@import "src/styles/mixins";
@import "src/styles/props";

// option item
.option {
  @include padding(0 15px);
  @include flex(center, flex-start, row, nowrap);
  @include CastrolSansConNormal;
  @include ellipsis;
  height: 50px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: darken($white, 10%);
  }

  &.selected {
    color: $pigment-green;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .checkbox {
    margin-right: 8px;
  }
}
