@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
}

.formSelectField {
  height: 50px;
}

.languageBoxWrap {
  @include flex(center, flex-start, row);
  margin-top: 10px;
}

.languageBox {
  background-color: $pigment-green;
  color: $white;
  font-size: 14px;
  line-height: 22px;
  padding: 0 8px;
  text-transform: uppercase;
  margin-right: 8px;
  cursor: pointer;

  &.languageActive {
    background-color: $danube;
  }

  &.languageEmpty {
    background-color: $dim-gray;
  }

  svg {
    cursor: pointer;
    margin-left: 8px;
  }
}