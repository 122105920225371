@import "src/styles/mixins";
@import "src/styles/props";

// filter group gap
.filterGroup + .filterGroup {
  margin-top: 22px;
}

// search result container
.searchResultContainer {
  margin-top: 30px;
  @include for-phone-only{
    margin-top: 20px;
  }
}

.filterOpen {
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: block;
    }
  }
  & > div:last-child {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
  
}

.filterClose {
  & > div:last-child {
    display: block;
  }
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}