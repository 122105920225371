@import "src/styles/mixins";
@import "src/styles/props";

.container {
  background: #fff;
  padding: 30px;
  .header {
    @include flex(null, null, column);
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    @include padding-bottom(20px);

    .portName {
      color: $ruby;
    }

    .selectedStockPoint {
      margin-top: 20px;
      font-size: 16px;
    }
  }

  .content {
    @include flex(null, null, column);

    .button{
      padding: 0 10px;
      align-self: flex-end;
      height: 50px;

      @include for-phone-only {
        align-self: flex-start;
        margin-top: 20px;
      }
    }

    .portAssociatedNote {
      color: $pigment-green;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 14px;
      margin-top: 20px;
      cursor: pointer;
    }

    .addNewProduct {
      @include padding(15px 28px);
      align-self: flex-end;
      @include for-phone-only {
        width: 100%;
        margin-top: 8px;
      }
    }

    .addPort {
      @include padding(15px 28px);
      align-self: flex-end;
      margin-top: 8px;
      &:hover {
        text-decoration: none;
        background-color: $white;
      }
      @include for-phone-only {
        width: 100%;
      }
    }

    .portList {
      padding-top: 20px;
    }

    .colHeader {
      flex: 1 1;
      box-sizing: border-box;
      padding-bottom: 9px;
      color: $pigment-green;
      text-transform: uppercase;
    }

    .portRow {
      @include flex(stretch, space-between, row);
      width: 100%;
      @include padding(10px 0);
      margin: 2px 0;
      @include border-bottom(1px solid $gainsboro);
      .link {
        @include CastrolSansConNormal;
        font-size: 16px;
        line-height: 19px;
        color: $pigment-green;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .messageContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    margin: 50px 0;
    margin-bottom: 1.5em;
    position: relative;
    border: 2px solid $cadetblue;
    @include for-phone-only {
      padding: 20px;
    }
    .icon {
      width: 28px;
      height: 24px;
      margin-right: 20px;
      @include for-phone-only {
        flex-grow: 1;
        flex-shrink: 0;
        margin-right: 5px;
      }
      circle {
        fill: $cadetblue !important;
      }
    }
    .link {
      @include CastrolSansConNormal;
      font-size: 16px;
      line-height: 19px;
      color: $pigment-green;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .message {
      align-self: baseline;
      justify-content: center;
      font-size: 16px;
      line-height: 22px;
      color: $dim-gray;
      @include Arial;
    }
  }
}
