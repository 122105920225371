@import "src/styles/mixins";
@import "src/styles/props";

.para {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 20px;
  width: 100%;
  @include border-bottom(1px solid $gainsboro);
  @include for-phone-only {
    display: none;
  }
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
  margin-bottom: 30px;
  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.loading {
  height: 100px;
}

.selectDropdown {
  padding: 0px 0px 0px 10px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  height: 100%;
}

.selectInput {
  padding: 0px 0px 0px 10px;
}

.errorDropdown {
  @include border($red 1px solid !important);
}

.input {
  @include border(1px solid $gainsboro);
  @include padding(0 20px);
  @include CastrolSansConNormal;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  margin-right: 16px;
}

.ellipsis {
  max-width: 300px;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  padding-left: 20px;
  padding-right: 20px;
  @include for-tabs-only{
    padding-left: 0;
    display: inline-block;
  }
}