@import "src/styles/mixins";
@import "src/styles/props";

// product list item container
.container {
  @include border(1px solid $gainsboro);

  &.opened {
    background-color: rgba($light-grey, 0.2);
  }
}

// summary container
.summaryContainer {
  @include padding(22px 25px 20px 25px);
  @include for-phone-only{
    @include padding(20px);
  }
}

// summary header
.summaryHeader {
  display: flex;
  align-items: center;

  h3 {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
  }
}

// summary header clickable
.summaryHeaderClickable{
  cursor: pointer;
}

// summary type
.summaryType {
  @include CastrolSansConMedium;
  @include flex(center, center);
  @include padding(0 8px);
  height: 21px;
  background-color: $danube;
  margin-left: 9px;
  text-transform: uppercase;
  font-size: 14px;
  color: $white;
}

// summary common text
.summaryText {
  @include CastrolSansConNormal;
  color: $dim-gray;
}

// summary sub title
.summarySubTitle {
  font-size: 16px;
  line-height: 19px;
  @include CastrolSansConNormal;
  margin-top: 6px;
  text-transform: uppercase;
}

// summary description
.summaryDescription {
  font-size: 14px;
  margin-top: 16px;
  padding-right: 255px;
  @include Arial;
  @include for-phone-only{
    margin-top: 12px;
    padding-right: 0;
  }
}

// summary button container
.summaryButtonContainer {
  @include flex();
  margin-top: 11px;
  @include for-phone-only{
    margin-top: 12px;
  }
  .summaryButton {
    font-size: 16px;
    line-height: 19px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
  }
}

// summary button gap
.summaryButton + .summaryButton {
  margin-left: 19px;
}

// list container
.listContainer {
  @include padding(22px 24px 20px);
  @include border-top(1px solid $gainsboro);
  @include for-phone-only{
    @include padding(20px);
  }
}

// extra button
.extraButton {
  font-size: 14px;
  text-transform: none;
  margin-left: 19px;
  height: auto;
}
