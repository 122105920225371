@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include flex(stretch, null, column);
  position: absolute;
  width: 100%;
  margin-top: 40px;
  z-index: 10;

  &.hasAnnouncementsNoPromotions {
    margin-top: 80px;
  }

  * {
    @include for-phone-only {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

// inner container
.innerContainer {
  margin: 0 auto;
  width: 1126px;
  @include for-phone-only {
    width: calc(100vw - 30px);
    padding: 0 15px;
  }
}

// search input
.searchInput {
  @include rect(100%, 64px);
  @include border(1px solid $gainsboro);
  @include flex(center, center);
  cursor: text;
  background-color: $white;
  box-shadow: 0 10px 20px rgba($black, .1);
  @include for-phone-only {
    @include padding (13px 46px);
  }
}

// placeholder
.searchPlaceholder {
  @include CastrolSansConNormal;
  font-size: 20px;
  color: $dim-gray;
  margin-left: 20px;
  @include for-phone-only {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px - 24px);
    font-size: 16px;
    line-height: 19px;
  }
}

// divider container
.dividerContainer {
  @include flex(center);
  margin-top: 22px;

  .dividerLine {
    @include item-flex(1, 1, 0);
  }

  &.hasAnnouncementsNoPromotions {
    .dividerText {
      color: $dim-gray;
    }
  }
}

// divider line
.dividerLine {
  height: 1px;
  background-color: #FFFFFF86;
  opacity: .5;
}

// divider text
.dividerText {
  @include CastrolSansConNormal;
  font-size: 20px;
  color: $white;
  margin: 0 20px;
  line-height: 24px;
  text-transform: uppercase;
  @include for-phone-only {
    font-size: 16px;
    line-height: 19px;
  }
}

// browse button container
.browseButtonContainer {
  @include flex();
  margin-top: 20px;

  // browse button style
  .browseButtonBox {
    @include item-flex(1, 1, 186px);

    > * {
      width: 100%;
    }
  }

  // browse product button style
  .browseProductButtonBox {
    @include item-flex(1, 1, 450px);

    > * {
      width: 100%;
    }
  }
}

// browse product button
.browseProductButton {
  @include flex(center, space-between);
  @include padding(0 16px);
  font-weight: 400;
}

.browseButton {
  position: relative;
  text-transform: uppercase;
}

// button container
.buttonContainer {
  position: relative;
}

// button container
.buttonContainer + .buttonContainer {
  margin-left: 20px;
  @include for-phone-only {
    margin-left: 0px;
    margin-top: 12px;
  }
}

// left group for browse product button
.buttonLeftGroup {
  @include flex(center);
}

// button icon
.buttonIcon {
  margin-right: 12px;
}

// arrow right
.buttonArrowRight {
  margin-left: 10px;
}

.buttonContainer {
  // button loading
  .buttonLoading {
    @include box(100%);
    position: absolute;
    min-height: auto;
    background-color: rgba($black, .5);
  }
}

// icon container
.iconContainer{
  @include flex(center, space-between, row);
  .closeIcon{
    svg{
      width: 18px;
      height: 18px;
    }
  }

  svg + svg{
    margin-left: 20px;
  }
}
