@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  @include flex(flex-start, center, column);
  width: 100%;
  margin-bottom: 24px;
  background-color: $white;

  @include for-phone-only{
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.results {
  display: none;

  @include for-phone-only {
    display: flex;
    @include CastrolSansConNormal;
    font-weight: 400;
    font-size: 30px;
    flex-direction: row;
    justify-content: center;
  }
}

.header {
  padding: 20px 30px;
  width: 100%;
  display: flex;

  @include for-phone-only {
    padding: 20px 0;
  }
}

.card {
  display: flex;
  margin-right: 54px;
  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    margin-left: 15px;

    .title {
      color: $dim-gray;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
    }

    .total {
      font-size: 16px;
      font-weight: 600;
      margin-top: 6px;
    }
  }

}

.details {
  width: 95%;
  margin: 20px 0 0 20px;
  border-top: 1px solid $gainsboro;

  @include for-phone-only {
    margin: 0;
  }

  .detailsTitle {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }
}
