@import "src/styles/mixins";
@import "src/styles/props";

.backdrop {
  background-color: $background-gray;
  @include padding-top(29px);
  @include padding-bottom(50px);
  @include flex(center, flex-start, column);
  @include for-phone-only {
    @include padding(0px);
  }
}

.mainCard {
  background-color: $white;
  width: 948px;
  @include padding(20px);
  @include for-phone-only {
    width: 100%;
    @include padding(24px 16px 50px 16px);
  }
}
