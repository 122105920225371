@import "src/styles/mixins";
@import "src/styles/props";

.productCountrylevels {
  width: 100%;
}

.multiSelect {
  border: 1px solid $gainsboro;
  height: 52px;
}