@import "src/styles/mixins.scss";
@import "src/styles/props.scss";

.inputWrapper {
  @include border(1px solid $gainsboro);
  height: 50px;
  @include flex(center, justify-content, row, nowrap, false);
  @include padding-left(20px);
  @include padding-right(20px);
  position: relative;
  margin-bottom: 12px;
  max-width: 100%;

  .inputText {
    color: $black;
    font-size: 16px;
    line-height: 19px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.placeholder {
      color: $dim-gray;
    }
  }

  .add {
    position: absolute;
    right: 9.6px;
    top: 16.5px;

    g path {
      stroke: $pigment-green;
    }
  }
}
