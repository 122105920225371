@import "src/styles/mixins";
@import "src/styles/props";

// navigation container
.navigationContainer{
  display: none;
  @include padding(0 16px);
  @include for-phone-only {
    display: block;
    position: absolute;
    top: 115px;
    background-color: $white;
    width: 100%;
    left: 0;
    border-bottom: 1px solid $gainsboro;
  }

  &.notLogin {
    @include for-phone-only {
      top: 185px;
    }
  }
}

.statContainer{
  display: none;
  @include for-phone-only {
    display: block;
    margin-top: 25px;
  }
}

// line divider
.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 30px 0;
  @include for-phone-only {
    margin: 20px 0;
  }
}

// loading
.loading {
  height: 100px;
}

// detail container
.portDetailContainer {
  @include flex(flex-start, space-between);
  @include for-phone-only {
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
  }
}

.portTable {
  margin-top: 40px;
}

.leftDrawer{
  @include for-phone-only {
    display: none;
  }
}
