@import "src/styles/mixins";
@import "src/styles/props";

.heading {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  @include CastrolSansConNormal;
  text-transform: uppercase;
}

.mainCard {
  background-color: $white;
  width: 948px;
  @include padding(20px);
  @include for-phone-only {
    width: 100%;
  }
}

.divider {
  @include border-bottom(1px solid $gainsboro);
  width: 100%;
  margin-bottom: 20px;
}

.input {
  margin-bottom: 20px;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @include for-phone-only {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.borderedBox {
  @include border(1px solid $gainsboro);
  @include padding(20px);
}

.attributeWrapper {
  .attributeLabel {
    color: $dim-gray;
    font-size: 14px;
    line-height: 20px;
    @include Arial;
    margin-bottom: 10px;
  }
}

.option {
  @include flex(center, flex-start, row);
  margin-bottom: 12px;

  .optionLabel {
    @include Arial;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
  }
}

.inputWrapper {
  @include border(1px solid $gainsboro);
  height: 50px;
  @include flex(center, justify-content, row);
  @include padding-left(20px);
  @include padding-right(20px);
  position: relative;
  margin-bottom: 12px;

  .inputText {
    color: $dim-gray;
    font-size: 16px;
    line-height: 19px;
  }

  .add {
    position: absolute;
    right: 9.6px;
    top: 16.5px;

    g path {
      stroke: $pigment-green;
    }
  }
}

.helpText {
  font-size: 14px;
  line-height: 20px;
  @include Arial;
  color: $dim-gray;

  b {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    @include Arial;
    color: $dim-gray;
  }
}

.buttons {
  @include flex(center, space-between, row);
  width: 948px;
  margin-top: 30px;
  @include for-phone-only {
    width: 100%;
  }

  .button {
    min-width: 91px;
    @include padding-left(34px);
    @include padding-right(34px);
    @include roboto-condensed;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
  }
}
.multiSelect {
  border: 1px solid $gainsboro;
  height: 52px;
}
