@import "src/styles/mixins";
@import "src/styles/props";

// result container
.resultContainer {
  // header
  > div:first-child {
    position: relative;
  }
}

// no results
.noMatchedResults {
  @include CastrolSansConNormal;
  margin-top: 12px;
  font-size: 16px;
  line-height: 19px;
  color: $dim-gray;
}

// browse item gap
.browseItem + .browseItem {
  margin-top: 20px;
}
