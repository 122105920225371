@import "src/styles/mixins";

.addPortContainer {
  max-width: 800px;

  
}

.container {
  @include padding(50px);

  h1 {
    font-size: 36px;
    font-family: "CastrolSansConNormal";
    text-transform: uppercase;
  }

  .modalContent {
    @include flex(null, null, column);
    margin-top: 16px;

    .physicalOfferContainer {
      border: none;
      padding: 0px;
    }
  }

  .actionBtnContainer {
    @include flex(null, flex-end);
    margin-top: 24px;

    @include for-phone-only {
      @include flex(null, center);
    }

    button {
      @include padding(15px 28px);

      &:first-child {
        margin-right: 24px;
      }
    }
  }
}