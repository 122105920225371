@import "src/styles/mixins";
@import "src/styles/props";

.dateField {
  padding-top: 20px;

  @include for-phone-only {
    width: 100%;
  }
}

.selectWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
}