@import 'src/styles/mixins';
@import 'src/styles/props';

.sliderContainer {
  overflow: hidden;
}

.slider {
  width: 100%;
  height: 18px;
}

.track {
  height: 8px;
  background-color: #dddddd;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.coveredRange {
  height: 8px;
  background-color: #009343;
  top: 50%;
  transform: translateY(-50%);
}

.thumb {
  background-color: #0a702f;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.labelContainer {
  @include flex(center, space-between, row, nowrap);
  margin-top: 8px;
  .label {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
  }
}
