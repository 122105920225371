@import "src/styles/mixins";
@import "src/styles/props";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  margin: 50px 0;
  margin-bottom: 1.5em;
  position: relative;
  border: 2px solid $gainsboro;
  background-color: $background-gray;
  @include for-phone-only {
    padding: 20px;
  }
  .icon {
    width: 28px;
    height: 24px;
    margin-right: 20px;
    @include for-phone-only {
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: 5px;
    }
  }
  h3 {
    color: $black;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
  }
}

.message {
  align-self: baseline;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  color: $dim-gray;
  @include Arial;
  & a {
    color: $pigment-green;
  }
}
