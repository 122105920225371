@import "src/styles/mixins";
@import "src/styles/props";
// main container
.container {
    @include padding(20px 40px);
    @include flex(stretch, null, column, nowrap);
    max-height: calc(100vh - 64px - 68px);
    @include for-phone-only {
      @include padding(75px 15px 15px 15px);
      max-height: calc(100vh - 30px);
    }
  }