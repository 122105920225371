@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(stretch);
  max-height: calc(100vh - 64px - 68px);
  @include for-phone-only{
    max-height: calc(100vh - 30px);
    overflow: auto;
    position: relative;
  }
}

// form container
.formContainer {
  @include item-flex(1, 1, 0);
}


// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}
