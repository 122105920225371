@import "src/styles/mixins";
@import "src/styles/props";

// search container
.container {
  position: relative;
}

// input
.input {
  @include CastrolSansConNormal;
  @include rect(507px, 40px);
  @include border(1px solid $gainsboro2);
  @include padding(0 40px 0 12px);
  font-size: 16px;

  &::placeholder {
    color: $dim-gray;
  }
}

// search icon
.icon {
  @include vertical-center;
  @include box(16px);
  position: absolute;
  right: 12px;
}
