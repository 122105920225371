@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include item-flex(1, 1, 0);
  @include padding(60px 160px 80px 160px);
  min-width: 800px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;


  @include for-phone-only {
    @include padding(32px 16px 80px 16px);
    min-width: unset;
    max-width: unset;
    flex-direction: column;
  }
}

.fieldsContainer,
.resContainer {
  width: 100%;
  max-width: 500px;

  .title {
    @include CastrolSansConRegular;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 29px;
    position: relative;

    @include for-phone-only {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 24px;
    }

    .checkCircleIcon {
      width: 38px;
      height: 38px;
      margin-right: 20px;
      position: relative;
      top: 7px;

      @include for-phone-only {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
    }

  }

  .description {
    @include Arial;
    color: $dim-gray;
    font-size: 16px;
    line-height: 24px;

    @include for-phone-only {
      margin-bottom: 24px;
    }
  }

  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 20px 0 0;

    &.marginBoth {
      margin: 20px 0;
    }
  }

  .actionRow {
    display: flex;
    flex-direction: row;

    .button {
      margin-top: 30px;

      &.updateButton {
        width: 117px;

        @include for-phone-only{
          width: 135px;
        }
      }

      &.cancelButton {
        width: 129px;
        margin-left: 20px;

        @include for-phone-only{
          width: 135px;
        }
      }

      &.doneBtn {
        width: 94px;
      }

    }
  }
}

