@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, center);
  background-color: $background-gray;
  @include padding(36px 24px);

  @include for-phone-only {
    @include padding(24px 16px);
  }

  .content {
    flex: 1;
    max-width: 950px;

    .checkboxWrapper {
      @include flex(center);
      margin-top: 20px;
      color: $dim-gray;
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;

      .checkbox {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }
  }
}