@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  @include flex(center, flex-start, row, nowrap);

  .datePickerPopper {
    @include CastrolSansConNormal;
  }
}

.inputWrapper {
  @include flex(center, space-between, row, nowrap);
  width: 109px;
  height: 22px;
  padding: 14px 10px;
  color: $black;

  label {
    @include Arial;
    cursor: pointer;
    font-size: 14px;
  }

  &.placeholder {
    color: $dim-gray;
  }

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}