@import "src/styles/mixins";
@import "src/styles/props";

.oemDetailEditModalContainer {
  max-width: 900px;
  
  .container {
    @include padding(48px);
    padding-top: 10px;

    @include for-phone-only {
      padding: 0;
    }

    h1 {
      @include CastrolSansConNormal;
      font-size: 36px;
      line-height: 48px;
      font-weight: 400;
      text-transform: uppercase;

      @include for-phone-only {
        font-size: 26px;
        line-height: 35px;
        padding: 27px 20px 0 20px;
      }
    }

    .modalContent {
      @include flex(null, null, column);
      @include for-phone-only {
        margin-top: 32px;
        padding: 20px;
      }
      .oemDetailContainer {
        @include flex(space-between, center, column);
        width: 100%;
        margin-bottom: 5px;
      
        @include for-phone-only{
          margin-bottom: 0;
        }

      }
      
      .calendarRow {
        width: auto;
        & > label{
          @include CastrolSansConNormal;
        }
      
        @include for-phone-only {
          width: 93% !important;
        }
      }
      
      
      .selectControl {
        border: 1px solid grey;
        height: 50px;
      
        &.nameSelector {
          width: 100%;
        }
      
        svg {
          right: 10px;
        }
      }
      
      .selectInput {
        @include Arial;
        font-size: 14px;
        padding: 0 32px 0 10px;
      }
      
      .selectOption {
        @include Arial;
        font-size: 14px;
      }
    }

    .actionBtnContainer {
      @include flex(null, flex-start);

      @include for-phone-only {
        @include flex(null, space-between);
        @include border-top(1px solid $gainsboro);
        margin-top: 0;
        padding: 20px 20px 40px 20px;
      }

      .confirmButton {
        width: 116px;
      }

      .cancelButton {
        width: 116px;
        margin-left: 24px;
      }

    }
  }
}