@import "src/styles/mixins";
@import "src/styles/props";

.header {
  min-height: 94px;
  @include flex(null, center, column);
  @include padding(0px 24px);
  @include for-phone-only {
    @include padding(17px 16px);
  }

  h3 {
    @include CastrolSansConNormal;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 8px;
  }
}
