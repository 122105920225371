@import "src/styles/mixins";
@import "src/styles/props";

// bottom container
.bottomContainer {
  @include padding(42px 0 80px);
  @include for-phone-only {
    @include padding(30px 0 30px);
  }
}

// bottom inner container
.bottomInnerContainer {
  width: 1126px;
  margin: 0 auto;
  @include for-phone-only {
    width: 100%;
  }
}

.linksContainer {
  @include for-phone-only {
    @include padding(0 16px);
  }
}

// links container gap
.linksContainer + .linksContainer {
  margin-top: 42px;
  @include for-phone-only {
    margin-top: 30px;
  }
}


