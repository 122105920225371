@import 'src/styles/mixins';
@import 'src/styles/props';

// select container
.selectContainer {
  @include border(1px solid $gainsboro);
  @include flex(center, space-between);
  cursor: pointer;
  position: relative;

  &.opened {
    .icon {
      transform: rotate(180deg);
    }
  }

  &.invalid {
    border-color: $ruby;
  }

  &.invalid + .error {
    display: block;
  }
}

.disabled {
  background: #f7f8f9;
  opacity: 0.5;
  cursor: unset;

  .input {
    cursor: unset;
  }
}

.error {
  display: none;
}

// select label
.label {
  @include CastrolSansConNormal;
  @include ellipsis;
  max-width: calc(100% - 52px);
  line-height: 19px;
  color: $dim-gray;
}

// icon
.icon {
  position: absolute;
  pointer-events: none;
  top: 50%;
  margin-top: calc(-7.11px / 2);
  right: 20px;

  path {
    stroke: $pigment-green;
  }
}

// click detector
.clickDetector {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

// options container
.optionsContainer {
  position: fixed;
  background-color: $white;
  box-shadow: 0 10px 20px rgba($black, 0.2);
  overflow: auto;
  animation: fade-in 0.3s forwards;
  z-index: 200;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

// input
.input {
  @include padding(0 52px 0 20px);
  @include CastrolSansConNormal;
  @include ellipsis;
  width: 100%;
  font-size: inherit;
  height: 100%;
  color: $black;
  cursor: pointer;

  &.inputVal {
    height: auto;
  }

  &.selectPlaceholder {
    color: $dim-gray;
  }

  &::placeholder {
    color: $dim-gray;
  }

  &.smallHorizontalPadding {
    padding-left: 2px;
    padding-right: 42px;
  }
}

.renderedValueContainer {
  @include padding(0 52px 0 20px);
  @include flex(center);
  @include CastrolSansConNormal;
  @include ellipsis;
  width: 100%;
  height: 100%;
  color: $black;
  cursor: pointer;

  &.placeholder {
    color: $dim-gray;
  }
}
