@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(flex-start, null, column);
}

// name container
.nameContainer {
  position: relative;

  .linkRow {
    @include flex(flex-start, null, row, nowrap);
  }
}

// name
.name {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: $pigment-green;

  &.inactive {
    color: $dim-gray;
  }

  em {
    background: $castrol-red;
    font-style: normal;
    color: $white;
  }
}

//oem-icon
.oem {
  align-self: center;
  margin-left: 6px;
  transform: rotate(360deg) !important;
}

// description
.description {
  @include Arial;
  @include ellipsis;
  font-size: 14px;
  line-height: 17px;
  margin-top: 3px;
  color: $dim-gray2;
  max-width: 100%;
}

// right arrow
.rightArrow {
  margin-left: 7px;

  path {
    stroke: $gainsboro;
  }
}

.inactiveLabel {
  @include CastrolSansConNormal;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  background-color: $ruby;
  text-transform: uppercase;
  padding: 1px 8px;
  margin-left: 6px;
}
