@import "src/styles/mixins";
@import "src/styles/props";

.modalContainer{
  max-width: 1100px;

  @include for-phone-only{
    max-width: unset;
  }
}

// container
.container {
  padding: 46px 40px 80px 40px;
  max-height: calc(100vh - 64px - 68px);

  @include for-phone-only{
    overflow: auto;
    max-height: unset;
    padding: 40px 20px;
  }
}

.title {
  @include CastrolSansConRegular;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.description {
  @include Arial;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 36px;
  color: $dim-gray;
}

.radioContainer {
  display: flex;
  flex-direction: column;

  .radioItem {
    display: inline-flex;
    cursor: pointer;

    span {
      @include Arial;
      font-size: 14px;
      line-height: 24px;
      margin-left: 12px;
      color: $dim-gray;

      &.active {
        color: $pigment-green;
      }
    }
  }

  .radioItem + .radioItem {
    margin-top: 16px;
  }

}

.actionRow {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  .unregisterBtn {
    width: 154px;
  }

  .cancelBtn {
    width: 129px;
    margin-left: 20px;

    @include for-phone-only{
      width: 135px;
    }
  }

}


// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &.desktop {
    display: block;
  }

  &.mobile {
    display: none;
  }

  @include for-phone-only{
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}
