@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(24px 0);
  @include flex(stretch, null, column);
  @include for-phone-only {
    gap: 22px;
    border-top: 1px solid $gainsboro;
    @include padding(0);
    padding-top: 22px !important;
    @include flex(center, space-between, row);
  }
}

// line divider
.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 15px 0;
}

// item gap
.item + .item {
  margin-top: 22px;
  @include for-phone-only {
    margin-top: 0;
  }
}

.label {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

// link text
.link {
  div {
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 22px;
    color: $pigment-green;
    height: 24px;
    text-transform: none;
    cursor: pointer;
  }
}
