@import "src/styles/mixins";
@import "src/styles/props";

.addOemApprovalsPageContainer {
  @include item-flex(1, 1, 0);
  margin: 0 auto;
  min-width: 600px;
  max-width: 1750px;

  @include for-phone-only{
    padding: 20px 0;
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}

.titleRow {
  @include CastrolSansConNormal;
  padding-right: 14px;

  @include for-phone-only{
    padding: 0 20px;
  }

  .title {
    @include padding(16px 33px);
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    text-transform: uppercase;

    @include for-phone-only{
      margin-bottom: 0;
      padding: 0;
    }
  }
}

.divider {
  @include rect(100%, 32px);
  background-color: rgba($light-grey, 0.2);

  @include for-phone-only{
    display: none;
  }
}

.tableContainer {
  padding: 20px 33px;

  @include for-phone-only {
    padding: 0;
  }
}

.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 70px;

  @include for-phone-only{
    margin-top: 35px;
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .cancelButton {
    width: 120px;
  }

  .saveButton {
    width: 95px;
    margin-left: 16px;
    margin-right: 33px;

    @include for-phone-only{
      margin-right: 0;
    }
  }
}


// loading
.loading {
  height: 100px;
}
