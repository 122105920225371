@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include top-left;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

// background cover
.cover {
  @include fullscreen;
  position: fixed;
  background-color: rgba($black, 0.5);
}

// content container
.contentContainer {
  @include fullscreen;
  @include padding(64px 83px 68px);
  @include flex(flex-start, center);
  position: absolute;
  pointer-events: none;
  @include for-phone-only{
    @include padding(15px);
    justify-content: flex-start;
  }

  &.contentCenter {
    align-items: center;
  }
}

// modal container
.modalContainer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  pointer-events: auto;
  background-color: $white;
  box-shadow: 0 20px 40px rgba($black, 0.16);

  @include for-phone-only{
    width: calc(100vw - 30px);
    max-height: calc(100vh - 30px);
    overflow: auto;
  }
}

// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  @include for-phone-only{
    top: 15px;
    right: 15px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}
