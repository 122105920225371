@import "src/styles/mixins";
@import "src/styles/props";

.para {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-bottom: 20px;
}

.multiProductSelectWrapper{
  @include padding-left(4px);
  height: 100%;
}

.divider {
  margin-bottom: 20px;
  width: 100%;
  @include border-bottom(1px solid $gainsboro);
  @include for-phone-only {
    display: none;
  }
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
  margin-bottom: 30px;
  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.selectDropdown {
  padding: 0px;
  height: 40px !important;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  @include border(none !important);

  & > svg {
    right: 4px;
  }
}

.selectInput {
  padding: 4px;
  font-size: 14px;
  line-height: 17px;
  width: calc(100% - 14px) !important;
}

.errorDropdown {
  @include border($red 1px solid !important);
}

.dropdownOption {
  @include Arial;
  font-size: 14px;
  line-height: 16px;
  @include padding(0 10px);
}

.productId {
  @include padding-left(4px);
}

.tableCell {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
