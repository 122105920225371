@import "src/styles/mixins";
@import "src/styles/props";

// cookie container
.container {
  @include padding(32px 120px 30px);
  @include bottom-left;
  position: fixed;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -10px 50px rgba($black, 0.1);
  z-index: 100;
  @include for-phone-only {
    @include padding(20px);
  }
}

// title
.title {
  @include CastrolSansConNormal;
  font-size: 20px;
  line-height: 24px;
  @include for-phone-only {
    font-size: 16px;
    line-height: 19px;
  }
}

// text
.text {
  @include Arial;
  font-size: 14px;
  line-height: 18px;
  color: $dim-gray;
  margin-top: 5px;
  max-width: 1032px;
}

// buttons container
.buttonContainer {
  @include flex();
  margin-top: 23px;
}

// button
.button {
  height: 40px;
  font-size: 14px;
}

// accept button
.acceptButton {
  width: 121px;
}

// manage button
.manageButton {
  margin-left: 30px;
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 19px;
}
