@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  @include flex(null, center);
  background-color: $background-gray;

  .contentWrapper {
    max-width: calc(min(100%, 1366px));
    overflow: hidden;
    @include flex(null, center);
    flex: 1;
    @include padding(36px 24px);

    @include for-phone-only {
      @include flex(null, null, column);
      @include padding(28px 16px);
    }

    .progressWrapper {
      @include flex(null, null, column);
      margin-right: 50px;
      min-width: 294px;
      max-width: 100%;
      overflow: hidden;

      @include for-phone-only {
        margin-bottom: 24px;
        margin-right: 0px;
      }

      .marginBottom {
        margin-bottom: 32px;
      }
    }

    .desktopContainer {
      flex: 1;
      overflow: hidden;

      @include for-phone-only {
        display: none;
      }
    }
  }
}
