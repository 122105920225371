@import "src/styles/mixins";
@import "src/styles/props";

// tab content container
.contentContainer {
  margin-top: 40px;
  @include for-phone-only{
    margin-top: 20px;
  }
}
