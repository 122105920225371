@import "src/styles/mixins";
@import "src/styles/props";

.inputRowWithButtonContainer {
  > div {
    width: 80%;

    @include for-phone-only {
      flex: 1;
      width: 100%;
    }
  }

  .tooltip {
    margin-right: 80px;

    @include for-phone-only {
      margin-right: unset;
    }
  }

  .existingRow {
    @include flex(flex-start);
    flex: 1;
    margin-bottom: 4px;

    @include for-phone-only {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .fullWidth {
      margin-right: 20px;
      flex: 1;

      @include for-phone-only {
        margin-bottom: 4px;
      }
    }

    .deleteBtn {
      @include CastrolSansConRegular;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: inline-block;
      margin-top: 15px;
      cursor: pointer;
      color: #009343;
      text-transform: uppercase;

      @include for-phone-only {
        margin-top: 6px;
        align-self: flex-end;
        margin-right: 20px;
      }

      .deleteIconBtn {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 4px;

        path {
          fill: #009343;
        }
      }
    }
  }

  .addBtn {
    padding: 0 10px;

    @include for-phone-only {
      width: 100%;
    }
  }
}