@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);

  .completeAllStepLabel {
    display: inline-block;
    color: $pigment-green;
    margin-bottom: 9px;
  }
}
