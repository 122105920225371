@import "src/styles/mixins";
@import "src/styles/props";

.formRow {
  @include flex(null, null, column);
  @include padding-top(20px);
  position: relative;

  .labelWrapper {
    @include flex();

    .fieldLabelWrapper {
      flex: 1;
      @include padding-bottom(9px);

      .fieldLabel {
        color: $pigment-green;
        text-transform: uppercase;
      }

      .requiredIndicator {
        color: $red;
      }
    }
  }
  input {
    height: 50px;
  }
  textarea {
    height: 100px;
  }
  .selectControl {
    height: 50px;
  }
  .selectInputRow{
    height: auto;
  }
}