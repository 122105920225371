@import 'src/styles/mixins';
@import 'src/styles/props';

.tableContainer {
  border: 1px solid $gainsboro;
  margin-bottom: 20px;
}

.header {
  display: flex;
  height: 70px;
  margin: 20px;
  background-color: $background-gray;

  .usersIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  .totalUserWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 15px;

    .totalUser {
      font-size: 14px;
      color: $dim-gray;
      text-transform: uppercase;
    }

    .userCount {
      color: $black;
      font-size: 16px;
      margin-top: 6px;
    }
  }
}

.graphContainer {
  margin: 0 20px;

  .graphTitle {
    margin-top: 20px;
    font-size: 16px;
    text-transform: uppercase;
  }

  .chart {
    width: 50%;
    height: 350px;

    @include for-tabs-l-only{
      width: 100%;
      height: 100%;
      div {
        div:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}

.table {
  margin: 0 20px;
  padding-bottom: 20px;

  .tableTitle {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 26px;
    text-transform: uppercase;

    .date {
      color: $pigment-green;
      margin-left: 5px;
    }
  }

  .change {
    margin-left: 5px;
  }

  .green {
    color: $pigment-green;
  }

  .red {
    color: $red;
  }

  .zero{
    margin-left:  -7px;
  }
}