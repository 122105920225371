@import "src/styles/mixins";
@import "src/styles/props";

// main header menu
.container {
  @include flex(center, center, row);
  border-bottom: 1px solid $gainsboro;

  @include for-phone-only {
    display: none;
  }
}

.outsideClickDetectorContainer {
  @include for-phone-only {
    width: 100%;
  }
}

.adminMenuOpen {
  @include flex(center, center, column);
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 115px;
  background: white;
}

.menuItemWrapper {

  @include for-phone-only {
    width: 100%;
  }

  .subItemWrapper {
    position: absolute;
    z-index: 200;
    background: $white;
    @include padding(5px 0px);
    box-shadow: 0 10px 20px rgba($black, 0.2);

    @include for-phone-only {
      position: relative;
      @include padding(1px 0px);
      box-shadow: none;

      .menuItem {
        @include padding-left(45px);
      }
    }
  }
}

.manageOEMOptions {
  width: 320px;
}

.menuItem {
  @include flex(center, flex-start);
  @include padding(16px 8px 16px 16px);
  height: 100%;
  cursor: pointer;

  @include for-phone-only {
    width: 100%;

    @include border-bottom(1px solid $gainsboro)
  }

  span {
    margin-left: 8px;
    text-transform: uppercase;
  }
}

.menuItemActive {
  background-color: $pigment-green;

  span {
    color: $white;
  }
}

.lookupsLoading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}