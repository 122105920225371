@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
}

// header
.header {
  @include flex(center, space-between);
  @include padding-bottom(10px);
  @include border-bottom(1px solid $gainsboro);
  position: sticky;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 10;

  h3 {
    text-transform: uppercase;
    @include for-phone-only {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.open {
  svg {
    transform: rotate(180deg);
  }
}

// content container
.contentContainer {
  margin-top: 20px;
  @include for-phone-only {
    display: none;
  }
  &.open {
    @include for-phone-only {
      width: calc(100vw - 60px);
      display: block;
    }
  }
}

// load more
.loadMore {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  color: $dim-gray;
  margin-top: 22px;
  display: block;
  @include for-phone-only {
    display: none;
  }
  &.open{
    @include for-phone-only {
      display: block;
    }
  }
}

.icon {
  display: none;
  @include for-phone-only {
    display: block;
    margin-right: 15px;
  }
  &.open{
    transform: rotate(180deg);
  }
}

// more button
.moreButton {  
  @include for-phone-only {
    display: none;
  }
}

.moreButtonMobile {
  margin-top: 15px;
  text-transform: uppercase;
  display: none;
  @include for-phone-only {
    display: block;
  }
  svg{
    transform: rotate(0deg) !important;
  }
}
