@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;
  margin-top: 20px;
  padding: 24px 20px;

  @include for-phone-only {
    padding: 0px;
  }
}

.expandableItem {
  width: 100%;
  &:not(:first-child) {
    margin-top: 24px;
  }
}

.detailsBody {
  margin: 25px 40px;

  @include for-phone-only {
    margin: 16px;
  }

  .detailsRow:not(:first-child) {
    margin-top: 30px;
  }

  .rowTitle {
    @include Arial;
    font-size: 12px;
    color: $dim-gray5;
    text-transform: uppercase;
  }

  .rowValue {
    @include Arial;
    font-size: 16px;
    color: $black;
    margin-top: 6px;
    word-break: break-all;
  }

  .link {
    @include Arial;
    color: $pigment-green;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.visibilityBody {
  margin: 32px 20px;

  .countryGroup {
    margin-bottom: 32px;
  }

  .countryGroupGrid {
    margin-top: 24px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(5, 20%);
    row-gap: 16px;
    margin-left: 10px;

    @include for-phone-only {
      grid-template-columns: repeat(2, 50%);
      margin-left: 0px;
    }
  }

  .countryGroupTitle {
    font-size: 22px;
    text-transform: uppercase;
  }

  .countryName {
    @include Arial;
  }
}

.actionRow {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only {
    width: 120px;
  }
}

.saveButton {
  width: 95px;

  @include for-phone-only {
    width: 116px;
  }
}
