@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(24px 0);
  @include flex(stretch, null, column);
  @include for-phone-only{
    @include flex(center, space-between , row);
    @include padding(20px 0);
  }
}

// item gap
.item + .item {
  margin-top: 22px;
  @include for-phone-only{
    margin-top: 0;
  }
}

.item{
  @include for-phone-only{
    width: 50%;
  }
}

.container div:nth-child(n+3){
  @include for-phone-only{
    margin-top: 20px;
  }
}
