@import "src/styles/mixins";
@import "src/styles/props";

.genericModalContainer {
  max-width: 600px;

  & svg {
    @include for-phone-only {
      top: 10px;
      right: 10px;
    }
  }

  .container {
    @include padding(50px);

    @include for-phone-only {
      padding: 27px 20px 40px 20px;
    }

    h1 {
      font-size: 36px;
      font-family: "CastrolSansConNormal";
      text-transform: uppercase;
    }

    .content {
      @include flex(center, null, null, null, false);
      margin-top: 32px;
      margin-bottom: 50px;

      .successIcon {
        margin-right: 16px;
      }

      span {
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: $dim-gray;
      }
    }

    .actionBtnContainer {
      @include flex();

      button {
        @include padding(15px 28px);

        @include for-phone-only {
          width: 100%;
        }

        &:first-child {
          margin-right: 16px;
        }
      }

      &.confirmButtons {
        button {
          @include for-phone-only {
            width: calc(50% - 8px);
          }
        }
      }
    }
  }
}
