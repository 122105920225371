@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  @include flex(null, null, column);
  width: 320px;
  max-width: 100%;
  overflow: hidden;

  @include for-phone-only {
    width: unset;
  }

  .step {
    @include flex(flex-start, flex-start, column);
    color: $dim-gray;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .stepItem {
      @include flex(center, null, null nowrap);
      span {
        flex: 1;
      }
      > .indicator {
        @include flex(center, center);
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background-color: $white;
        margin-right: 16px;
        @include border(1px solid $gainsboro);
      }
    }

    .SuccessIcon {
      width: 28px;
      height: 28px;
      margin-right: 16px;
    }

    &.active {
      color: $ruby;
      .stepItem {
        > .indicator {
          color: $white;
          background-color: $ruby;
          @include border(none);
        }
      }
    }

    &.divider {
      @include for-phone-only {
        @include padding-bottom(20px);
        @include border-bottom(1px solid $gainsboro);
      }
    }
  }

  .subStep {
    @include flex(flex-start, null, null, nowrap);
    font-size: 14px;
    line-height: 16px;
    span {
      flex: 1;
    }
    > .indicator {
      @include flex(center, center);
      width: 16px;
      height: 16px;
      border-radius: 100%;
      margin-right: 16px;
    }
    &.past {
      color: $pigment-green;
    }
    &.ongoing {
      color: $ruby;
      > .indicator {
        @include border(2px solid $ruby);
      }
    }
    &.todo {
      color: $dim-gray3;
      > .indicator {
        @include border(2px solid $dim-gray3);
      }
    }
    &.isEdit {
      color: $pigment-green;
      > .indicator {
        border: none;
      }
      &.ongoing {
        color: $ruby;
      }
    }
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .subStepsContainer {
    margin-top: 24px;
    margin-left: 44px;
    @include for-phone-only {
      display: none;
    }
  }

  .phoneContainer {
    display: none;
    @include padding-bottom(20px);
    @include border-bottom(1px solid $gainsboro);
    margin-bottom: 20px;
    max-width: 100%;
    overflow: hidden;

    @include for-phone-only {
      @include flex(null, null, column);
    }
  }
}
