@import "src/styles/mixins";
@import "src/styles/props";

// table container
.container {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 50px - 80px - 80px - 64px - 68px);
}

// header
.tableHeader {
  @include border-top(1px solid $gainsboro);
  @include border-bottom(1px solid $gainsboro);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  .tableColumn {
    @include padding(5px 20px);
    min-height: 40px;
  }
  @include for-phone-only {
    left: auto;
    border: none;
  }
}

// row
.tableRow {
  @include flex(stretch);
  @include for-phone-only {
    flex-wrap: nowrap;
  }
}

// column
.tableColumn {
  @include CastrolSansConNormal;
  @include padding(16px 20px);
  @include flex(center);
  background-color: $white;
  position: relative;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  color: $dim-gray;

  &:not(.tableLabelColumn) {
    @include flex(center, center);
  }

  @include for-phone-only {
    width: 50% !important;
    border-bottom: 1px solid $gainsboro;
    border-top: 1px solid $gainsboro;
  }
}

// label column
.tableLabelColumn {
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: left;
  color: $black;
  @include for-phone-only {
    position: sticky;
    left: 0;
    z-index: 95;
    top: 0;
    border-right: 1px solid $gainsboro;
  }
}

// link text
.link {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  color: $pigment-green;
  width: 95%;
  @include for-phone-only {
    width: 80% !important;
  }
}

// close icon
.close {
  @include vertical-center;
  right: 12.5px;
  position: absolute;
  cursor: pointer;
}

// body
.tableBody {
  .tableRow {
    @include border-bottom(1px solid $gainsboro);
    @include for-phone-only {
      border-bottom: none;
    }
  }
}

.tableRow {
  .tableColumn:nth-child(2n - 1) {
    background-color: $white-smoke;
  }
}

// load more
.loadMore {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 21px;
  color: $dim-gray;
}

.oemColumn {
  flex-direction: column;
}

.downloadButton {
  width: 100%;
  max-width: 200px;
  display: block;
  margin-bottom: 8px;
}