@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(null, space-between, row);
  h3 {
    line-height: 27px;
    text-transform: uppercase;
  }
  h4 {
    line-height: 16px;
    text-transform: uppercase;
  }
  @include for-phone-only {
    flex-direction: column;
  }
}

.right {
  background-color: $white-smoke;
  padding: 24px 30px;
  @include for-phone-only {
    padding: 20px;
    width: calc(100vw - 70px) !important;
  }
}

.item {
  width: 400px;
  @include for-phone-only {
    width: 100%;
  }
}

.item + .item {
  margin-left: 10px;
  @include for-phone-only {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.description {
  @include Arial;
  font-size: 16px;
  line-height: 22px;
  margin-top: 15px;

  // description
  &.description2 {
    color: $dim-gray;
  }

  // description
  &.description3 {
    color: $dim-gray;
    .link {
      @include Arial;
      margin-left: 2px;
      color: $pigment-green;
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.address {
  @include Arial;
  font-size: 14px;
  line-height: 19px;
  margin-top: 15px;
}

// icon gap
.icon {
  margin-top: 15px;
  &.green {
    color: $pigment-green;
  }
}

.formControl {
  font-size: 14px;
  height: 40px;
}
