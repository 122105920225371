@import "src/styles/mixins";
@import "src/styles/props";

@mixin button($color) {
  background-color: $color;

  &:not(:disabled) {
    &:hover {
      background-color: lighten($color, 5%);
    }

    &:active {
      background-color: lighten($color, 10%);
    }
  }
}

// button
.button {
  @include CastrolSansConMedium;
  @include flex(center, center);
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: $white;
  text-transform: uppercase;

  // for green button
  &.green {
    @include button($pigment-green);
  }

  // for blue button
  &.blue {
    @include button($danube);
  }

  // for ruby button
  &.ruby {
    @include button($ruby);
  }
  // for red button
  &.red {
    @include button($red);
  }

  &.orange {
    @include button($orange);
  }

  &.red {
    @include button($red);
  }

  &.gray {
    @include button($dim-gray2);
  }
  &.green-outline {
    background-color: $white;
    color: $salem;
    border: 1px solid $salem;

    &:hover {
      color: lighten($salem, 5%);
    }

    &:active {
      color: lighten($salem, 10%);
    }
  }

  &.nobel {
    @include button($nobel);
  }

  // for transparent button
  &.transparent {
    background-color: transparent;
    color: $pigment-green;

    &:hover {
      color: lighten($pigment-green, 5%);
    }

    &:active {
      color: lighten($pigment-green, 10%);
    }
  }

  &:disabled {
    background-color: $nobel;
    color: rgba($white, 0.5);

    path {
      stroke: rgba($white, 0.5);
    }
  }
}
