@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include border(1px solid $gainsboro);
  width: 100%;
  @include padding(20px);

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
  }
}

.divider {
  @include border-bottom(1px solid $gainsboro);
  width: 100%;
  margin-bottom: 20px;
  @include for-phone-only {
    display: none;
  }
}

.twoColumnGrid {
  position: relative;
  display: grid;
  grid-template-columns: 715fr 109fr;
  grid-gap: 16px;
  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

.labelContainer {
  @include flex(center, space-between, row);
  margin-bottom: 10px;

  .label {
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: $pigment-green;

    &:after {
      content: " *";
      color: $red;
    }
  }
}

.inputWrapper {
  @include border(1px solid $gainsboro);
  height: 50px;
  @include flex(center, justify-content, row);
  @include padding-left(20px);
  @include padding-right(20px);
  position: relative;
}

.inputText {
  color: $dim-gray;
  font-size: 16px;
  line-height: 19px;
}

.add {
  position: absolute;
  right: 9.6px;
  top: 16.5px;

  g path {
    stroke: $pigment-green;
  }
}

.continueButton {
  align-self: flex-end;
  @include roboto-condensed;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
}
