@import "src/styles/mixins";
@import "src/styles/props";

// table container
.container {
  overflow: visible;

  @include for-phone-only {
    padding: 20px 0;
  }
}

// table legend label
.tableLegendRow {
  @include flex(center, flex-start, row, nowrap);
  @include CastrolSansConNormal;
  border: 1px solid $gainsboro;
  background-color: rgba($light-grey, 0.2);
  padding: 16px 20px;
  margin-bottom: 54px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  @include for-phone-only {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 47px;
  }

  .titleLabel {
    color: $black;
    text-transform: uppercase;

    @include for-phone-only {
      margin-bottom: 16px
    }
  }

  .legend {
    @include flex(center, null, row);

    .description {
      color: $black;
    }

    .requiredIndicator {
      color: $red;
      margin-left: 16px;
      margin-right: 8px;

      @include for-phone-only {
        margin-left: 0;
      }
    }

    .errorLine {
      width: 20px;
      height: 2px;
      background-color: $error-red;
      margin-left: 16px;
      margin-right: 8px;

      @include for-phone-only {
        margin-left: 0;
      }
    }
  }


}

.tableContainer {
  overflow-x: auto;
}

// table header
.tableHeader {
  @include border-top(1px solid $gainsboro);
  @include border-bottom(1px solid $gainsboro);
  width: 1775px;

  .tableRow {
    @include flex(center, flex-start, row, nowrap, false);

    &.groupFirstAbove {
      @include border-bottom(1px solid $pigment-green);
    }

    .tableColumn {
      @include padding(12px);
      min-height: 40px;

      &:first-child {
        @include border-left(1px solid $gainsboro);
      }
    }

    .newDocumentHeaderContainer {
      display: flex;
      justify-content: space-between;
      flex: 1 1;
    }

  }
}

// table bottom
.tableBottom {
  @include border-bottom(1px solid $gainsboro);
  width: 1775px;
  margin-bottom: 5px;

  .tableRow {
    @include flex(center, flex-start, row, nowrap, false);
    @include border-right(1px solid $gainsboro);
    height: 44px;

    .tableColumn {
      @include border-left(1px solid $gainsboro);
      @include padding(12px);
      height: 44px;
      min-height: 44px;
      position: absolute;
      left: 0;

      &.addColumn {
        cursor: pointer;
      }
    }

  }
}

// table column
.tableColumn {
  @include CastrolSansConNormal;
  @include border-right(1px solid $gainsboro);
  @include flex(null, null, column);
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: $dim-gray;
  background-color: $white;

  &:nth-child(2n) {
    background-color: $light-grey3;
  }

  &:focus {
    border: 1px solid $pigment-green;
  }

  &.oemName {
    @include border-left(1px solid $gainsboro);
    @include flex(null, null, row, nowrap);

    &.desktop {
      position: absolute;
      left: 0;
      display: flex;
    }

    &.mobile {
      display: none;
    }

    @include for-phone-only {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: flex;
      }
    }

    .groupIcon {
      width: 30px;

      .groupStart,
      .groupMiddle,
      .groupEnd {
        margin-left: 9px;
        display: none;
      }
    }
  }

  &.productName {
    margin-left: 250px;

    @include for-phone-only {
      margin-left: 0;
    }
  }

  &.productId {
    padding: 14px 10px;
    height: 50px;
  }

  &.commentLabel {
    span {
      color: $pigment-green;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.actionColumn {
    @include flex(center, flex-start, row, nowrap);
    padding: 10px 12px;
    position: absolute;
    right: 0;
    z-index: 10;
    background-color: $white;
    border-right: unset;
    opacity: 1;
    box-shadow: -30px 0 20px -10px $white;

    @include for-phone-only {
      opacity: 1;
      position: unset;
      z-index: unset;
      box-shadow: none;
    }

    .copyIcon,
    .removeIcon {
      cursor: pointer;

      &:focus {
        outline: unset;
      }
    }

    .removeIcon {
      margin-left: 12px;
    }
  }

  @include for-phone-only {
    padding: 0;
  }

}

// table label column
.tableLabelColumn {
  @include flex(center, flex-start, row, nowrap);
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
  color: $black;

  &:first-child {
    position: absolute;
    left: 0;
    z-index: 10;

    @include for-phone-only {
      position: unset;
      left: unset;
      z-index: unset;
    }
  }

  &:nth-child(2) {
    margin-left: 250px;

    @include for-phone-only {
      margin-left: 0;
    }
  }

  .requiredIndicator {
    color: $red;
    margin-right: 4px;
  }
}


// table body
.tableBody {
  width: 1775px;

  .tableRow {
    @include flex(center, flex-start, row, nowrap, false);
    @include border-bottom(1px solid $gainsboro);
    // position: relative;

    &:last-child {
      @include border-bottom(1px solid $gainsboro);
    }

    &.groupFirstAbove {
      @include border-bottom(1px solid $pigment-green);
    }

    &.group {
      .tableColumn {
        background-color: $light-green;

        &.oemName {
          @include border-left(1px solid $pigment-green);

          .groupMiddle {
            display: block;
          }
        }

        &.lastColumn {
          @include border-right(1px solid $pigment-green);
        }

        &.actionColumn {
          background-color: $white;
        }
      }
    }

    &.groupFirst{
      .tableColumn {
        &.oemName {
          .groupMiddle {
            display: none;
          }
          .groupStart {
            display: block;
          }
        }
      }
    }


    &.groupLast {
      @include border-bottom(1px solid $pigment-green);
      .tableColumn {
        &.oemName {
          .groupMiddle {
            display: none;
          }
          .groupEnd {
            display: block;
          }
        }
      }
    }
  }
}

.selectControl {
  border: none;
  height: 50px;

  &.nameSelector {
    width: 218px
  }

  svg {
    right: 10px;
  }
}

.selectInput {
  @include Arial;
  font-size: 14px;
  padding: 0 32px 0 10px;
}

.selectOption {
  @include Arial;
  font-size: 14px;
}

.checkboxWrapper {
  @include flex(center, flex-start, row, nowrap);
  width: 91px;
  height: 26px;
  padding: 12px 14px;

  .checkboxLabel {
    @include Arial;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.fileInput {
  width: 199px;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 36px;
  border: 0;

  .fileInputText {
    @include Arial;
    font-size: 14px;
  }
}

.datePickerWrapper {
  width: 130px;
}

.pinIcon {
  position: absolute;
  right: 9.6px;
  top: 50%;
  margin-top: calc(-11px / 2);
  cursor: pointer;
}

.inputWrapper {
  border: none;
  padding: 0;

  input {
    @include Arial;
    width: calc(100% - 22px);
    padding: 0 10px;
    border: 0;
    height: 48px;
    font-size: 14px;
    line-height: 20px;

    // &:focus {
    //   border: 1px solid $pigment-green;
    // }
  }
}