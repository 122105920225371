@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include padding(37px 64px 40px);
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}
.nopad{
  padding: 0px;
}
