@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  width: 460px;
  background-color: $pigment-green;

  @include for-phone-only {
    @include padding(20px);
    width: 100%;
  }

  h2 {
    @include CastrolSansConNormal;
    font-size: 30px;
    color: $white;
    margin-top: 6px;
    text-transform: uppercase;
    @include for-phone-only {
      font-size: 26px;
      line-height: 36px;
    }
  }
}

// title
.title {
  color: $white;
  margin-top: 31px;
  @include for-phone-only {
    font-size: 40px;
    line-height: 48px;
    margin-top: 14px;
  }
}

// description
.description {
  @include Arial;
  color: $white;
  font-size: 16px;
  line-height: 22px;
  margin-top: 38px;
  @include for-phone-only {
    font-size: 12px;
    line-height: 20px;
    margin-top: 14px;
  }

  a {
    @include Arial;
    color: inherit;
    text-decoration: underline;
    font-weight: 700;
  }
}
