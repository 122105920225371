@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(40px);
  position: absolute;
  top: 0;
  width: 450px;
  background-color: $white;
  box-shadow: 0 10px 20px rgba($black, 0.16);
  z-index: 200;
  @include for-phone-only {
    padding: 15px;
    width: 100%;
  }
}

.cover {
  @include fullscreen;
  position: fixed;
  z-index: 100;
}

.innerContainer {
  max-height: 500px;
  overflow: auto;
}
