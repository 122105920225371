@import "src/styles/mixins";
@import "src/styles/props";

// products container
.productsContainer {
  margin-top: 30px;
  @include for-phone-only{
    margin-top: 20px;
  }
}

// loading
.loading {
  height: 100px;
}

// left drawer
.LeftDrawer{
  @include for-phone-only{
    display: none;
  }
}

// range details container
.rangeDetailContainer{
  display: none;
  @include for-phone-only{
    display: block;
  }
}

// line divider
.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  display: none;
  @include for-phone-only{
    display: block;
  }
}