@import "src/styles/mixins";
@import "src/styles/props";
// container
.container {
  @include padding(20px);
  @include flex(stretch, space-between, column);
}

// modal custom style
.modalCustom {
  width: 400px;
  @include for-phone-only {
    width: 100%;
  }
}

//header
.header {
  font-size: 18px;
  line-height: 22px;
  @include CastrolSansConNormal;
}

//message
.message {
  font-size: 14px;
  line-height: 18px;
  @include Arial;
  margin-top: 20px;
}

// button container
.buttonContainer {
  @include flex(center, center);
  margin-top: 20px;
}

// button
.button {
  height: 35px;
}

// submit button
.submitButton {
  width: 100px;
}

// cancel button
.cancelButton {
  margin-left: 20px;
  text-transform: none;
  font-weight: 400;
}
