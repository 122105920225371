@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
    @include border-top(1px solid $gainsboro);
    @include border-bottom(1px solid $gainsboro);
    @include flex(null, null, column);

    &.opened {
      background-color: rgba($light-grey, 0.2);
      @include border(1px solid $gainsboro);

      .dropdownArrow {
        transform: rotate(180deg);
      }
    }
  }

// header container
.headerContainer {
  @include flex(center, space-between, row);
  @include padding(15px);
  cursor: pointer;
}

// summary header
.title {
  @include flex(center);
  color: $dim-gray;
  h3 {
    text-transform: uppercase;
  }
}

// right-section
.rightSection {
  @include flex(center, space-between, row);
  .products {
    font-size: 16px;
    text-transform: uppercase;
    color: $pigment-green;
    @include CastrolSansConNormal;
    line-height: 19px;
    margin-right: 5px;
  }
}

// list container
.listContainer {
    @include padding(22px 24px 20px);
    @include border-top(1px solid $gainsboro);
  }