@import "src/styles/mixins";
@import "src/styles/props";

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only {
    padding: 0;
    background-color: unset;
  }
}

.parentWidth {
  max-width: 100%;
}

.formWrapper {
  @include flex(null, null, column);
  @include padding(20px);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;

  @include for-phone-only {
    padding-top: 0px;
  }

  .sectionHeader {
    font-size: 16px;
    text-transform: uppercase;

    @include for-phone-only {
      display: none;
    }
  }
}

.actionRow {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only {
    margin: 20px;
    width: auto;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only {
    width: 135px;
  }
}

.saveButton {
  width: 95px;

  @include for-phone-only {
    width: 135px;
  }
}

.richTextarea {
  width: 100%;
  height: 200px;
  margin-bottom: 50px;

  div,
  p,
  strong,
  b,
  em,
  ul,
  ol,
  li {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  u {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: $salem;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.phoneOnly {
  display: none;
  @include for-phone-only {
    display: initial;
  }
}

.newHelpTitle {
  font-size: 30px;
  padding-top: 10px;
  padding-left: 20px;
  text-transform: uppercase;
}

// link text
.link {
  @include CastrolSansConRegular;
  color: $pigment-green;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
}

.viewValue {
  padding-left: 16px;
}
