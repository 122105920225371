@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  flex: 1;
  max-width: 100%;
  @include flex(stretch, flex-start, column, nowrap);
  @include Arial;
  overflow: hidden;
}

.valueInputWrapper {
  @include flex(center, stretch, row, nowrap);
  border: solid 1px $pigment-green;
  background-color: $white;
  overflow: hidden;
  &.valueInputSmallPadding {
    > input {
      padding: 9px 8px;
    }
  }
  > input {
    flex: 1;
    padding: 20px 20px 14px;
    font-size: 16px;
    line-height: 16px;
    color: $dim-gray;
    @include CastrolSansConRegular;
    overflow: hidden;
  }
  .checkCircle {
    width: 16px;
    height: 16px;
    margin-right: 20px;
  }
}
