@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}

.title {
  @include CastrolSansConNormal;
  font-size: 24px;
  line-height: 29px;
  padding: 0 33px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @include for-phone-only {
    padding: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 23px;

  @include padding(32px 10% 114px 10%);
  background-color: $light-grey2;

  @include for-phone-only{
    padding: 0;
    background-color: unset;
    margin-top: 20px;
  }
}

.backButton {
  width: 87px;
}

.loadingOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loadingOverlayDim {
  opacity: 0.2;
  pointer-events: none;
}