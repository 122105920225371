@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(stretch, null, column);
}

// label
.label {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
