@import "src/styles/mixins";
@import "src/styles/props";

.container {
  width: 100%;

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
  }
}

.card {
  width: 100%;
}

.divider {
  @include border-bottom(1px solid $gainsboro);
  width: 100%;
  margin-bottom: 20px;
}

.options {
  width: 100%;
  @include border(1px solid $gainsboro);
  @include padding(20px);
}

.optionsHeader {
  @include flex(center, flex-start, row);
  margin-bottom: 20px;
}

.optionsHeaderText {
  font-size: 16px;
  line-height: 22px;
  @include CastrolSansConNormal;
  margin-right: 10px;
  color: $pigment-green;
}
