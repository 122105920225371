@import "src/styles/mixins";
@import "src/styles/props";

.body {
  background-color: $background-gray;
  @include padding(29px 0px 50px 0px);
  display: grid;
  place-items: center;
  @include for-phone-only {
    @include padding-left(16px);
    @include padding-right(16px);
  }
}

.loading {
  height: 100px;
}
