@import "src/styles/mixins";
@import "src/styles/props";

// title
.title {
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  @include for-phone-only {
    font-size: 40px;
    line-height: 48px;
    margin-top: 14px;
  }
}
