@import "src/styles/mixins";
@import "src/styles/props";

// link
.link {
  color: $pigment-green;
  width: 100%;
  z-index: 1;
  @include for-phone-only {
    width: 80%;
  }
}

.subTitle {
  font-size: 14px;
  margin-top: 2px;
  color: $dim-gray;
}

.productWithNotes {
  min-height: 50px;
}