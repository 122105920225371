@import "src/styles/mixins";
@import "src/styles/props";

.addProductModalContainer {
  max-width: 800px;

  .container {
    @include padding(50px);

    h1 {
      font-size: 36px;
      font-family: "CastrolSansConNormal";
      text-transform: uppercase;
    }

    h2 {
      font-size: 24px;
      text-transform: uppercase;
      font-family: "CastrolSansConNormal";
      margin-top: 30px;
    }

    .tabContainer {
      margin-top: 20px;
    }

    .tabItemSelectedClassName {
      color: $pigment-green;
      & span {
        background-color: $pigment-green;
      }
    }

    .tabItemClassName {
      font-size: 16px;
      font-weight: 700;
      & span {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .tabContent{
      margin-top: 5px;
    }

    .modalContent {
      @include flex(null, null, column);
      margin-top: 16px;

      .physicalOfferContainer {
        border: none;
        padding: 0px;
      }
    }

    .actionBtnContainer {
      @include flex(null, flex-end);
      margin-top: 24px;

      @include for-phone-only {
        @include flex(null, center);
      }

      button {
        @include padding(15px 28px);

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}
