@import "src/styles/mixins";
@import "src/styles/props";

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.pageHeader {
  margin-bottom: 28px;
  @include flex(center);
  text-transform: uppercase;

  @include for-phone-only {
    display: block;
  }

  .title {
    @include CastrolSansConRegular;
    display: inline-block;
    font-size: 36px;
    line-height: 43px;
    flex: 1;

    @include for-phone-only {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .link {
    @include CastrolSansConRegular;
    display: inline-block;
    font-size: 16px;
    line-height: 19px;
    color: $pigment-green;

    @include for-phone-only {
      display: block;
    }

    svg {
      height: 14px;
      width: 14px;
      margin-right: 14px;
    }
  }
}
