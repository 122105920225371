@import "src/styles/mixins";
@import "src/styles/props";

// modal container
.container {
  @include padding(22px 20px 30px);
  position: fixed;
  background-color: $white;
  box-shadow: 0 5px 30px rgba($black, .16);
  width: 320px;
  right: 30px;
  bottom: 90px;
  z-index: 100;

  @include for-phone-only{
    bottom: 70px;
  }
}

// header container
.headerContainer {
  @include flex(center, space-between);
  cursor: pointer;
}

// left group for header
.leftGroup {
  @include flex(flex-start, null, column);
}

// title
.title {
  @include CastrolSansConNormal;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  // @include for-phone-only{
  //   font-size: 26px;
  //   line-height: 31px;
  // }
}

// sub title
.subTitle {
  @include Arial;
  font-size: 14px;
  line-height: 22px;
  margin-top: 1px;
  color: $dim-gray;
}

// arrow icon
.arrow {
  path {
    stroke: $silver;
  }
}

// header opened
.headerContainer.opened {
  .arrow {
    transform: rotate(180deg);
  }
}

// list container
.listContainer {
  margin-top: 20px;
}

// list item gap
.listItem + .listItem {
  margin-top: 5px;
}

// button container
.buttonContainer {
  @include flex(center);
  margin-top: 32px;
}

// button
.button {
  height: 40px;
}

// compare button
.compareButton {
  width: 114px;
  font-size: 14px;
  @include CastrolSansConMedium;
}

// clear button
.clearButton {
  @include CastrolSansConNormal;
  font-size: 14px;
  text-transform: none;
  margin-left: 15px;
}
