@import 'src/styles/mixins';
@import 'src/styles/props';

.tableContainer {
  background-color: $white;
  margin-bottom: 20px;
  margin-top: 20px;
}

.header {
  display: flex;
  height: 70px;
  margin: 20px;
  background-color: $background-gray;

  .usersIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  .totalUserWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 15px;

    .totalUser {
      font-size: 14px;
      color: $dim-gray;
      text-transform: uppercase;
    }

    .userCount {
      color: $black;
      font-size: 16px;
      margin-top: 6px;
    }
  }
}

.table {
  margin: 0 20px;
  padding-bottom: 20px;
  padding-top: 5px;

  .tableTitle {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;

    .date {
      color: $pigment-green;
      margin-left: 5px;
    }

    .exportToExcel {
      @include flex(center);
      @include CastrolSansConNormal;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: $pigment-green;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      svg {
        margin-right: 16px
      }
    }
  }

  .change {
    margin-left: 5px;
  }

  .green {
    color: $pigment-green;
  }
}