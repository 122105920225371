@import "src/styles/mixins";
@import "src/styles/props";

// tabel container
.tableContainer {
  margin-top: 40px;
  @include for-phone-only{
    margin-top: 32px;
  }
}

.tableColumn:first-child {
  padding: 0 !important;
}

.actionsColumn {
  @include for-phone-only {
    display: none !important;
  }
}

.mobilePosition {
  @include for-phone-only {
    top: unset !important;
    bottom: -100px;
    right: 2px !important;
    width: 99% !important;
  }
}

.showProducts {
  color: $pigment-green;
  cursor: pointer;

  &:hover {
    text-decoration: underline;;
  }
}

.actions {
  @include flex(null, null, column);
  padding: 10px 0;

  .mobileActionButton {
    height: 40px;
    padding: 0 20px;
    justify-content: flex-start;

    svg {
      margin-right: 20px;
    }
  }

  .mobileActionButton + .mobileActionButton {
    margin-top: 14px;
  }
}