@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  margin-top: 34px;
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 11.5px) calc(50% - 11.5px);
  grid-column-gap: 23px;
  grid-row-gap: 20px;
  margin-top: 21px;
  overflow-y: auto;
  @include for-phone-only {
    grid-template-columns: auto;
    max-width: calc(100vw - 32px);
    grid-row-gap: 0px;
  }
  &.mobile{
    display: none;
    @include for-phone-only {
      display: grid;
    }
  }
  &.desktop{
    display: grid;
    @include for-phone-only {
      display: none;
    }
  }
}

// loading container
.loading {
  height: 400px;
}

// columns gap
.column + .column {
  margin-left: 38.5px;
  @include for-phone-only {
    margin-left: 0px;
  }
}

// gap of search result container for each group
.searchResultContainer + .searchResultContainer {
  margin-top: 34.5px;
  @include for-phone-only {
    margin-top: 25px;
  }
}

.searchResultContainer{
  @include for-phone-only {
    margin-top: 25px;
  }
}
