@import 'src/styles/mixins';
@import 'src/styles/props';

.tabs {
  display: flex;
  margin-top: 21px;
  margin-bottom: 16px;
  border-bottom: 1px solid $gainsboro;

  .tab {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    color: $dim-gray;
    margin-right: 25px;
    margin-bottom: 18px;
    text-transform: uppercase;
    cursor: pointer;

    &.active {
      color: $ruby;
    }

    &.active::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      background-color: $ruby;
      margin-top: 38px;

      @media (max-width: 491px) {
        top: 10px;
      }
    }
  }
}