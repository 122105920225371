@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  overflow: auto;
  position: relative;
}

// header
.header {
  @include padding-bottom(10px);
  @include border-bottom(1px solid $gainsboro);
  background-color: $white;
  position: sticky;
  top: 0;
  left: 0;

  h3 {
    text-transform: uppercase;
  }
}

// list item
.listItem {
  @include rect(100%, 40px);
  @include flex(center);
  font-size: 16px;
  color: $dim-gray;
  cursor: pointer;

  &:hover {
    background-color: rgba($black, .1);
  }
}
