@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(flex-start, null, column);
}

// title
.title {
  @include CastrolSansConNormal;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 44px;
  @include for-phone-only {
    font-size: 30px;
    line-height: 36px;
  }
}

.subTitleContainer {
  @include flex(flex-start, null, row);
}

// sub title
.subTitle {
  margin-top: 3px;
  text-transform: uppercase;
  @include for-phone-only {
    font-size: 20px;
    line-height: 24px;
  }
}

.subTitleDivider {
  margin: 0 10px;
  color: $dim-gray;
  @include for-phone-only {
    font-size: 20px;
    line-height: 24px;
  }
}

// sub title
.subTitle2 {
  margin-top: 3px;
  text-transform: uppercase;
  color: #c90d5c;
  @include for-phone-only {
    font-size: 20px;
    line-height: 24px;
  }
}

// description
.description {
  @include Arial;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  color: $dim-gray;
  max-width: 682px;
  @include for-phone-only {
    max-width: 100%;
  }
}
