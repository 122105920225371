@import "src/styles/mixins";
@import "src/styles/props";

.tooltipExtended{
  a {
    color: $pigment-green;
  }
}

// container
.container {
  @include padding(24px 0);
  @include flex(stretch, null, column);
  @include for-phone-only{
    @include padding(0);
    @include flex(flex-start, space-between, column);
  }
}

// item gap
.item + .item {
  margin-top: 22px;
}

// last item
.item:last-child {  
  @include for-phone-only{
    margin-top: 22px;
  }
}
