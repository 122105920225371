@import "src/styles/mixins";
@import "src/styles/props";

.shareByEmailContainer {
  max-width: 865px;
  height: 100%;
  max-height: 750px;
  overflow: auto;

  @include for-phone-only {
    max-height: 673px;
  }

  .container {
    @include padding(48px);

    @include for-phone-only {
      padding: 0;
    }

    h1 {
      @include CastrolSansConNormal;
      font-size: 36px;
      line-height: 48px;
      font-weight: 400;
      text-transform: uppercase;

      @include for-phone-only {
        font-size: 26px;
        line-height: 35px;
        padding: 27px 20px 0 20px;
      }
    }

    .modalContent {
      @include flex(null, null, column);
      margin-top: 40px;

      @include for-phone-only {
        padding: 0 20px;
        margin-top: 32px;
        min-height: 250px;
      }

      .subTitle {
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
        margin-top: 32px;
        margin-bottom: 24px;

        @include for-phone-only {
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
    }

    .actionBtnContainer {
      @include flex(null, flex-end);
      margin-top: 32px;

      .sendButton {
        width: 92px;
      }

      @include for-phone-only {
        @include flex(null, center);
        @include border-top(1px solid $gainsboro);
        margin-top: 20px;
        padding: 20px 20px 40px 20px;

        .sendButton {
          width: 100%;;
        }
      }
    }
  }
}