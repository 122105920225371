@import "src/styles/mixins";
@import "src/styles/props";

// action  menu container
.container {
  position: relative;
  display: flex;
  justify-content: center;

  .settingIcon {
    cursor: pointer;
  }
}

// click detector
.clickDetector {
  @include fullscreen;
  position: fixed;
  z-index: 100;
}

// option container
.optionContainer {
  position: absolute;
  min-width: 215px;
  top: 6px;
  left: calc(-230px + 50%);
  background-color: $white;
  max-height: 200px;
  box-shadow: 0 10px 20px rgba($black, .2);
  z-index: 200;
}
