@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  @include flex(stretch, center, column, nowrap);
  flex: 1;
  overflow: hidden;
  .editIcon {
    width: 16px;
    height: 16px;
    display: none;
    margin-left: 12px;
  }
  &:not(.edit) {
    @include clickable();
    &:hover {
      background-color: rgba($color: $gainsboro, $alpha: 0.4);
      .editIcon {
        display: block;
      }
    }
  }
}

.label {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-bottom: 4px;
  &.requiredAsterisk:after {
    content: ' *';
    color: $red;
  }
}

.content {
  @include flex(center, space-between, row, nowrap);
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  white-space: normal;
  &.contentBold {
    font-weight: bold;
  }
}
