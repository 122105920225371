@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include item-flex(1, 1, 0);
  @include padding(60px 60px 80px 60px);
  min-width: 800px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;


  @include for-phone-only {
    @include padding(32px 16px 80px 16px);
    min-width: unset;
    max-width: unset;
    flex-direction: column;
  }
}

.leftPart {
  width: calc(50% - 20px);

  @include for-phone-only {
    width: 100%
  }

  .title {
    @include CastrolSansConRegular;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
  }

  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 20px 0 40px;
  }

  .accountContainer {

    .infoRow {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .infoLabel {
        width: 40%;
        @include Arial;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
      }

      .infoValue {
        @include CastrolSansConRegular;
        color: $dim-gray;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}



.rightPart {
  width: calc(50% - 20px);
  max-width: 380px;
  margin-left: 40px;
  padding: 40px 62px;
  background-color: rgba($gainsboro3, 0.2);

  @include for-phone-only {
    width: calc(100% - 40px);
    height: unset;
    margin-left: unset;
    padding: 20px;
    margin-top: 30px;
  }

  .title{
    @include CastrolSansConRegular;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
  }

  .buttonContainer {
    .button {
      @include CastrolSansConRegular;
      width: 100%;
      margin-top: 20px;
      padding: 0 16px;
      justify-content: flex-start;

      svg {
        margin-right: 12px;
      }

    }

    // @include for-phone-only {
    //   width: 100%;
    // }
  }

  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 30px 0;
  }

}

