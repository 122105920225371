@import "src/styles/mixins";
@import "src/styles/props";

.menuCard {
  height: 140px;
  width: 320px;
  background-color: lighten($light-grey, 10%);
  padding: 20px;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: $pigment-green;

    .title, .description {
      color: $white !important;

      span {
        color: $white !important;
      }
    }
  }
}

.title {
  @include CastrolSansConNormal;
  display: flex;
  color: $pigment-green;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;

  span {
    color: $dim-gray;
  }

  .arrowIcon {
    margin-left: 10px;
  }
}

.description {
  color: $dim-gray;
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;

  p {
    @include Arial;
  }
}