@import "src/styles/mixins";
@import "src/styles/props";

// checkbox
.checkbox {
  @include box(20px);
  @include border(1px solid $dark-gray);
  @include flex(center, center);
  min-width: 20px;
  cursor: pointer;

  &.checked {
    border-color: $pigment-green;
    background-color: $pigment-green;
  }
  &.disabled {
    background: #F7F8F9;
    opacity: 0.5;
    cursor: unset;

    input {
      cursor: unset;
    }
  }
  &.notEditable {
    cursor: unset;

    input {
      cursor: unset;
    }
  }
}
