@import "src/styles/mixins";
@import "src/styles/props";

.privacyPolicy {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  cursor: pointer;

  span {
    @include Arial;
    color: $dim-gray;
    font-size: 16px;
    line-height: 22px;
    margin-left: 12px;
  }
}

.link {
  @include Arial;
  color: $pigment-green;
  font-size: 16px;
  line-height: 24px;
}

.descriptionTitle {
  @include Arial;
  color: $dim-gray;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-top: 24px;
}

.description {
  @include Arial;
  color: $dim-gray;
  font-size: 16px;
  line-height: 24px;
}