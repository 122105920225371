@import "src/styles/mixins";
@import "src/styles/props";

.container {
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 28px;
    @include CastrolSansConNormal;
    @include for-phone-only{
      font-size: 20px;
      line-height: 24px;
    }
  }
}

// tab container
.tabContainer {
  margin-top: 23px;
}

// search list item gap
.searchListItem + .searchListItem {
  margin-top: 16px;
}

// no matched results
.noMatchedResults {
  @include flex(center, center);
  @include CastrolSansConNormal;
  margin-top: 12px;
  font-size: 16px;
  line-height: 19px;
  color: $dim-gray;
  width: 100%;
  height: 200px;
}
