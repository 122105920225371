@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(flex-start, null, column);

  h1 {
    font-size: 36px;
    line-height: 44px;
    @include CastrolSansConNormal;
    @include for-phone-only{
      font-size: 30px;
      line-height: 36px;
    }
  }
  h2{
    font-size: 22px;
    line-height: 27px;
    @include CastrolSansConNormal;
    @include for-phone-only{
      font-size: 20px;
      line-height: 24px;
    }
  }

  margin-right: 20px;
}

// title
.title {
  text-transform: uppercase;
}

// sub title
.subTitle {
  margin-top: 3px;
  text-transform: uppercase;
}

// description
.description {
  @include Arial;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  color: $dim-gray;
  max-width: 682px;
  white-space: pre-wrap;
}

// contact us button
.button {
  min-width: 140px;
  padding: 0 20px;
  margin-top: 20px;
  @include CastrolSansConMedium;
  font-size: 16px;
  line-height: 19px;
}
