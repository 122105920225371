@import 'src/styles/mixins';
@import 'src/styles/props';

.radioGroupWrapper {
  width: 50%;
  overflow: hidden;
  @include padding(0px 20px 20px 20px);
  @include border(1px solid $gainsboro);
  @include flex(stretch, stretch, column, nowrap);
  margin-top: 20px;
  flex: 1 !important;

  &:not(:last-child) {
    margin-right: 20px;
  }

  > * {
    height: 100%;
  }

  .optionsContainer {
    flex: 1;
    overflow: auto;
  }

  @include for-phone-only {
    width: 100%;
    flex: none !important;
  }

  .itemLabelWrapper {
    @include flex(center);

    &:not(:last-child) {
      padding-bottom: 12px;
    }

    .label {
      flex: 1;
    }

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
