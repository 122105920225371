@import "src/styles/mixins";
@import "src/styles/props";

// title
.title {
  @include CastrolSansConNormal;
  @include flex(center, space-between);
  font-size: 20px;
  line-height: 24px;
  margin: 24px 0;
  text-transform: uppercase;
  @include for-phone-only{
    margin: 20px 0;
  }
}

.btnClear {
  @include CastrolSansConNormal;
  font-weight: normal;
  text-decoration: underline;
}