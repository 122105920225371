@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  @include Arial;
  flex: 1;
  overflow: hidden;
}

.selectDropdown {
  padding: 0px 0px 0px 10px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: $white;

  & > input {
    padding: 0px 0px 0px 10px;
  }
}
