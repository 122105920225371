@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 33px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @include for-phone-only {
    padding: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only{
    padding: 0;
    background-color: unset;
  }
}

.calendarRow {
  width: 270px;
  padding-top: 20px;

  @include for-phone-only {
    width: 93%;
  }
}

.reportDetailContainer {
  @include flex(flex-start, center, column);
  width: 100%;
  margin-bottom: 24px;

  @include for-phone-only{
    margin-bottom: 0;
  }
}

.quickStatsContainer {
  width: 100%;
  background-color: $white;

  @include for-phone-only{
    padding: 0;
  }
}

.quickStats {
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;

  @include for-phone-only{
    &.desktop {
      display: none;
    }
  }
}

.description {
  font-size: 14px;
  margin-top: 20px;
  font-weight: normal;
  color: $dim-gray;
  line-height: 20px;
}

.filterContainer {
  margin-top: 20px;
  border-top: 1px solid $gainsboro;
  display: flex;
  justify-content: space-between;

  .filterButton {
    margin-top: 20px;

    @include for-phone-only {
      width: 20%;
      height: 40px;
    }
  }

  .clearFilter {
    margin-top: 30px;
    display: flex;
    cursor: pointer;

    .cancelIcon {
      margin-right: 8px;
    }

    div {
      text-decoration: underline;
      color: $dim-gray;
    }
  }
}

.formFieldsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @include for-phone-only {
    flex-direction: column;
    gap: unset;
  }
}

// loading
.loading {
  height: 100px;
}

.backButton {
  width: 105px;
  height: 50px;
  margin-top: 24px;
}

.loadingOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loadingOverlayDim {
  opacity: 0.2;
  pointer-events: none;
}

.multipleSelect {
  position: relative;
  width: 290px;
  display: flex;
  flex-direction: row;
  svg {
    right: 15px;
  }

  @include for-phone-only {
    width: 100%;
    height: inherit !important;
  }

  @include for-tabs-l-only {
    height: inherit !important;
    width: 100%;
  }
}

.multipleInput {
  border: 1px solid $gainsboro;
  padding-left: 10px;
  width: 290px;
  height: 48px !important;
  @include CastrolSansConNormal;

  @include for-phone-only {
    width: 100%;
  }
  @include for-tabs-l-only {
    width: 100%;
  }
}

.selectOptions {
  width: 290px;

  @include for-phone-only {
    width: 77vw;
  }
}