@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  position: relative;
  overflow: auto;
}

// table row
.tableRow {
  @include flex();
}

.tableColumn {
  @include CastrolSansConRegular;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.requiredColumn:after {
  content: ' *';
  color: $red;
}

.tableErrorColumn {
}

.tableDropdownColumn {
}

.tableHeader {
  background-color: $white;
  z-index: 2;

  .tableRow {
    display: grid;
    grid-template-columns: 0.6fr 1fr 1fr 2fr 0.4fr 0.2fr;
  }

  .tableColumn {
    @include CastrolSansConNormal;
    color: $pigment-green;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    pointer-events: visible;
  }
}

.tableBody {
  @include Arial;
}

// body row
.tableRow {
  @include border-bottom(1px solid $gainsboro);
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 2fr 0.4fr 0.2fr;
}

.descriptionCard {
  @include padding(20px);
  background-color: $background-gray;
  margin-bottom: 8px;
  .title {
    text-transform: uppercase;
    @include CastrolSansConNormal;
    font-size: 12px;
    line-height: 15px;
    color: $pigment-green;
    margin-bottom: 10px;
  }
  .paraBody {
    color: $dim-gray;
    @include Arial;
    font-size: 14px;
    line-height: 20px;
  }
  @include for-phone-only {
    @include padding(0);
    margin-bottom: 20px;
  }
}

// body column
.tableColumn {
  font-size: 14px;
  line-height: 20px;
  min-height: 59px;
  color: $dim-gray;
  overflow: hidden;
  @include Arial;

  .errorText {
    color: $red;
    @include Arial;
  }

  .arrowDown {
    cursor: pointer;
    path {
      stroke: $pigment-green;
    }
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $ruby;
    font-size: 14px;
    line-height: 20px;

    .errorIcon {
      margin-right: 10px;
    }
  }
}

.input {
  @include border(1px solid $gainsboro);
  @include padding(0 20px);
  @include CastrolSansConNormal;
  width: 80%;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
}

.textarea {
  @include border(1px solid $gainsboro);
  @include padding(13px 20px);
  @include CastrolSansConNormal;
  width: 100%;
  resize: vertical;
  background-color: $white;
}

.errorText {
  color: $red;
  @include Arial;
  font-size: 14px;
  line-height: 20px;
}

.moreLessLink {
  @include Arial;
  color: $pigment-green;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
