@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include horizontal-center;
  @include padding(13px 20px);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  background-color: $white;
  width: 633px;
  position: fixed;
  top: 20px;
  box-shadow: 0 5px 30px rgba($black, 0.16);
  z-index: 1500;
  animation: appear 0.3s forwards;

  @include for-phone-only {
    @include padding(15px);
    width: calc(100% - 30px);
  }

  @keyframes appear {
    from {
      transform: translate(-50%, -100%);
      opacity: 0;
    }

    to {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
}

// left group
.leftGroup {
  display: flex;
  align-items: center;
  @include for-phone-only {
    width: 80%;
  }
}

// message group
.messageGroup {
  @include CastrolSansConNormal;
  margin-left: 16px;
  @include for-phone-only {
    width: 80%;
  }
}

.title {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

.message {
  margin-top: 3px;
  font-size: 14px;
  line-height: 22px;
  color: $dim-gray;
}

// right group
.rightGroup {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  @include for-phone-only {
    flex-direction: column-reverse;
  }
}

.error {
  circle {
    fill: red;
  }
}

// seconds
.seconds {
  @include CastrolSansConNormal;
  margin-right: 14px;
  font-size: 14px;
  line-height: 22px;
  color: $nobel;
  @include for-phone-only {
    margin-top: 16px;
    margin-right: 5px;
  }
}

// close icon
.close {
  @include box(16px);
  cursor: pointer;

  path {
    fill: $dim-gray2;
  }

  &:hover {
    path {
      fill: lighten($dim-gray2, 10%);
    }
  }
}
