@import "src/styles/mixins";
@import "src/styles/props";

// list container
.container {
  @include flex(center, space-between);
  @include padding(0 12px);
  height: 40px;
  background-color: $white-smoke;
}

// product name
.productName {
  @include CastrolSansConNormal;
  @include ellipsis;
  display: block;
  color: $pigment-green;
  font-size: 14px;
  line-height: 22px;
  max-width: 200px;
}

// close icon
.close {
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: .7;
  }
}
