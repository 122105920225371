@import "src/styles/mixins";
@import "src/styles/props";

.container {
  display: none;
  @include for-phone-only {
    display: initial;
  }
}

.step {
  @include flex(center, initial, row);
  margin-bottom: 20px;
}

.numberCircle {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  background-color: $white;
  margin-right: 16px;
  @include border(1px solid $gainsboro);
  @include flex(center, center, row);
}

.activeStepCircle {
  color: $white;
  background-color: $ruby;
}

.completedStepCircle {
  margin-right: 16px;
  width: 28px;
  height: 28px;
}

.label {
  font-size: 14px;
  line-height: 17px;
  @include CastrolSansConNormal;
  text-transform: uppercase;
  color: $black;
}

.completedLabel {
  color: $pigment-green;
}

.currentLabel {
  color: $ruby;
}

.divider {
  width: 100%;
  margin-bottom: 20px;
  @include border-bottom(1px solid $gainsboro);
}
