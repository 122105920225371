@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include box(100%);
  position: absolute;
  background-color: $black;
}

// slider cover
.cover {
  @include box(100%);
  position: absolute;
  background-color: rgba($black, 0.1);
}

// slider control container in bottom
.controlContainer {
  @include bottom-left;
  @include rect(100%, 120px);
  position: absolute;
  background-color: rgba($black, 0.3);
  @include for-phone-only {
    width: 100%;
    @include padding(24px 16px);
    height: 350px;
  }
}

// control inner container
.controlInnerContainer {
  @include flex(center, space-between);
  flex-wrap: nowrap;
  margin: 0 auto;
  width: 1126px;
  height: 100%;
  @include for-phone-only {
    width: 100%;
    align-items: flex-end;
  }
}

// details container
.promotionDetailsContainer {
  @include flex(flex-start, flex-start, column);
  @include CastrolSansConNormal;
  color: $white;
  max-width: calc(100% - 350px);
  @include for-phone-only {
    padding-bottom: 50px;
  }

  .promotionTextContainer {
    @include flex(flex-start, space-between, column);
    min-height: 77px;
  }
}

// promotion title
.title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  @include for-phone-only {
    font-size: 26px;
    line-height: 32px;
  }
}

// promotion description
.description {
  font-size: 16px;
  margin-top: 5px;
  line-height: 18px;
  width: 90%;
  @include Arial;
  @include for-phone-only {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word;
    width: calc(100vw - 32px);
  }
}

// slider container
.sliderContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  @include for-phone-only {
    height: 20px;
  }
}

// slider button
.sliderButton {
  @include rect(24px, 4px);
  min-width: 24px;
  background-color: $white;
  opacity: 0.5;
  transition: opacity 0.5s;
  cursor: pointer;

  &.sliderShow {
    opacity: 1;
  }
}

// slider button gap
.sliderButton + .sliderButton {
  margin-left: 8px;
}

// actions container
.promotionActionsContainer {
  @include flex(center);
  @include for-phone-only {
    margin-top: 20px;
    font-size: 14px !important;
    line-height: 18px !important;
  }
}

// promotion product button
.promotionButton {
  min-width: 161px;
  padding: 0 20px;
  margin-left: 20px;
}

// banner image
.banner {
  @include box(100%);
  @include top-left;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;

  &.bannerShow {
    opacity: 1;
  }
}
