@import "src/styles/mixins";
@import "src/styles/props";

// footer container
.container {
  @include flex(flex-end, space-between);
  @include padding(42px 58px 40px 60px);
  background-color: rgba($light-grey, 0.2);
  @include for-phone-only {
    @include padding(22px 20px);
    flex-direction: column;
    align-items: flex-start;
  }
}

// left group
.leftGroup {
  @include flex(flex-start, null, column);
}

// corp name
.corpName {
  @include CastrolSansConMedium;
  font-size: 16px;
}

// common text
.text {
  @include CastrolSansConNormal;
  font-size: 14px;
  margin-top: 4px;
  color: $light-black;
}

.copyright{
  color: $dim-gray;
}

// right group
.rightGroup {
  @include flex(center);
  @include for-phone-only {
    margin-top: 24px;
    @include flex(flex-start, null, column);
  }

  .text {
    text-transform: uppercase;
  }
}

// text gap
.text + .text {
  margin-left: 31px;
  @include for-phone-only {
    margin-left: 0;
    margin-top: 16px;
  }
}
