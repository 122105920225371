@import "src/styles/mixins";
@import "src/styles/props";

// heading for container
.container {
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only{
      font-size: 20px;
      line-height: 24px;
    }
  }
}

// table container
.tableContainer {
  margin-top: 19px;
}
