@import "src/styles/mixins";
@import "src/styles/props";

// main container
.gridContainer {
  height: 100%;
  overflow: hidden;
  @include flex(center, flex-start, row);
  @include for-phone-only {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

// slider

.item {
  cursor: pointer;
  background-color: #f7f7f7;
  color: #333333;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #dddddd;
  margin-right: 10px;
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
  &.first {
    margin-left: 0;
  }
  &.last {
    margin-right: 0;
  }
  margin-bottom: 5px;
  &.selected {
    background-color: #009343;
    color: #f7f7f7;
  }
  @include for-phone-only {
    width: 100% !important;
    margin: 0;
    margin-bottom: 5px;
    padding: 2px 0 !important;
    max-width: calc(100% - 5px);
  }
}
