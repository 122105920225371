@import "src/styles/mixins";
@import "src/styles/props";

.modalContainer{
  max-width: 865px;
  height: 100%;
  max-height: 580px;
  overflow: auto;

  @include for-phone-only{
    max-width: unset;
  }
}

// container
.container {
  @include padding(48px);

  @include for-phone-only {
    padding: 32px 20px;
  }

  .title {
    @include CastrolSansConRegular;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;

    @include for-phone-only {
      font-size: 26px;
      line-height: 35px;
    }
  }

  .description {
    @include Arial;
    font-size: 16px;
    line-height: 22px;
    color: $dim-gray;
    margin-top: 20px;
  }

  .subTitle {
    @include CastrolSansConRegular;
    font-size: 16px;
    line-height: 22px;
    margin-top: 24px;
  }

  .datePicker {
    @include border(1px solid $gainsboro);
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .fileInput {
    padding-left: 20px;
    padding-right: 20px;

    .fileInputText {
      @include Arial;
      font-size: 14px;
    }
  }

  .pinIcon {
    position: absolute;
    right: 17.6px;
    top: 50%;
    margin-top: calc(-11px / 2);
    cursor: pointer;
  }
}

.actionBtnContainer {
  @include flex(null, flex-start);
  @include border-top(1px solid $gainsboro);
  margin-top: 32px;
  padding-top: 32px;

  @include for-phone-only {
    @include flex(null, space-between);
    margin-top: 20px;
    padding-top: 20px;
  }

  .uploadButton {
    width: 116px;
  }

  .cancelButton {
    width: 96px;
    margin-left: 24px;
  }

}


// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}
