@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include item-flex(1, 1, 0);
  @include padding(60px 60px 80px 60px);
  min-width: 800px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  .errorMessage{
    & a{
      color: $ruby;
    }
  }


  @include for-phone-only {
    @include padding(32px 16px 80px 16px);
    min-width: unset;
    max-width: unset;
    flex-direction: column;
  }
}

.leftPart {
  width: calc(50% - 20px);

  @include for-phone-only {
    width: 100%
  }

  .title {
    @include CastrolSansConRegular;
    font-size: 32px;
    line-height: 43px;
    text-transform: uppercase;
    margin-bottom: 29px;
    position: relative;
  }

  .description {
    @include Arial;
    color: $dim-gray;
    font-size: 16px;
    line-height: 24px;
  }

  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 30px 0 0;

    &.noMarginTop {
      margin: 0 0;
    }

    .dividerLabel {
      @include CastrolSansConRegular;
      position: relative;
      width: 50px;
      height: 20px;
      left: calc(50% - 25px);
      top: -10px;
      text-align: center;
      background-color: $white;
      color: $dim-gray;
    }
  }


  .loginInput {
    span {
      @include CastrolSansConRegular;
      font-size: 22px;
      line-height: 30px;
    }
  }

  .rememberMe {
    display: flex;
    flex-direction: row;
    margin-top: 28px;
    cursor: pointer;

    span {
      @include Arial;
      color: $dim-gray;
      font-size: 16px;
      line-height: 22px;
      margin-left: 12px;
    }
  }

  .radioContainer {
    display: block;
    background-color: rgba($gainsboro3, 0.2);
    padding: 20px;
    width: calc(100% - 40px);
    margin-top: 12px;

    span {
      @include Arial;
      color: $dim-gray;
      font-size: 16px;
      line-height: 22px;
    }

    .rememberMeDesc {
      margin-bottom: 26px;
    }

    .radioLabelWrapper{
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      .label {
        margin-left: 10px;
        cursor: pointer;
        &.active {
          color: $pigment-green;
        }
      }

    }
  }

  .buttonContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .button {
      margin-top: 30px;
      width: 100%;

      &.loginSSO {
        margin-top: 20px;
      }

    }

    @include for-phone-only {
      width: 100%;
      margin-top: 0;
    }
  }

  .error {
    @include Arial;
    font-size: 16px;
    line-height: 22px;
  }

  .inputError{
    border-color: $ruby;
  }
}



.rightPart {
  width: calc(50% - 20px);
  height: 400px;
  margin-left: 40px;
  padding: 40px 60px 0 60px;
  background-color: rgba($gainsboro3, 0.2);

  @include for-phone-only {
    width: calc(100% - 40px);
    height: unset;
    margin-left: unset;
    padding: 20px;
    margin-top: 30px;
  }

  .helpTitle{
    @include CastrolSansConNormal;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .questions {
    margin-top: 39px;

    .question {
      span,
      a {
        @include Arial;
        color: $pigment-green;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        @include for-phone-only {
          font-size: 16px;
        }
      }
    }

    .question + .question {
      margin-top: 28px;

      @include for-phone-only {
        margin-top: 24px;
      }
    }
  }
}

