@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(56px 100px 58px);
  @include item-flex(1, 1, 0);
  @include CastrolSansConNormal;
  position: relative;

  @include for-phone-only {
    @include padding(20px);
    width: 100%;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only {
      font-size: 18px;
      line-height: 22px;
    }
  }

  h3 + p {
    margin-top: 7px;
  }

  p {
    @include Arial;
    font-size: 16px;
    line-height: 22px;
    color: $dim-gray;
    @include for-phone-only {
      font-size: 12px;
      line-height: 20px;
    }
  }

  p.topDescriptions {
    margin-top: 9px;
    margin-bottom: 7px;
  }

  a {
    color: $pigment-green;
    font-weight: 700;

    &:not(:hover) {
      text-decoration: none;
    }
  }
}

.fieldContainer + .fieldContainer {
  margin-top: 27px;
}

// label container
.labelContainer {
  @include flex(center, space-between);

  .loading {
    min-height: auto;
    width: auto;
  }
}

// location button
.locationButton {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  height: auto;
}

// form control
.formControl {
  height: 50px;
  margin-top: 13px;
  @include for-phone-only{
    height: 40px;
  }
}

// checkbox container
.checkboxContainer {
  @include padding(26px 0 24.5px);
  @include border-bottom(1px solid $gainsboro);
  @include flex(center, flex-start);
}

// checkbox
.checkbox {
  @include flex(center);
  cursor: pointer;

  p {
    margin-left: 12px;
  }
}

// footer
.formFooter {
  @include flex(center, space-between);
  margin-top: 26.5px;

  p {
    width: 336px;
    a {
      @include Arial;
    }
    @include for-phone-only {
      width: 50%;
    }
  }

  @include for-phone-only {
    @include flex(center, space-between, row, nowrap);
  }
}

// continue button
.continueButton {
  width: 121px;
  height: 50px;
  margin-left: 43px;
  @include for-phone-only {
    margin-left: 0;
  }
}

// arrow
.arrow {
  margin-left: 9px;
}

// option container
.optionContainer {
  @include flex(center);

  img {
    margin-right: 16px;
  }
}

// submitting button loading
.buttonLoading {
  min-height: auto;
}

// lookup loading
.lookupLoading {
  @include top-left;
  @include box(100%);
  position: absolute;
  background-color: rgba($black, .3);
}
