@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  background-color: $pigment-green;
  color: $white;
  @include for-phone-only{
    width: 100% !important;
    padding: 20px !important;
  }
  h2 {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 38px;
    @include for-phone-only{
      font-size: 26px;
      line-height: 36px;
      margin-top: 4px;
    }
  }

  // title
  .title {
    @include CastrolSansConMedium;
    color: $white;
    margin-top: 30px;
    font-size: 64px;
    line-height: 76px;
    @include for-phone-only{
      font-size: 40px;
      line-height: 48px;
      margin-top: 14px;
    }
  }
}
