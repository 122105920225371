@import "src/styles/mixins";
@import "src/styles/props";


// table container
.container {
  overflow: visible;
  padding: 20px 14px 14px 14px;

  @include for-phone-only {
    padding: 0;
  }
}

// active button
.activeCheckbox{
  display: flex;
  margin-bottom: 6px ;
  .activeCheckboxSpace{
    padding: 0px 0px 10px 10px;
  }
}
.activeButton{
  padding:8px;
  cursor: pointer;
  margin-right: 14px;
  height: 35px;
  margin-top: 2px;
}
.rightContent{
  @include flex(center, flex-start);
}
.totalButton{
  padding:8px;
  cursor: pointer;
  margin-right: 14px;
  margin-top: 2px;
}
// table label
.tableLabelRow {
  @include flex(center, space-between);
  margin-bottom: 16px;

  .tableLabel {
    @include CastrolSansConNormal;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: $black;
    text-transform: uppercase;
  }

  .exportToExcel {
    @include flex(center);
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;

    svg {
      margin-right: 16px
    }

    &:hover {
      text-decoration: underline;
    }

    &.mobile{
      display: none;
      @include for-phone-only {
        display: flex;
      }
    }

    &.desktop{
      display: flex;
      @include for-phone-only {
        display: none;
      }
    }
  }



  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 20px 0;
    display: none;

    @include for-phone-only {
      display: block;
    }
  }
}

// header
.tableHeader {
  @include border-bottom(1px solid $gainsboro);

  .tableRow {
    @include flex(stretch, null, null, nowrap);

    .tableColumn {
      @include padding(12px);
      min-height: 40px;
    }

  }

  @include for-phone-only {
    display: none;
  }
}

// column
.tableColumn {
  @include CastrolSansConNormal;
  @include padding(12px);
  @include flex(center);
  position: relative;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: $dim-gray;

  &:not(.tableLabelColumn) {
    @include flex(center, flex-start);
  }

  &.productName {
    @include flex(flex-start, center, column);
  }

  &.commentLabel {
    color: $pigment-green;

    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.columnWithIcon {
    svg {
      margin-left: 8px
    }
  }

  &.downloadColumn {
    justify-content: center;
    position: relative;

    svg {
      cursor: pointer;
    }
  }

  &.settingColumn {
    justify-content: center;
    position: relative;
  }

  &.documentColumn {
    .link {
      @include flex(center, flex-start, row, nowrap);
      flex: 1 1 auto;
      word-break: break-all;

      svg {
        margin-right: 8px
      }

    }
  }

  // for mobile ui
  &.leftColumn {
    width: 40%;
  }
  &.leftCheckbox{
    width: 20%;
  }

  &.rightColumn {
    flex: 1 1 auto;
  }

  @include for-phone-only {
    padding: 0;
  }

}

// label column
.tableLabelColumn {
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
  color: $black;
}

.padding_0{
  padding-top: 0px;
  height: auto;
}
.margin_20{
  margin-top: 20px;
}

// link text
.link {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  color: $pigment-green;
  @include for-phone-only {
    width: 80% !important;
  }
}

// inactive label
.inactiveLabel {
  @include CastrolSansConNormal;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  background-color: $ruby;
  text-transform: uppercase;
  padding: 1px 8px;
}

// brand label
.brandLabel {
  @include CastrolSansConNormal;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  background-color: $cadetblue;
  text-transform: uppercase;
  padding: 1px 8px;
}
.actionBtnContainer {
  @include flex();
  padding-top: 20px;
  border-top: 1px solid $gainsboro;
  font-size: 14px;
  line-height: 22px;

  .clearButton {
    padding: 10px;
    height: 42px;
  }
}
// body
.tableBody {
  max-height: 800px;
  overflow-y: auto;

  .tableRow {
    @include flex(stretch, null, null, nowrap);
    @include border-bottom(1px solid $gainsboro);

    &.inactive {
      background-color: rgba($ruby, 0.03)
    }

    &.expanded {
      .mobileTableRow {
        display: flex;

        &.firstRow {
          .dropdownArrow {
            transform: rotate(180deg);
          }

          .divider {
            display: block;
          }
        }
      }

      .mobileActionButton {
        display: flex;
      }
    }

    &.collapsed {
      .mobileTableRow.firstRow {
        .divider {
          display: none;
        }
      }

      .mobileTableRow:not(.firstRow) {
        display: none;
      }


      .mobileActionButton {
        display: none;
      }
    }

    @include for-phone-only {
      flex-direction: column;
      flex-wrap: nowrap;

      padding: 0 20px;

      &.inactive,
      &.expanded {
        @include border-left(1px solid $gainsboro);
        @include border-right(1px solid $gainsboro);

        &:first-child {
          @include border-top(1px solid $gainsboro);
        }
      }
    }
  }

  &.mobile{
    display: none;
    @include for-phone-only {
      display: block;
    }
  }

  &.desktop{
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

// sortable column
.sortableColumn {
  @include flex(center);
}

// sort icon
.sortIcon {
  margin-left: 4px;
  transform: rotate(180deg);
  cursor: pointer;
  &.asc {
    transform: rotate(360deg);
  }
}
.inputWrapper{
 
  height: 40px;
  width:62%;
  background-color: $white;
  &.mobile{
    display: none;
    @include for-phone-only {
      display:block;
      width: 80%;
      margin-top: 10px;
    }
  }

  &.desktop{
    display: flex;
    @include for-phone-only {
      display: none;
    }
  }
 
}



.mobileTableRow {
  @include flex(center, flex-start, row, nowrap);
  @include border-bottom(1px solid $gainsboro);
  padding: 12px 0;

  &.firstRow {
    border-bottom: none;
    position: relative;

    .rightColumn {
      width: calc(50% - 12px);
    }

    .divider {
      @include rect(calc(100% + 40px), 1px);
      background-color: $gainsboro;
      position: absolute;
      bottom: 0px;
      left: -20px;
    }
  }
}

.mobileActionButton {
  height: 40px;
  justify-content: flex-start;
  padding-left: 16px;
  font-weight: 400;
  margin-top: 12px;
  svg {
    margin-right: 16px;
  }

  &.lastButton {
    margin-bottom: 12px;
  }
}