@import "src/styles/mixins";
@import "src/styles/props";

.backdrop {
  background-color: $background-gray;
  @include padding-top(29px);
  @include padding-bottom(50px);
  @include for-phone-only {
    @include padding(0px);
  }
}

.pageContentContainer {
  @include item-flex(1, 1, 0);
  @include padding(20px);
  background-color: $white;
  margin: 0 auto;
  max-width: 1400px;
  min-width: 1000px;

  @include for-phone-only{
    padding: 20px 20px;
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}

.prefixDesktop {
  display: block;
  @include for-phone-only {
    display: none;
  }
}

.title {
  @include CastrolSansConRegular;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

.para {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-bottom: 20px;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
  margin-bottom: 30px;

  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}

.stepProgress {
  display: none;

  @include for-phone-only {
    display: block;
    margin-bottom: 20px;
  }
}

.redInfo {
  @include Arial;
  font-weight: bold;
  color: $ruby;
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

