@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  width: 250px;
}

// item gap
.item + .item {
  margin-top: 20px;
}
