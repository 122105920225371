@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include for-phone-only {
    width: 100%;
  }
}

// input wrap
.inputWrap {
  @include border(1px solid $gainsboro);
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  .iconContainer {
    display: inline-block;
    visibility: hidden;
    width: 36px;
    position: relative;
    top: 7px;

    &.showEyeIcon {
      visibility: visible;
    }

    svg {
      cursor: pointer;
    }
  }

  &.changed {
    .iconContainer {
      visibility: visible;
    }
  }

  &.changed:invalid, &.changed.invalid {
    border-color: $ruby;
  }

  &.changed:invalid + .error, &.changed.invalid + .error {
    display: block;
  }

  &.disabled {
    background: #F7F8F9;
    opacity: 0.5;
    cursor: unset;

    input {
      cursor: unset;
    }
  }
}

// input
.input {
  @include CastrolSansConNormal;
  @include padding(0 20px);
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 17px;

  &.showIcon {
    width: calc(100% - 36px);
  }

  &.password {
    letter-spacing:4px;
  }
}


// error
.error {
  display: none;
}
