@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(24px 0);
  @include flex(stretch, null, column);
  @include for-phone-only {
    padding: 5px 0;
  }
}

// label container
.labelContainer {
  @include flex(center, space-between, row);
  svg {
    width: 15px;
    height: 10px;
    display: none;
    @include for-phone-only {
      display: block;
    }
    path {
      stroke: $pigment-green;
    }
    &.expanded {
      transform: rotate(180deg);
    }
  }
}

// link conatiner{
.linkContainer {
  display: block;
  margin-top: 22px;
  @include for-phone-only {
    display: none;
  }
  &.expanded {
    @include for-phone-only {
      display: block;
    }
  }
}

// item gap
.item + .item {
  margin-top: 22px;
}

.label {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

// link text
.link {
  div {
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 22px;
    color: $pigment-green;
    height: 24px;
    text-transform: none;
    cursor: pointer;
  }
}
