@import "src/styles/mixins";
@import "src/styles/props";

.descriptionModal {
  min-height: 200px;
  max-height: 80%;
  width: auto;
  max-width: 100%;
  min-width: 650px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .descModalHeader {
    @include padding(24px);
    line-height: 14px;
    color: $dim-gray;
  }

  .descriptionBody {
    @include padding(16px 16px 16px 40px);
    width: 100%;
    overflow: auto;
  }

  @include for-phone-only {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
