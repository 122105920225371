@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  @include Arial;
  @include flex(stretch, flex-start, column, nowrap);
  overflow: hidden;
  flex: 1;
  .moreItems {
    color: $pigment-green;
  }
}

.selectDropdown {
  padding: 0px;
  height: 42px;
  border: none;
  background-color: $white;
  @include border(1px solid $gainsboro);

  &.multiSelect {
    height: 40px;
  }

  @include for-phone-only {
    width: 60%;
  }

  & > input {
    padding: 4px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    width: calc(100% - 14px);
  }

  & > svg {
    right: 8px;
  }

  &.error {
    border: 1px solid $ruby;
  }
}
