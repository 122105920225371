@import "src/styles/mixins";
@import "src/styles/props";

.commentContainer {
  max-width: 600px;

  .container {
    @include padding(48px);

    @include for-phone-only {
      padding: 0;
    }

    h1 {
      @include CastrolSansConNormal;
      font-size: 26px;
      line-height: 35px;
      font-weight: 400;
      text-transform: uppercase;

      @include for-phone-only {
        padding: 27px 20px 0 20px;
      }
    }

    .modalContent {
      @include flex(flex-start, flex-start, row, nowrap);
      margin-top: 40px;

      .contentWrapper {
        @include flex(flex-start);
        @include item-flex(1, 1, auto);

        @include Arial;

        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $dim-gray;
      }

      @include for-phone-only {
        align-items: flex-start;
        padding: 0 20px;
        margin-top: 32px;
        min-height: 250px;
      }
    }
  }
}