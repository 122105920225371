@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);
  @include padding(20px);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;

  .header {
    @include flex(null, null, column);
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    @include border-bottom(1px solid $gainsboro);
    @include padding-bottom(20px);

    .infoMessage {
      font-size: 14px;
      color: $dim-gray;
      text-transform: initial;
      margin-top: 20px;
      font-family: Arial, Helvetica, sans-serif;
      &:first-child {
        margin-top: 0;
      }
    }

    .message {
      color: $ruby;
      &:first-child {
        margin-top: 0;
      }
    }

    .secondaryTitle {
      margin-top: 20px;
    }
  }

  .content {
    max-width: 100%;
    overflow: hidden;
  }
}
