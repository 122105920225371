.viewport {
  background-color: #f00023;
  width: 100%;
  overflow: hidden;

  &:hover .messages {
    animation-play-state: paused;
  }
}

.messages {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  animation: scroll linear infinite;
  animation-duration: var(--scroll-speed);
  height: 59px;

  &:empty {
    display: none;
  }
}

.message {
  flex: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  justify-content: center;
  white-space: nowrap;
  color: #fbbfc8;
  padding: 0 50%;

  &:first-child {
    padding-left: 100%;
  }

  &:last-child {
    padding-right: 0;
  }

  &__icon {
    margin-right: 16px;
    display: inline-block;
  }

  a {
    color: inherit;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(var(--scroll-left));
  }
}
