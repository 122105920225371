@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);
  background-color: $background-gray;
  margin-top: 20px;

  .productNameWrapper {
    @include flex(null, space-between, row);
  }

  .status {
    margin-left: 5px;
  }

  .statusText {
    @include CastrolSansConNormal;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    background-color: $ruby;
    text-transform: uppercase;
    padding: 1px 8px;
    flex: unset;
    width: 50px;
    margin-top: 4px;
  }

  .productName {
    color: $salem;
    flex: 1 !important;
    display: flex;
    flex-direction: column;
  }

  .excluded {
    @include CastrolSansConNormal;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    background-color: $ruby;
    text-transform: uppercase;
    padding: 1px 8px;
  }

  .productNotes {
    flex: 1 !important;
  }

  .settingsIcon {
    width: 20px;
    height: 20px;
  }

  .checkbox {
    width: 16px;
  }

  .desktopContainer {
    @include for-phone-only {
      display: none;
    }

    .header {
      @include padding(20px 35px 0px 35px);
      @include border-bottom(1px solid $gainsboro);
      text-transform: uppercase;
      display: grid;
      grid-template-columns: 1fr 1.4fr 2.2fr 0.5fr;
    }

    .productCategoriesLabel {
      text-align: center;
    }

    .productCategories {
      @include flex(null, center);
      @include padding-bottom(20px);
      margin-top: 15px;

      span {
        text-transform: uppercase;
        color: $dim-gray;
        font-size: 14px;
        width: 50px;
        text-align: center;
      }
    }

    .productsWrapper {
      @include padding(20px);
      max-height: 600px;
      overflow: auto;
    }

    .productContainer {
      @include padding(15px);
      display: grid;
      grid-template-columns: 1fr 1.4fr 2.2fr 0.5fr;
      background-color: $white;
      position: relative;

      &.inactive {
        background-color: rgba($ruby, 0.03);
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .productCategoryIconContainer {
        @include flex(null, center);
        margin-left: 20px;

        .iconWrapper {
          @include flex(center, flex-end);
          width: 45px;
        }
      }

      .settingsIconWrapper {
        @include flex(null, flex-end);
        flex: 1;
      }
    }
  }

  .phoneContainer {
    display: none;
    background-color: $light-grey;
    @include padding(10px);

    @include for-phone-only {
      @include flex(null, null, column);
    }

    .itemWrapper {
      @include flex(null, null, column);
      @include padding(16px);
      background-color: $white;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &.inactive {
        background-color: rgba($ruby, 0.03);
      }

      .sectionHeader {
        @include flex(null, null, column);
        @include padding-bottom(20px);
        @include border-bottom(1px solid $gainsboro);

        .firstRow {
          @include flex(null, space-between);
          margin-bottom: 14px;
          text-transform: uppercase;
        }
      }

      .contentWrapper {
        @include flex(null, null, column);
        margin-top: 16px;

        .physicalOfferLabel {
          margin-bottom: 16px;
        }

        span {
          text-transform: uppercase;
        }

        .categoryWrapper {
          @include flex(null, space-between);

          span {
            font-size: 12px;
            color: $dim-gray;
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          .iconWrapper {
            @include flex(center, flex-start);
            width: 40px;
          }
        }
      }
    }

    .productNotesLabel {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  .productNotes {
    color: $dim-gray;

    .applyMulti {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      cursor: pointer;

      span {
        color: $dim-gray;
        margin-left: 12px;
      }
    }

    .selectDropdown {
      padding: 0px;
      height: 42px;
      border: 1px solid $gainsboro;
      width: 100%;
      margin-top: 16px;
    }

    .actionButtons {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      .button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .applyChange {
        background-color: $pigment-green;

        svg {
          width: 16px;
          height: 12px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .cancelChange {
        margin-left: 16px;
        background-color: $dim-gray;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
