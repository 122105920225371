@import "src/styles/mixins";
@import "src/styles/props";

// search description
.searchDescription {
  @include CastrolSansConNormal;
  font-size: 24px;
  line-height: 29px;
  color: $dim-gray;
  @include for-phone-only {
    font-size: 14px;
    line-height: 19px;
  }
}

// divider container
.dividerContainer {
  @include flex(center);
  margin-top: 38px;
  @include for-phone-only {
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .dividerLine {
    @include item-flex(1, 1, 0);
  }
}

// divider line
.dividerLine {
  height: 1px;
  background-color: $gainsboro;
  opacity: .5;
}

// divider text
.dividerText {
  @include roboto-condensed;
  font-size: 20px;
  color: $dim-gray;
  margin: 0 22px;
  line-height: 24px;
  text-transform: uppercase;
}

// column container
.browserColumnContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 23px;
  grid-row-gap: 20px;
  margin-top: 21px;
  overflow: auto;

  @include for-phone-only {
    grid-template-columns: auto;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 30px);
    overflow: auto;
  }
}

// column
.column {
  @include item-flex(1, 1, 380px);

  @include for-phone-only {
    max-height: 100%;
    overflow: hidden;
  }
}
