@import "src/styles/mixins";
@import "src/styles/props";

.container {
  .actions {
    @include flex(null, null, column);
    position: fixed;
    background-color: $white;
    box-shadow: 0 10px 20px rgba($black, .2);
    top: 0px;
    z-index: 100;
    min-width: 250px;

    .action {
      @include flex(center);
      @include padding(12px 22px);
      color: $black;
      cursor: pointer;

      &:hover {
        background-color: $pigment-green;

        span {
          color: $white;
        }
      }

      span {
        margin-left: 20px;
        text-transform: uppercase;
      }
    }
  }

  .settingsIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}