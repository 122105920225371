@import "src/styles/mixins";
@import "src/styles/props";
 
// line divider
.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 30px 0;
  @include for-phone-only {
    margin: 20px 0;
  }
}
.csdivider{
  @include rect(100%, 1px);
  background-color: $gainsboro;
  @include for-phone-only {
    margin: 20px 0;
  }
}

.activeCheckbox{
  display: flex;
  margin-bottom: 6px ;
  .activeCheckboxSpace{
    padding: 0px 0px 10px 10px;
  }
}

.expiredCheckbox{
  display: flex;
  margin-bottom: 6px ;
  .expiredCheckboxSpace{
    padding: 0px 0px 10px 10px;
  }
}

.filterGroup {
  margin-top: 24px;
}

.margin_20{
  margin-top: 20px;
}

.padding_0{
  padding-top: 0px;
  height: auto;
}

.actionBtnContainer {
  @include flex();
  padding-top: 20px;
  border-top: 1px solid $gainsboro;
  font-size: 14px;
  line-height: 22px;

  .clearButton {
    padding: 10px;
    height: 42px;
  }
}
 
// loading
.loading {
  height: 100px;
}
 
// detail container
.productDetailContainer {
  @include flex(flex-start, space-between, null, wrap, false);
 
  flex-wrap: nowrap;
 
  @include for-phone-only {
    flex-direction: column;
  }
 
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}
 
.portTable {
  margin-top: 40px;
  @include for-phone-only {
    margin-top: 20px;
  }
}
 
// left drawer
.leftDrawer {
  padding-top: 40px;
  @include for-phone-only {
    display: none;
  }
}
 
// left details
.leftDetail {
  display: none;
  @include for-phone-only {
    display: block;
  }
}
 
// table conatiner
.tableContainer {
  @include for-phone-only {
    width: 100%;
    overflow: auto;
  }
}
 
.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 0;
  padding-left: 50px;
  margin: 50px 0;
  margin-bottom: 1.5em;
  position: relative;
  border: 2px solid #6a8db6;
  background-color: #fff;
  @include for-phone-only {
    width: calc(100vw - 40px);
    padding: 10px 5px;
  }
  h3{
    color: #6a8db6;
    margin-bottom: 5px;
  }
}
 
.message {
  align-self: baseline;
  justify-content: center;
}
 
.alternateProducts{
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  .item{
    padding: 5px;
    color: $white;
    background-color: #fe4110;
    line-height: 22px;
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 10px;
    margin-top: 5px;
    a{
      color: $white;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
 
.mobile {
  display: none;
 
  @include for-phone-only {
    display: block;
  }
}
 
 
.oemApprovalsContainer {
  margin-top: 42px;
 
  @include for-phone-only {
    margin-top: 0;
  }
}
 
.noPortsWithAvailability {
  @include Arial;
  font-size: 16px;
  line-height: 24px;
  color: $dim-gray;
  border: 1px solid $gainsboro;
  margin-top: 20px;
  padding: 10px 20px;
  display: flex;
 
  .icon {
    margin-right: 12px;
  }
}
.container {
.tabContainer {
  margin-top: 20px;
}
 
.tabItemSelectedClassName {
  color: $pigment-green;
  & span {
    background-color: $pigment-green;
  }
}
 
.tabItemClassName {
  font-size: 14px;
  font-weight: 700;
  & span {
    font-size: 14px;
    font-weight: 700;
  }
}
 
.tabContent{
  margin-top: 4px;
}
}
 
.exportDiv{
  display: flex;
  justify-content: end;
  margin-right: 6%;
 
  .exportToExcel {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;
    width: fit-content;
  
    svg {
      margin-right: 8px
    }
  
    &:hover {
      text-decoration: underline;
    }
  
    &.mobile{
      display: none;
      @include for-phone-only {
        display: flex;
      }
    }
  
    &.desktop{
      display: flex;
      @include for-phone-only {
        display: none;
      }
    }
  }
}