@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  p {
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 22px;
  }
}

// list container
.listContainer {
  margin-top: 20px;
}

// list item gap
.listItem + .listItem {
  margin-top: 16px;
}
