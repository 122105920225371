.react-datepicker {
  font-family: 'CastrolSansConNormal';
  font-weight: 400;
  border: none;
  border-radius: 0;
  font-size: 16px;
  line-height: 22px;
  box-shadow: 0 4px 8px #666666;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  border-radius: 0;
  padding: 14px 0 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 0;
}

.react-datepicker__current-month {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  padding-bottom: 14px;
  border-bottom: 1px solid #dddddd;
  z-index: 100;
}

.react-datepicker__navigation-icon::before {
  border-color: #009343;
  border-width: 2px 2px 0 0;
  top: 14px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #009343;
}

.react-datepicker__day-names {
  text-transform: uppercase;
}

.react-datepicker__day-name {
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #fff;

  width: 32px;
  height: 32px;
}

.react-datepicker__day {
  border: 1px solid #fff;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;

  width: 32px;
  height: 32px;
}

.react-datepicker__day:hover {
  border-radius: 0;
  border: 1px solid #dddddd;
  background-color: #f7f8f9;
}


.react-datepicker__day--selected,
.react-datepicker__day--highlighted,
.react-datepicker__day--selected:hover,
.react-datepicker__day--highlighted:hover {
  border-radius: 0;
  border: none;
  background-color: #009343;
  outline: unset;
}


.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0;
  border: 1px solid #dddddd;
  background-color: #f7f8f9;
  color: #000;
  outline: unset;
}



.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0;
}

.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 0;
}

.react-datepicker__day--outside-month {
  color: #999999;
  pointer-events: none;
}

.react-datepicker__month-dropdown-container--scroll{
  margin-right: 30px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow{
  margin-top: 5px;
  width: 6px;
  height: 6px;
}
