@import "src/styles/mixins";
@import "src/styles/props";

.deactivateEomModalContainer {
  max-width: 600px;

  .container {
    @include padding(48px);

    @include for-phone-only {
      padding: 0;
    }

    h1 {
      @include CastrolSansConNormal;
      font-size: 36px;
      line-height: 48px;
      font-weight: 400;
      text-transform: uppercase;

      @include for-phone-only {
        font-size: 26px;
        line-height: 35px;
        padding: 27px 20px 0 20px;
      }
    }

    .modalContent {
      @include flex(null, null, column);
      margin-top: 40px;

      @include for-phone-only {
        margin-top: 32px;
      }

      .description {
        @include Arial;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $dim-gray;

        @include for-phone-only {
          padding: 0 20px;
        }
      }
    }

    .actionBtnContainer {
      @include flex(null, flex-start);
      margin-top: 32px;

      @include for-phone-only {
        @include flex(null, space-between);
        @include border-top(1px solid $gainsboro);
        margin-top: 0;
        padding: 20px 20px 40px 20px;
      }

      .confirmButton {
        width: 116px;
      }

      .cancelButton {
        width: 116px;
        margin-left: 24px;
      }

    }
  }
}