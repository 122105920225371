@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  max-height: calc(100vh - 64px - 68px);
  overflow: auto;

  @include flex(stretch);
  @include for-phone-only{
    max-height: 100%;
    @include flex(null, null, column);
  }
}
