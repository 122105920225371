@import "src/styles/mixins";
@import "src/styles/props";


// line divider
.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 15px 0;
}
