@import "../../../styles/mixins";
@import "../../../styles/props";

// main container
.container {
  @include flex(center, space-between);
  @include rect(100%, 64px);
  // @include padding-left(17%);
  background: url("https://www.castrol.com/content/dam/castrol/business-sites-new/en/global/corporate/images/cgi-header-image/nav_image_2340x1050_reverted_version.jpg");
  background-size: cover;
  @include for-phone-only {
    background: url("https://www.castrol.com/content/dam/castrol/business-sites-new/en/global/corporate/images/cgi-header-image/nav_image_2340x1050_reverted_version.jpg") no-repeat 5% 0;
    background-size: cover;
    // @include padding-left(18%);
  }

  .link {
    cursor: pointer;
  }

  img {
    @include rect(162px, 64px);
    display: block;
    border-bottom: 1px solid beige;
  }

}

.notLoginContainer {
  @include rect(100%, 48px);
  padding: 0 24px;
  display: flex;
  flex-direction: row;

  @include for-phone-only {
    flex-direction: column;
    padding: 8px 15px;
    height: auto;
  }

  .loggedOutInfo {
    @include flex(center, center);
    @include CastrolSansConRegular;
    width: calc(100% - 150px);
    font-size: 16px;
    line-height: 22px;

    svg {
      margin-right: 10px;
    }

    @include for-phone-only {
      width: auto;

      justify-content: flex-start;
    }
  }

  .loginHere {
    @include flex(center, flex-start);
    @include CastrolSansConRegular;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: $pigment-green;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }

    @include for-phone-only {
      width: auto;
      justify-content: flex-start;
      padding-top: 8px;
      margin-left: 34px;
    }
  }
}
