@import "src/styles/mixins";
@import "src/styles/props";

.wrapper {
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.clickDetector {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  cursor: unset;
}

.icon {
  position: absolute;
  pointer-events: none;
  top: 50%;
  margin-top: calc(-7.11px / 2);
  right: 4px;

  & .opened {
    transform: rotate(180deg);
  }

  path {
    stroke: $pigment-green;
  }
}

.optionsContainer {
  position: fixed;
  background-color: $white;
  box-shadow: 0 10px 20px rgba($black, 0.2);
  overflow: auto;
  animation: fade-in 0.3s forwards;
  z-index: 200;
  margin-left: 5px;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .option {
    @include Arial;
    font-size: 14px;
    line-height: 16px;
    height: 45px;
    @include padding(14px 10px);
    @include border-bottom($gainsboro 1px solid);
    display: flex;
    align-items: center;

    .label {
      margin-left: 12px;
    }
  }
}

.input {
  @include Arial;
  font-size: 14px !important;
  line-height: 20px !important;;
  @include ellipsis;
  width: 100%;
  font-size: inherit;
  height: 100%;
  color: $black;
  cursor: pointer;

  &::placeholder {
    color: $dim-gray;
  }
}
