@import 'src/styles/mixins';
@import 'src/styles/props';

.formSelectField {
  height: 50px;
}

.optionBoxWrap {
  margin-top: 10px;
}

.optionBoxContainer {
  @include flex(center, flex-start, row);
  margin: -4px;
}

.optionBox {
  background-color: $danube;
  color: $white;
  font-size: 14px;
  line-height: 22px;
  padding: 0 4px;
  text-transform: uppercase;
  margin: 4px;

  svg {
    @include clickable();
    margin-left: 4px;
  }

  &.tagClickable {
    @include clickable();
  }

  &.tagInActive {
    background-color: $dim-gray;
  }

  &.tagDone {
    background-color: $pigment-green;
  }
}
