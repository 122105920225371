@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include rect(100%, 320px);
  position: relative;

  &.hasPromotions {
    height: 703px;

    .announcements {
      position: absolute;
      bottom: 120px;
      z-index: 2;
    }
  }

  @include for-phone-only {
    &.hasAnnouncements {
      margin-bottom: 60px;

      .announcements {
        top: 100%;
        bottom: auto;
      }
    }
  }
}


// loading
.loading {
  height: 100%;
}
