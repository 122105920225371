@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  @include flex(center, null, null, nowrap);
}

.checkboxLabel {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $black;
  margin-left: 12px;
  margin-right: 10px;
  text-transform: initial;
}
