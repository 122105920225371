@import "src/styles/mixins";
@import "src/styles/props";

// input container
.container {
  @include border-bottom(2px solid $pigment-green);
  @include padding-bottom(10px);
  display: block;
  position: relative;
}

// input
.input {
  @include CastrolSansConNormal;
  @include padding-right(36px);
  width: 100%;
  font-size: 32px;
  line-height: 40px;
  caret-color: $pigment-green;
  @include for-phone-only {
    font-size: 28px;
    line-height: 36px;
  }

  &::placeholder {
    color: $black;
    @include for-phone-only {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

// search icon
.search {
  @include box(24px);
  position: absolute;
  right: 0;
  bottom: 25px;
  @include for-phone-only {
    top: 12px;
    bottom: 0px;
  }
}
