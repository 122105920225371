@import "src/styles/mixins";
@import "src/styles/props";

// button
.button {
  height: auto;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
}
