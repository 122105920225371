@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include padding(112px 200px 100px);
  @include flex(stretch, null, column, nowrap);
  max-height: calc(100vh - 64px - 68px);
  overflow: auto;
  @include for-tabs-l-only {
    @include padding(112px 100px 100px);
  }
  @include for-phone-only {
    @include padding(75px 15px 15px 15px);
    max-height: calc(100vh - 30px);
  }
}

// browser container
.browserContainer {
  @include flex(stretch, null, column, nowrap);
  max-height: 20%;
  overflow: auto;
  margin-top: 25px;
  @include for-phone-only {
    @include padding(0);
    margin-top: 10px;
  }
}

// info message
.infoMessage {
  @include Arial;
  @include flex(center, flex-end);
  position: absolute;
  top: 0px;
  right: 60px;
  font-size: 16px;
  line-height: 19px;
  color: $dim-gray;
  background: $white;
  z-index: 20;
  width: calc(100% - 60px);
  height: 58px;

  > div {
    flex: 1;
    text-align: right;
  }

  @include for-phone-only {
    font-size: 14px;
    line-height: 16px;
    margin-left: 15px;
    width: calc(100% - 15px);
    right: 0px;
    @include padding-right(50px);
  }

  a {
    @include Arial;
    color: $pigment-green;
  }
}
