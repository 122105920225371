@import "src/styles/mixins";
@import "src/styles/props";

.infoCard {
  background-color: $pigment-green-alpha-10;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.iconContainer {
  @include flex(flex-start, center);
  margin-right: 10px;
}

.heading {
  color: $pigment-green;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  @include CastrolSansConMedium;
  text-transform: uppercase;

  &.noBold {
    @include CastrolSansConRegular;
  }

  b {
    color: $red;
  }

}

.body {
  color: $pigment-green;
  font-size: 14px;
  line-height: 20px;
  @include Arial;

  p {
    font-size: 14px;
    line-height: 20px;
    @include Arial;
  }

  a {
    @include Arial;
    color: $red;
    cursor: pointer;
  }
}
