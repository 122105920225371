@import "src/styles/mixins";
@import "src/styles/props";

// header for container
.container {
  h3, h4 {
    text-transform: uppercase;
  }
  h3{
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

// list container
.listContainer {
  @include flex(space-between);
  margin-top: 16px;
  @include for-phone-only {
    flex-direction: column;
    margin-top: 20px;
  }

  .item {
    @include item-flex(1, 1, 0);
    background-color: $white-smoke;
    @include padding(20px);
    @include flex(null, null, column);
    font-size: 16px;
    line-height: 19px;
  }
}

// item gap
.item + .item {
  margin-left: 40px;
  @include for-phone-only {
    margin-left: 0px;
    margin-top: 12px;
  }
}

// cargo info conatiner
.cargoContainer{
  margin-top: 20px;
  @include flex(center, space-between, row);
}

// notes
.notes{
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
}

// description
.description {
  @include Arial;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
}
