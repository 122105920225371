@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(54px 71px 54px 72px);
  width: 460px;
  @include for-phone-only {
    @include padding(20px);
    width: 100%;
  }
}
