@import "src/styles/mixins";
@import "src/styles/props";

.container{
  display: inline-flex;
  cursor: pointer;

  .switchBody {
    @include rect(36px, 20px);
    border-radius: 10px;
    background-color: $gainsboro;
    transition: background-color 0.3s;

    &.checked {
      background-color: $pigment-green;

      .innerCircle {
        left: 19px;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: unset;
    }

    .innerCircle {
      @include box(14px);
      border-radius: 100%;
      background-color: $white;
      position: relative;
      top: 3px;
      left: 3px;
      transition: left 0.3s;
    }
  }

  .label {
    @include Arial;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 13px;
    color: $dim-gray;
  }
}
