@import 'src/styles/mixins';
@import 'src/styles/props';

.tableRow {
  grid-template-columns: 126fr 130fr 65fr 95fr 125fr 95fr 116fr 85fr 40fr !important;
}

.para {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 20px;
  width: 100%;
  @include border-bottom(1px solid $gainsboro);
  @include for-phone-only {
    display: none;
  }
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
  margin-bottom: 30px;
  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.loading {
  height: 100px;
}

.selectDropdown {
  padding: 0px 0px 0px 10px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;

  & > input {
    padding: 0px 0px 0px 10px;
  }
}

.input {
  @include border(1px solid $gainsboro);
  @include padding(0 20px);
  @include CastrolSansConNormal;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
}

.visibleDataCell {
  padding-right: 8px;
}
