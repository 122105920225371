@import "src/styles/mixins";
@import "src/styles/props";

.formSelectField {
  height: 40px;
}

.filterContainer {
  width: 300px;
  @include for-phone-only {
    width: 100vw;
  }
}

.filterGroup {
  margin-top: 24px;
}

.pageHeader {
  margin-bottom: 28px;
  @include flex(center);
  text-transform: uppercase;
  .title {
    @include CastrolSansConRegular;
    display: inline-block;
    font-size: 36px;
    line-height: 43px;
    flex: 1;

    @include for-phone-only {
      font-size: 30px;
    }
  }

  .link {
    @include CastrolSansConRegular;
    display: inline-block;
    font-size: 16px;
    line-height: 19px;
    color: $pigment-green;
    svg {
      height: 24px;
      width: 24px;
      margin-bottom: -4px;
      margin-right: 8px;
    }

    @include for-phone-only {
      display: block;
    }
  }

  .addButton {
    @include for-phone-only {
      margin-top: 20px;
    }
  }
}

.filterOpen {
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: block;
    }
  }
  & > div:last-child {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

.filterClose {
  & > div:last-child {
    display: block;
  }
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}
.lastFilter {
  @include for-phone-only {
    padding-bottom: 20px;
  }
}
