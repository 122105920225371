@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 33px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @include for-phone-only{
    padding: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only{
    padding: 0;
    background-color: unset;
  }
}

.formRow {
  width: 371px;
}

.filterContainer {
  margin-top: 20px;
  border-top: 1px solid $gainsboro;
  display: flex;
  justify-content: space-between;

  .export {
    margin-top: 20px;
    display: flex;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .filterButton {
    text-transform: uppercase;
    color: $salem;
    margin-left: 16px;
    margin-top: 2px;
    cursor: pointer;
    text-decoration: none;
  }
}

.loadingOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loadingOverlayDim {
  opacity: 0.2;
  pointer-events: none;
}

.multipleSelect {
  width: 371px;

  svg {
    right: 15px;
  }
}

.multipleInput {
  border: 1px solid $gainsboro;
  padding-left: 10px;
  width: 361px;
  @include CastrolSansConNormal;
}

.selectOptions {
  width: 371px;
}