@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex();

  @include for-phone-only {
    display: block;
  }
  & > a {
    color: $pigment-green;
  }
}

.span {
  font-size: 16px;
  line-height: 22px;
  color: $dim-gray;
  @include Arial;
  & > a {
    color: $pigment-green;
  }
}
