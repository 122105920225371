@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include padding-left(8px);
  position: fixed;
  z-index: 100;
}

// inner container
.innerContainer {
  @include left-arrow(8px, $white);
  @include padding(18px 13px 20px 16px);
  background-color: $white;
  box-shadow: 0 10px 30px rgba($black, .16);
  position: relative;

  &.top {
    &::after {
      top: auto;
      bottom: 19px;
    }
  }

  &.bottom {
    &::after {
      top: 19px;
    }
  }
}

// header container
.header {
  @include border-bottom(1px solid $gainsboro);
  @include padding-bottom(11px);
}

// title
.title {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: $black;
}

// content container
.contentContainer {
  @include flex(stretch, null, column, nowrap);
  margin-top: 14.5px;
  max-height: 207px;
  overflow: auto;

  > * + * {
    margin-top: 14px;
  }
}
