@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 33px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @include for-phone-only{
    padding: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only{
    padding: 0;
    background-color: unset;
  }
}

.formRow {
  width: 270px;

  @include for-phone-only {
    width: 100%;
  }

  @include for-tabs-l-only {
    width: 100%;
  }
}

.inputRow {
  width: 100%;

  @include for-tabs-l-only {
    width: 100%;
  }
}

.calendarRow {
  width: 250px;
  padding-top: 20px;
  & > label{
    @include CastrolSansConNormal;
  }

  @include for-phone-only {
    width: 93% !important;
  }
}


.row {
  display: flex;
  gap: 1%;
  flex-wrap: wrap;

  @include for-phone-only {
    flex-direction: column;
  }
}

.formFieldsContainer {
  margin-top: 20px;
}

.filterContainer {
  margin-top: 20px;
  border-top: 1px solid $gainsboro;
  display: flex;
  justify-content: space-between;

  .export {
    margin-top: 20px;
    display: flex;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .filterButton {
    text-transform: uppercase;
    color: $white;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;

    @include for-phone-only{
      width: unset;
    }
  }

  .clearFilter {
    margin-top: 30px;
    display: flex;
    cursor: pointer;

    @include for-phone-only{
      margin-top: 36px;
    }

    .cancelIcon {
      margin-right: 8px;
    }

    .desktop {
      display: block;
      text-decoration: underline;
      color: $dim-gray;
      text-transform: uppercase;

      @include for-phone-only{
        display: none;
      }
    }

    .mobile {
      display: none;
      text-decoration: underline;
      color: $dim-gray;
      text-transform: uppercase;

      @include for-phone-only{
        display: block;
      }
    }
  }
}

.loadingOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loadingOverlayDim {
  opacity: 0.2;
  pointer-events: none;
}

.multipleSelect {
  position: relative;
  width: 270px;
  display: flex;
  flex-direction: row;

  svg {
    right: 15px;
  }

  @include for-phone-only {
    width: 100%;
  }

  @include for-tabs-l-only {
    width: 100%;
  }
}

.multipleInput {
  border: 1px solid $gainsboro;
  padding-left: 10px;
  width: 361px;
  @include CastrolSansConNormal;

  @include for-phone-only {
    width: 100%;
  }

  @include for-tabs-l-only {
    width: 100%;
  }
}

.selectOptions {
  width: 270px;

  @include for-phone-only {
    width: 77vw;
  }

  @include for-tabs-l-only {
    width: 100%;
  }
}