@import "src/styles/mixins";
@import "src/styles/props";

// table container
.container {
  overflow: visible;
}

// table label
.tableLabelRow {
  @include flex(center, space-between);
  margin-bottom: 16px;

  .tableLabel {
    @include CastrolSansConNormal;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: $black;
    text-transform: uppercase;
  }

  .exportToExcel {
    @include flex(center);
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-right: 16px
    }
  }
}

// header
.tableHeader {
  @include border-bottom(1px solid $gainsboro);

  .tableRow {
    @include flex(stretch);

    .tableColumn {
      @include padding(12px);
      min-height: 40px;
    }

  }

  @include for-phone-only {
    display: none;
  }
}

// column
.tableColumn {
  @include CastrolSansConNormal;
  @include padding(12px);
  @include flex(center);
  position: relative;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: $dim-gray;

  &:not(.tableLabelColumn) {
    @include flex(center, flex-start);
  }

  &.oemName {
    @include flex(flex-start, center, column)
  }

  &.commentLabel {
    span {
      color: $pigment-green;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.columnWithIcon {
    svg {
      // cursor: pointer;
      margin-left: 8px
    }
  }

  &.settingColumn {
    justify-content: center;
    position: relative;
  }

  // for mobile ui
  &.leftColumn {
    width: 50%;
  }

  &.rightColumn {
    flex: 1 1 auto;
  }

  @include for-phone-only {
    padding: 0;
  }

}

// label column
.tableLabelColumn {
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  color: $black;
}

// link text
.link {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  color: $pigment-green;
  @include for-phone-only {
    width: 80% !important;
  }
}

// inactive label
.inactiveLabel {
  @include CastrolSansConNormal;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  background-color: $ruby;
  text-transform: uppercase;
  padding: 1px 8px;
}

// body
.tableBody {
  .tableRow {
    @include flex(stretch);
    @include border-bottom(1px solid $gainsboro);

    &.inactive {
      background-color: rgba($ruby, 0.03);
    }

    &.expanded {
      .mobileTableRow {
        display: flex;

        &.firstRow {
          .dropdownArrow {
            transform: rotate(180deg);
          }

          .divider {
            display: block;
          }
        }
      }

      .mobileActionButton {
        display: flex;
      }
    }

    &.collapsed {
      .mobileTableRow.firstRow {
        .divider {
          display: none;
        }
      }

      .mobileTableRow:not(.firstRow) {
        display: none;
      }


      .mobileActionButton {
        display: none;
      }
    }

    @include for-phone-only {
      flex-direction: column;
      flex-wrap: nowrap;

      padding: 0 20px;

      &.inactive,
      &.expanded {
        @include border-left(1px solid $gainsboro);
        @include border-right(1px solid $gainsboro);

        &:first-child {
          @include border-top(1px solid $gainsboro);
        }
      }
    }
  }

  &.mobile{
    display: none;
    @include for-phone-only {
      display: block;
    }
  }

  &.desktop{
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

// sortable column
.sortableColumn {
  @include flex(center);
  cursor: pointer;
}

// sort icon
.sortIcon {
  margin-left: 4px;

  &.asc {
    transform: rotate(180deg);
  }
}

.mobileTableRow {
  @include flex(center, flex-start, row, nowrap);
  @include border-bottom(1px solid $gainsboro);
  padding: 12px 0;

  &.firstRow {
    border-bottom: none;
    position: relative;

    .rightColumn {
      width: calc(50% - 12px);
    }

    .divider {
      @include rect(calc(100% + 40px), 1px);
      background-color: $gainsboro;
      position: absolute;
      bottom: 0px;
      left: -20px;
    }
  }
}

.mobileActionButton {
  height: 40px;
  justify-content: flex-start;
  padding-left: 16px;
  font-weight: 400;
  margin-top: 12px;
  svg {
    margin-right: 16px;
  }

  &.lastButton {
    margin-bottom: 12px;
  }
}

.tooltip {
  max-width: 95vw;
}