@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(center);
  @include for-phone-only{
    width: 100%;
  }
}

// link
.link {
  @include CastrolSansConNormal;
  font-size: 14px;
  color: $pigment-green;
  text-transform: uppercase;
  cursor: pointer;

  @include for-phone-only{
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

// arrow icon
.arrow {
  margin: 0 8px;

  path {
    stroke: rgba($black, .2);
  }
}

// current link
.current {
  color: $black;
  display: inline-block;
  cursor: unset;
}
