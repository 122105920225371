@import "src/styles/mixins";
@import "src/styles/props";

// card container
.container {
  @include padding(22px 22px 36px 20px);
  background-color: rgba($light-grey, 0.4);
  display: block;

  &:hover {
    text-decoration: none;
  }
}

// container hovered
.container:hover {
  background-color: $pigment-green;

  .title, .subTitle, .description {
    color: $white;
  }

  .title path {
    stroke: $white;
  }
}

// title
.title {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  flex-wrap: nowrap;
}

// card text
.text {
  @include CastrolSansConNormal;
  line-height: 17px;
  font-size: 14px;
  color: $dim-gray;
}

// sub title
.subTitle {
  margin-top: 2px;
  text-transform: uppercase;
}

// description
.description {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  @include Arial;
}
