@import "src/styles/mixins";
@import "src/styles/props";

// header and description
.container {
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }

  p {
    @include Arial;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
    @include for-phone-only {
      font-size: 14px;
      line-height: 20px;
    }
    color: $dim-gray;
  }
}

// select
.select {
  width: 440px;
  margin-top: 20px;
  height: 50px;
  @include for-phone-only {
    width: 100%;
    height: 40px;
  }
}
