@import "src/styles/mixins";
@import "src/styles/props";

// title container
.titleContainer {
  @include flex(center, space-between);
  cursor: pointer;
}

// for opened
.titleContainer.opened {
  .arrow {
    transform: rotate(180deg);
  }
}

// group title
.title {
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  @include for-phone-only{
    font-size: 18px;
    line-height: 22px;
  }
}

// arrow
.arrow {
  path {
    stroke: rgba($black, .2);
  }
}

// options container
.optionsContainer {
  margin-top: 10px;
  max-height: calc((17px * 10) + (10px * 9));
  overflow: auto;
}

// option item
.option {
  @include flex(center);
  @include Arial;
  font-size: 14px;
  line-height: 17px;
  color: $dim-gray;
  cursor: pointer;
  flex-wrap: nowrap;
}

// option gap
.option + .option {
  margin-top: 10px;
}

// checkbox style
.checkbox {
  margin-right: 12px;
}
