@import "src/styles/mixins";
@import "src/styles/props";

.column {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding: 10px 0px;
  border-bottom: 1px solid $gainsboro;

  &.actionColumn {
    display: block;
    border-bottom: none;
    padding-bottom: 0px;
  }

  &:first-child {
    padding-top: 0px;
  }

  .columnLabel {
    color: $dim-gray;
  }

  .columnValue {
    color: $dim-gray;
  }
}
