@import "src/styles/mixins";
@import "src/styles/props";

.navigationHeader {
  @include border-bottom(1px solid $gainsboro);
}

// filter group gap
.filterGroup + .filterGroup {
  margin-top: 22px;
}

.filterOpen {
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: block;
    }
  }
  & > div:last-child {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }

}

.filterClose {
  & > div:last-child {
    display: block;
  }
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

.actionBtnContainer {
  @include flex();
  margin-top: 90px;
  padding-top: 20px;
  border-top: 1px solid $gainsboro;
  font-size: 14px;
  line-height: 22px;

  .applyButton {
    width: 78px;
    height: 42px;
  }

  .clearButton {
    margin-left: 20px;
    width: 98px;
    height: 42px;
  }
}

.tableTitle {
  @include flex(flex-start, space-between);
  @include CastrolSansConNormal;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;

  .filterButton {
    display: none;

    @include for-phone-only {
      display: block;
    }
  }

}

.noItems {
  @include Arial;
  color: $dim-gray;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
}
