@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include box(64px);
  @include flex(center, center);
  cursor: pointer; 
  @include for-phone-only {
    @include flex(center, flex-start);
  }
  &:hover {
    background-color: lighten($pigment-green, 10%);
  }
}
