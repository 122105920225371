@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  padding: 54px;
  width: 40%;
  max-width: 300px;
  background-color: $pigment-green;
  color: $white;

  @include for-phone-only {
    @include padding(20px);
    width: 100%;
    max-width: unset;
  }

  // title
  .title {
    @include CastrolSansConMedium;
    color: $white;
    margin-top: 30px;
    font-size: 64px;
    line-height: 76px;
    text-transform: uppercase;

    @include for-phone-only{
      font-size: 40px;
      line-height: 48px;
      margin-top: 14px;
    }
  }

  .question {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 38px;

    @include for-phone-only{
      font-size: 26px;
      line-height: 36px;
      margin-top: 4px;
    }
  }


}
