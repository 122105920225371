@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(center);

  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    .label {
      color: lighten($pigment-green, 10%);
    }
  }

  &:active {
    .label {
      color: lighten($pigment-green, 15%);
    }
  }

  a{
    color: $pigment-green;
    &:hover{
      text-decoration: none;
      color: lighten($pigment-green, 10%);
    };
  }
}

// label
.label {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 16px;
  color: $pigment-green;
  text-transform: uppercase;
}
