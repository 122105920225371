@import 'src/styles/mixins';
@import 'src/styles/props';

// option item
.option {
  @include flex(center);
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  .optionValue {
    @include padding(13px 20px);
    @include CastrolSansConNormal;
    @include flex(center);
    flex: 1;
    @include ellipsis;
    .inactive {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      background-color: #c90d5c;
      text-transform: uppercase;
      padding: 1px 8px;
      margin-left: 6px;

    }
  }

  &:hover {
    background-color: darken($white, 10%);
  }

  &.selected {
    color: $pigment-green;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.inactive {
    background-color: $red-alpha-06;
  }
}
