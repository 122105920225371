@import "src/styles/mixins";
@import "src/styles/props";

// tab header container
.container {
  @include flex(flex-end);
  @include rect(100%, 40px);
  position: relative;

  @include for-phone-only{
    flex-wrap: nowrap;
    overflow: auto;
  }
}

// bottom border
.bottomBorder {
  @include rect(100%, 1px);
  @include bottom-left;
  background-color: $gainsboro;
  position: absolute;
}

// tab item
.tabItem {
  @include CastrolSansConNormal;
  font-size: 20px;
  color: $dim-gray;
  height: 40px;
  position: relative;
  cursor: pointer;
  z-index: 10;
  text-transform: uppercase;
  @include for-phone-only{
    font-size: 16px;
    line-height: 19px;
  }

  &.selected {
    color: $ruby;
  }

  .bottomBorder {
    @include rect(100%, 4px);
    background-color: $ruby;
  }

  &:hover {
    text-decoration: none;
  }
}

// tab item gap
.tabItem + .tabItem {
  margin-left: 30px;
  @include for-phone-only{
    margin-left: 20px;
  }
}
