@import "src/styles/mixins";
@import "src/styles/props";

.container {
  position: relative;
  overflow: auto;

  @include for-phone-only {
    overflow: visible;
  }
}

// table row
.tableRow {
  @include flex();
}

.tableColumn {
  @include CastrolSansConNormal;
  display: flex;
  align-items: center;
}

.tableHeader {
  background-color: $white;

  @include for-phone-only {
    display: none;
  }

  .tableRow {
    @include border-top(1px solid $gainsboro);
    @include border-bottom(1px solid $gainsboro);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.7fr 0.5fr 0.7fr 0.7fr 1fr 1fr 0.4fr;
  }

  .tableColumn {
    @include border-right(1px solid $gainsboro);
    @include CastrolSansConNormal;
    color: $pigment-green;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0 4px;
    min-height: 40px;

    &:first-child {
      @include border-left(1px solid $gainsboro);
    }
  }
}

.tableBody {
  @include Arial;
  // body row
  .tableRow {
    @include border-bottom(1px solid $gainsboro);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.7fr 0.5fr 0.7fr 0.7fr 1fr 1fr 0.4fr;

    .tableColumn {
      &:nth-child(2) {
        @include border-left(1px solid $gainsboro);

        @include for-phone-only {
          border-left: none;
        }
      }
    }

    @include for-phone-only {
      display: inline-block;
      width: 100%;
      border-bottom: none;

      &.expanded {
        .tableColumn {
          display: flex;
        }

        .dropdownArrow {
          transform: rotate(180deg);
        }
      }

      &.collapsed {
        .tableColumn {
          display: none;
        }
      }
    }

  }

  .headerColumn {
    display: none;
    position: relative;

    @include for-phone-only {
      @include flex(center, space-between, row);
      height: 40px;
    }

    .divider {
      @include rect(calc(100% + 32px), 1px);
      background-color: $gainsboro;
      position: absolute;
      left: -16px;
      bottom: 0;
    }
  }

  // body column
  .tableColumn {
    @include Arial;
    @include border-right(1px solid $gainsboro);
    min-height: 40px;
    color: $dim-gray;

    @include for-phone-only {
      border-right: none;
      @include border-bottom(1px solid $gainsboro);

      &:last-child {
        border-bottom: none;
        margin-bottom: 16px;
      }
    }

    .errorText {
      @include Arial;
      color: $red;
    }

    .columnLabel {
      display: none;
      @include for-phone-only {
        display: block;
        width: 40%;
      }
    }
  }
}

.requiredIndicator {
  color: $red;
  margin-left: 4px;
}

.input {
  border: none;

  & > input {
    @include padding(0 4px);
    font-weight: 400;
  }

  &.error {
    border: 1px solid $ruby;
  }
}

.inputContainer {
  @include for-phone-only {
    width: 60%;
  }
}

.selectDropdown {
  padding: 0px;
  height: 42px;
  border: none;
  width: 100%;

  &.multiSelect {
    height: 40px;
  }

  @include for-phone-only {
    width: 60%;
  }

  & > svg {
    right: 8px
  }

  &.error {
    border: 1px solid $ruby;
  }
}

.selectInput {
  padding: 4px !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 400 !important;
  width: calc(100% - 14px) !important;
}

.selectOption {
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400 !important;
}