@import "src/styles/mixins";
@import "src/styles/props";

// table
.container {
  width: 100%;
  border-collapse: collapse;

  colgroup {
    col:nth-child(1) {
      width: 20%;
    }

    col:nth-child(2) {
      width: 15%;

    }

    col:nth-child(3) {
      width: 15%;
    }

    @for $i from 4 through 8 {
      col:nth-child(#{$i}) {
        width: 10%;
      }
    }
  }

  td, th {
    @include border(1px solid $gainsboro);
    @include padding(10px);
    @include CastrolSansConNormal;
  }

  th {
    font-weight: 400;
    background-color: $white-smoke;
  }

  td {
    a {
      color: $pigment-green;
    }
  }
}

.center {
  text-align: center;
}