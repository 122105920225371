@import 'src/styles/mixins';
@import 'src/styles/props';

.productCard {
  width: 100%;
  @include border(1px solid $gainsboro);
  @include padding(16px);
  margin-bottom: 4px;
  position: relative;
}

.container {
  @include padding-right(14px);
}

.attributes {
  .attribute {
    margin-bottom: 20px;
  }
}

.columnName {
  font-size: 12px;
  line-height: 15px;
  @include CastrolSansConNormal;
  color: $pigment-green;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.requiredColumn:after {
  position: absolute;
  content: " *";
  color: $red;
}

.columnValue {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
}

.fullscreen {
  @include clickable();
}

.errorText {
  color: $red;
  @include Arial;
}

.input {
  @include border(1px solid $gainsboro);
  @include padding(0 20px);
  @include CastrolSansConNormal;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
}

.textarea {
  @include border(1px solid $gainsboro);
  @include padding(13px 20px);
  @include CastrolSansConNormal;
  width: 100%;
  resize: vertical;
  background-color: $white;
}

.moreLessLink {
  @include Arial;
  color: $pigment-green;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}

.fullScreenBackdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @include flex(stretch, center, column, nowrap);
  background-color: rgba($color: $black, $alpha: 0.2);
}

.modal {
  margin: 70px 16px;
  flex: 1;
  overflow: hidden;
  background-color: $background-gray;
  @include flex(stretch, flex-start, column, nowrap);
}

.modalHeader {
  @include flex(center, space-between, row, nowrap);
  height: 59px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 2px $gainsboro;
}

.doneButton {
  @include clickable();
  font-size: 16px;
  line-height: 22px;
  color: $pigment-green;
  text-decoration: underline;
}

.closeButton {
  @include clickable();
}

.modalContent {
  flex: 1;
  overflow: auto;
  padding: 20px;
}

.topRightContainer {
  position: absolute;
  top: 16px;
  right: 18px;
  > *:not(:first-child) {
    margin-left: 24px;
  }
}

.borderTop {
  padding-top: 20px;
  border-top: solid 1px $gainsboro;
}
