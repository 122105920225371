@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;
  min-height: 376px;
  margin-top: 20px;

  @include for-phone-only {
    min-height: 0px;
  }
}

.formWrapper {
  margin: 24px 20px;
  @include border(1px solid $gainsboro);
  flex: 1;

  @include for-phone-only {
    margin: 0px;
  }

  .header {
    padding: 20px;
    @include border-bottom(1px solid $gainsboro);

    .titleInfo {
      @include Arial;
      font-size: 14px;
      color: $dim-gray;
      margin-top: 8px;
    }
  }
}

.labelWrapper {
  @include flex();

  .fieldLabelWrapper {
    margin-right: 8px;
    .fieldLabel {
      color: $pigment-green;
      text-transform: uppercase;
    }

    .requiredIndicator {
      color: $red;
    }
  }
}

.countriesTree {
  display: block;
  padding: 20px;
}

.actionRow {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only {
    width: 120px;
  }
}

.nextButton {
  width: 95px;

  @include for-phone-only {
    width: 116px;
  }
}
