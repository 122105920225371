@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  position: relative;
}

.innerContainer {
  @include flex(center);
  cursor: pointer;
  @include for-phone-only {
    width: 100%;
    @include flex(center, space-between, row);
  }
}

// label
.label {
  @include CastrolSansConNormal;
  color: $white;
  font-size: 16px;
}

// dropdown arrow
.arrow {
  margin-left: 10px;
}

// click detector
.clickDetector {
  @include fullscreen;
  position: fixed;
  z-index: 100;
}

// option container
.optionContainer {
  position: absolute;
  width: 200px;
  max-height: 225px;
  overflow: auto;
  top: 100%;
  right: 0;
  background-color: $white;
  box-shadow: 0 10px 20px rgba($black, .2);
  z-index: 200;
}

.optionContainerOffsetted{
  top: calc(100% + 50px);
}

.inputWrapper{
  padding: 10px;
  background-color: $white;
  @include for-phone-only {
    width: 100%;
  }
  input{
    padding: 0 8px;
    width: 100%;
    @include for-phone-only {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
