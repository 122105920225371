@import "src/styles/mixins";
@import "src/styles/props";

.container {
  h1 {
    font-size: 36px;
    line-height: 44px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
    @include for-phone-only{
      font-size: 30px;
      line-height: 36px;
    }
  }
}

// search description message
.searchDescription {
  @include CastrolSansConNormal;
  font-size: 20px;
  line-height: 28px;
  margin-top: 16px;
  color: $dim-gray;
  @include for-phone-only{
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }

  b {
    color: $ruby;
  }
}
