@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(center, space-between);

  h2 {
    text-transform: uppercase;
  }
}

// right group for header
.rightGroup {
  @include flex(center);
}

// header button
.button {
  @include CastrolSansConNormal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

// header button gap
.button + .button {
  margin-left: 23px;
}

// header button icon
.icon {
  margin-right: 16px;
}
