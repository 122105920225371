@import "src/styles/mixins";
@import "src/styles/props";

.noResult {
  width: 100%;
  height: 143px;
  border: 1px solid $gainsboro;
  background-color: $white-smoke;
  @include padding(25px 20px);

  .noResultTitle {
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
  }

  .noResultMessage {
    @include Arial;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: $dim-gray;
  }

  @include for-phone-only {
    height: 230px;
  }
}
