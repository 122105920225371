@import "src/styles/mixins";
@import "src/styles/props";

.oemPageContainer {
  @include item-flex(1, 1, 0);
  @include for-phone-only{
    @include padding(20px 15px);
    width: 100%;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 33px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @include for-phone-only{
    padding: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only{
    padding: 0;
    background-color: unset;
  }
}

.oemDetailContainer {
  @include flex(flex-start, center, column);
  width: 100%;
  margin-bottom: 24px;

  @include for-phone-only{
    margin-bottom: 0;
  }
}

.oemNameAddressContainer {
  @include padding(24px 20px);
  width: 100%;
  background-color: $white;

  @include for-phone-only{
    padding: 0;
  }
}

.nameTitle {
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;

  @include for-phone-only{
    &.desktop {
      display: none;
    }
  }
}

.formField {
  margin-top: 20px;
}

.description {
  @include Arial;
  font-size: 14px;
  line-height: 20px;
  color: $dim-gray;
  margin-top: 13px;

  b {
    @include Arial;
    font-size: 14px;
    line-height: 20px;
    color: $dim-gray;
  }

  @include for-phone-only{
    font-size: 16px;

    b {
      font-size: 16px;
    }
  }
}

.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only{
    margin-top: 20px;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only{
    width: 135px;
  }
}

.updateButton {
  width: 117px;

  @include for-phone-only{
    width: 135px;
  }
}

.saveButton {
  width: 95px;

  @include for-phone-only{
    width: 135px;
  }
}

.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 20px 0;

  &.mobile{
    display: none;
    @include for-phone-only {
      display: block;
    }
  }

  &.desktop{
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

// loading
.loading {
  height: 100px;
}
