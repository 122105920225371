@import "src/styles/mixins";
@import "src/styles/props";

// header for container
.container {
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

// list container
.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 19px;

  .item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border-bottom: 1px solid $gainsboro;
    @include padding(10px 20px);
    @include for-phone-only {
      flex-direction: column;
      @include padding(10px 0);
    }
    .name {
      width: 30%;
      flex: 0 0 auto;
      font-size: 16px;
      line-height: 22px;
      color: $pigment-green;
      @include for-phone-only {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .value{
      font-size: 14px;
      line-height: 17px;
      color: #666666;
      text-transform: uppercase;
    }
  }
}

// item last
.item:last-child {
  border: none;
}
