@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  width: 100%;
  overflow: hidden;
}

.title {
  @include CastrolSansConBold;
  font-size: 24px;
  line-height: 29px;
  color: $black;
  text-transform: uppercase;
  margin-bottom: 24px;
  @include for-phone-only {
    display: none;
  }
}
