@import "src/styles/mixins";
@import "src/styles/props";

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only {
    padding: 0;
    background-color: unset;
  }
}

.formWrapper {
  @include flex(null, null, column);
  @include padding(24px 20px);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;

  @include for-phone-only {
    @include padding(14px 10px);
  }
}

.horizontalStepper {
  margin-bottom: 0px;
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.phoneOnly {
  display: none;
  @include for-phone-only {
    display: initial;
  }
}

.stepProgessContainer {
  margin-bottom: 20px;
}
