@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include for-phone-only {
    border: 1px solid $gainsboro;
  }
}

.formWrapper {
  @include flex(null, null, column);
  @include padding(24px 20px);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;
}

.subFormWrapper {
  b {
    @include Arial;
  }

  > div:first-child {
    @include for-phone-only {
      border: none;
    }
  }

  margin-top: 20px;

  @include for-phone-only {
    margin-top: 0px;
  }
}

.sectionHeader {
  font-size: 20px;
  text-transform: uppercase;
}

.richTextarea {
  width: 100%;
  height: 200px;
  margin-bottom: 50px;

  div,
  p,
  strong,
  b,
  em,
  ul,
  ol,
  li {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  u {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: $salem;
  }
}

.formDivider {
  border-top: 1px solid $gainsboro;
  margin: 0px 20px;
}

.mobileOnly {
  display: none;
  @include for-phone-only {
    display: block;
  }
}

.addLangsRow {
  @include for-phone-only {
    padding-top: 0px;
  }
}

.actionRow {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only {
    width: 120px;
  }
}

.nextButton {
  width: 95px;

  @include for-phone-only {
    width: 116px;
  }
}
