@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  @include flex(flex-start, flex-start, row, nowrap);
}

// checkbox style
.checkbox {
  margin-right: 12px;
}

.titleContainer {
  flex: 1;
}
