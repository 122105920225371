@import "src/styles/mixins";
@import "src/styles/props";

.rowWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;

  > * {
    flex: 1 1 auto;
  }

  .browseBtn {
    flex: 0 0 120px;
  }
}

.fileInput {
  margin-bottom: 0px;
}

.formRow {
  padding-top: 0px;
  min-width: 0;
}
