@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include flex();
  height: 100%;
  position: relative;
  margin-right: 20px;
  @include for-phone-only {
    margin-right: 0px;
  }

  .menuLabelWrapper {
    display: none;
  }
}

.menuIcon {
  display: none;
  visibility: hidden;
  @include for-phone-only {
    @include flex(center);
    visibility: visible;
    margin-right: 20px;
  }
}

.menuOptions {
  @include flex(center, null, row);
  @include for-phone-only {
    @include flex(center, null, column);
    display: none;
    visibility: hidden;
  }
  &.open {
    display: block;
    visibility: visible;
    top: 64px;
    right: 0;
    z-index: 500;
    width: 100vw;
    background-color: $pigment-green;
    position: absolute;

    .menuLabelWrapper {
      @include flex(center);
      @include padding(16px);
      background-color: $white;
      @include border-bottom(1px solid $gainsboro);

      span {
        color: $dim-gray;
        text-transform: uppercase;
        flex: 1;
      }
    }
    .dropdownContainer {
      padding: 15px 15px 0 15px;
    }

    .buttonContainer{
      padding: 0 15px 15px 15px;

      span{
        color: $white;
        margin-left: 15px;
        text-decoration: none;
        border: none;
        display: block;
      }
    }
  }
}

// dropdown container
.dropdownContainer {
  @include flex(center);
  @include padding(0 24px);
  height: 100%;
  @include for-phone-only {
    @include flex(flex-start, flex-start, column);
    @include padding(0);
  }

  & > div {
    @include for-phone-only {
      height: 50px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      @include flex(center, null, row);
      width: 100%;
    }
  }
}

// dropdown gap
.dropdown + .dropdown {
  margin-left: 24px;
  @include for-phone-only {
    margin-left: 0;
  }
}

.myAccount {
  color: $white;
  font-size: 16px;
  margin-left: 24px;
  cursor: pointer;
  text-transform: uppercase;

  @include for-phone-only {
    margin-left: 0;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background-color: $white;
  @include for-phone-only {
    display: none;
  }
}

// button container
.buttonContainer {
  @include flex();
  @include for-phone-only {
    @include flex(null, flex-start, column);
  }

  span{
    display: none;
  }

  & > div {
    @include for-phone-only {
      @include border-top(1px solid rgba($white, 0.2));
      width: 100%;
      height: 50px;
      border-left: none;
    }
  }

  & div:last-child {
    @include for-phone-only {
      @include border-bottom(1px solid rgba($white, 0.2));
    }
  }

  .loginIcon {
    width: 20px;
    height: 20px;
    transform: rotate(270deg);
  }
}
