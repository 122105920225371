@import "src/styles/mixins";
@import "src/styles/props";

// header container
.header {
  @include flex(center, space-between);

  h3 {
    text-transform: uppercase;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.viewMore {
  text-transform: uppercase;
}

// container for card links
.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-column-gap: 23px;
  grid-row-gap: 20px;
  margin-top: 21px;
  @include for-phone-only {
    grid-template-columns: auto;
    max-width: calc(100vw - 32px);
  }
}
