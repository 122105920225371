@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  position: relative;
  max-height: calc(100vh - 64px - 68px);
  overflow-y: auto;
  @include for-phone-only {
    max-height: calc(100vh - 30px);
    overflow: auto;
  }
}

.content {
  width: 54%;
  margin: 0 auto;
  padding: 50px 0;
  overflow-y: auto;
  @include for-phone-only {
    width: 80%;
    padding: 30px 0;
  }

  & p {
    @include Arial;
    font-size: 14px;
    line-height: 22px;
    color: $dim-gray;
    margin-bottom: 25px;
    word-break: break-word;
  }

  & ul {
    list-style-type: none;
    margin-bottom: 25px;
    letter-spacing: normal;
    padding-left: 20px;
  }
  
  & li {
    @include Arial;
    font-size: 14px;
    line-height: 22px;
    color: $dim-gray;
    &:before {
      @include CastrolSansConMedium;
      margin-right: 5px;
      content: "\2022";
      color: $pigment-green;
    }
  }
}

.section {
  margin-bottom: 20px;
}

.header {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 22px;
  color: $pigment-green;
  margin-bottom: 25px;
  padding-top: 25px;
}





.closeWrapper {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  right: 0;
}

// close icon
.close {
  @include box(20px);
  position: sticky;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}

.buttonContainer {
  position: sticky;
  bottom: 0;
  width: 53.846%;
  padding: 20px 0;
  background-color: $white-alpha-60;
  width: 100%;
}

.button {
  height: 40px;
  font-size: 14px;
  margin: 0 auto;
}

// accept button
.acceptButton {
  width: 121px;
}

.controls {
  @include flex(center, space-between);
}
