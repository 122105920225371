@import 'src/styles/mixins';
@import 'src/styles/props';

// container
.container {
  @include flex(center, null, null, nowrap);
}

// icon
.icon {
  margin-right: 16px;
}

// content container
.contentContainer {
  @include flex(stretch, null, column);
  flex: 1;
}

// label
.label {
  @include CastrolSansConNormal;
  font-size: 14px;
  line-height: 17px;
  color: $dim-gray;
  text-transform: uppercase;
}

// value
.value {
  @include CastrolSansConMedium;
  font-size: 16px;
  line-height: 19px;
  margin-top: 1px;
  text-transform: uppercase;
  word-break: break-word;
  width: 100%;
}
