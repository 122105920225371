@import "src/styles/mixins";
@import "src/styles/props";

.modalContainer{
  max-width: 1240px;

  @include for-phone-only{
    max-width: unset;
  }
}

// container
.container {
  @include flex(stretch);
  max-height: calc(100vh - 64px - 68px);

  @include for-phone-only{
    overflow: auto;
    max-height: unset;
    position: relative;
  }
}

// form container
.formContainer {
  @include item-flex(1, 1, 0);
}


// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &.desktop {
    display: block;
  }

  &.mobile {
    display: none;
  }

  @include for-phone-only{
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}
