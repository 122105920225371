@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null, column);
  background-color: $background-gray;
  margin-top: 20px;

  .productName {
    color: $salem;
    flex: 1 !important;
  }

  .settingsIcon {
    width: 20px;
    height: 20px;
  }

  .checkbox {
    width: 16px;
  }

  .desktopContainer {
    @include for-phone-only {
      display: none;
    }

    .header {
      @include flex(null, space-between);
      @include padding(20px 20px 0px 20px);
      text-transform: uppercase;
    }

    .productCategories {
      @include flex(null, center);
      @include border-bottom(1px solid $gainsboro);
      @include padding-bottom(20px);
      margin-top: 15px;

      span {
        text-transform: uppercase;
        color: $dim-gray;
        font-size: 14px;
        width: 100px;
        text-align: center;
      }
    }

    .productsWrapper {
      @include padding(20px);
      max-height: 600px;
      overflow: auto;
    }

    .productContainer {
      @include flex(center, space-between);
      @include padding(15px);
      background-color: $white;
      position: relative;

      &.notFromProduct {
        background-color: rgba($ruby, 0.07);
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .productCategoryCheckboxContainer {
        @include flex();
        width: 500px;

        .checkboxWrapper {
          @include flex(null, center);
          width: 100px;
        }
      }

      .settingsIconWrapper {
        @include flex(null, flex-end);
        flex: 1;
      }
    }
  }

  .phoneContainer {
    display: none;
    background-color: $light-grey;;
    @include padding(10px);

    @include for-phone-only {
      @include flex(null, null, column);
    }

    .itemWrapper {
      @include flex(null, null, column);
      @include padding(16px);
      background-color: $white;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .sectionHeader {
        @include flex(null, null, column);
        @include padding-bottom(20px);
        @include border-bottom(1px solid $gainsboro);

        .firstRow {
          @include flex(null, space-between);
          margin-bottom: 14px;
          text-transform: uppercase;
        }
      }

      .contentWrapper {
        @include flex(null, null, column);
        margin-top: 24px;

        .physicalOfferLabel {
          margin-bottom: 16px;
        }

        span {
          text-transform: uppercase;
        }

        .categoryWrapper {
          @include flex(null, space-between);

          span {
            font-size: 12px;
            color: $dim-gray;
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}