@import "src/styles/mixins";
@import "src/styles/props";

// tabel container
.tableContainer {
  margin-top: 40px;
  @include for-phone-only{
    margin-top: 32px;
  }
}

.showPagination {
  overflow: unset;
  max-height: unset;
}

.tableColumn:first-child {
  padding: 0 !important;
}

.actionsColumn {
  @include for-phone-only {
    display: none !important;
  }
}

.mobilePosition {
  @include for-phone-only {
    left: 8px !important;
    width: 96% !important;
  }
}