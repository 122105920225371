@import "src/styles/mixins";
@import "src/styles/props";

.header {
  border: 1px solid $gainsboro;
  background-color: $light-grey4;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  font-size: 16px;
  text-transform: uppercase;
}

.editIcon {
  height: 17px;
  width: 17px;
  margin-right: 16px;
}

.clickable {
  cursor: pointer;
}

.downArrow {
  transform: rotate(180deg);
}

.body {
  &.collapseBody {
    display: none;
  }

  @include for-phone-only {
    border: 1px solid $gainsboro;
  }
}
