@import "src/styles/mixins";
@import "src/styles/props";

.tableWrapper {
  overflow-x: auto;
  overflow-y: clip;
  .table {
    width: 100%;
    border-collapse: collapse;
    @include border(1px solid $gainsboro);

    thead {
      tr {
        .requiredColumn:after {
          position: absolute;
          content: "*";
          color: $red;
        }

        th {
          @include border(1px solid $gainsboro);
          @include padding(14px 10px);
          @include CastrolSansConNormal;
          font-size: 16px;
          line-height: 22px;
          color: $black;
          text-align: left;
        }
      }
    }

    tbody {
      * {
        font-family: "Arial" !important;
      }

      tr {
        td {
          height: 1px;
          @include Arial;
          @include border(1px solid $gainsboro);
        }
      }
    }
  }
}
