@import "src/styles/mixins";
@import "src/styles/props";

// nav header container
.container {
  @include flex(center, space-between);
  @include padding(0 33px);
  height: 65px;

  @include for-phone-only{
    height: 50px;
    @include padding(0 15px);
    @include border-bottom(1px solid $gainsboro);
  }

  &.bottomLine {
    @include border-bottom(1px solid $gainsboro);
  }
}

.searchInput{
  input{
    @include for-phone-only{
      display: none;
    }
  }

  svg{
    @include for-phone-only{
      fill: white;
      width: 24px;
      height: 24px;
    }
  }

  @include for-phone-only{
    position: absolute !important;
    top: 35px;
    right: 50px;
  }
}

// filter
.filter{
  display: none;
  @include for-phone-only{
    display: block;
    position: absolute;
    right: 0;
    margin-right: 20px;
  }
  svg{
    width: 16px;
    height: 16px;
  }
  &.filterOpen{
    @include for-phone-only{
      display: none;
    }
  }
}

// back link
.backLink{
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  @include CastrolSansConNormal;
  color: $pigment-green;
  cursor: pointer;
  &.filterOpen{
    @include for-phone-only{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  & svg{
    transform: rotate(90deg);
    margin-right: 5px;
  }
}

// breadcrumb
.breadcrumb{
  display: block;
  &.filterOpen{
    @include for-phone-only{
      display: none;
    }
  }
}