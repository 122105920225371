@import "src/styles/mixins";
@import "src/styles/props";

// textarea
.textarea {
  @include border(1px solid $gainsboro);
  @include padding(13px 20px);
  @include CastrolSansConNormal;
  width: 100%;

  &.changed:invalid {
    border-color: $ruby;
  }

  &.changed:invalid + .error {
    display: block;
  }
}

// error
.error {
  display: none;
}
