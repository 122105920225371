@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  height: 250px;
}

.textarea {
  height: 200px;

  div, p, strong, b, em, ul, ol, li {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  u {
    @include Arial;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: $salem;
  }
}
