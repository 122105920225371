@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(center, space-between, row, nowrap);
  margin-top: 20px;

  .perPagePart {
    @include flex(center, null, row);
    @include Arial;
    font-size: 14px;
    line-height: 17px;

    .perPageSelector {
      height: 30px;
      margin-right: 16px;

      @include for-phone-only {
        margin-right: 12px;
      }

      svg {
        right: 8px;

        path {
          stroke: $gainsboro;
        }
      }
    }

    .perPageInput {
      width: 60px;
      padding: 0 16px 0 12px;

      @include for-phone-only {
        width: 55px;
      }
    }

    span {
      color: #666666;

      &.desktop {
        display: block;

        @include for-phone-only {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @include for-phone-only {
          display: block;
        }
      }
    }
  }

  .pagesPart {
    @include flex(center, null, row, nowrap, false);
    @include Arial;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;

    .pageItem {
      @include flex(center, center);
      width: 30px;
      height: 30px;
      border: 1px solid $gainsboro;
      background-color: $white-smoke;
      font-size: 14px;
      line-height: 17px;
      color: $black;

      &.active {
        color: $white;
        border: 1px solid $pigment-green;
        background-color: $pigment-green;
      }
    }

    .pageItem + .pageItem {
      margin-left: 16px;

      @include for-phone-only {
        margin-left: 8px;
      }
    }
  }

  @include for-phone-only {
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
}
