@import "src/styles/mixins";
@import "src/styles/props";

// title
.title {
  @include CastrolSansConNormal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

// item container
.itemContainer {
  margin-top: 20px;
}

.container{
  @include for-phone-only {
    margin-top: 20px;
    width: 100%;
  }
}
