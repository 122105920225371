@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include border(1px solid $gainsboro);
  width: 100%;
  @include padding(20px);

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
  }
}

.divider {
  @include border-bottom(1px solid $gainsboro);
  width: 100%;
  margin-bottom: 20px;
}

.validationErrCount {
  font-size: 16px;
  line-height: 22px;
  @include CastrolSansConNormal;
  margin-bottom: 20px;

  b {
    color: $red;
  }
}

.mobileOnly {
  display: none;
  @include for-phone-only {
    display: initial;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.buttons {
  @include flex(center, space-between, row);
  width: 100%;
  margin-top: 30px;
}

.button {
  min-width: 91px;
  @include padding-left(34px);
  @include padding-right(34px);
  @include roboto-condensed;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.uniquePair {
  font-size: 16px;
  line-height: 22px;
  @include CastrolSansConNormal;
  margin-bottom: 20px;
  color: $red;
}

.backendError {
  font-size: 12px;
  line-height: 20px;
  @include CastrolSansConNormal;
  margin-bottom: 4px;
  color: $red;
}
