@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(44px 86px);
  overflow: auto;
  height: 90vh;
  max-height: calc(100vh - 64px - 68px);

  @include for-phone-only{
    max-height: unset;
    height: unset;
    padding: 0 20px 60px 20px;
  }
}

.inputField {
  input {
    font-size: 16px;
    line-height: 22px;
  }
}

.comments {
  @include Arial;
  height: 220px !important;
  font-size: 16px;
  line-height: 22px;
}

.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 20px 0;
}

// button container
.buttonContainer {
  margin-top: 20px;

  .submitButton {
    width: 121px;
  }
}

