$black: #000;
$white: #fff;
$white-alpha-60: rgba(255, 255, 255, 0.6);
$pigment-green: #009343;
$pigment-green-alpha-80: rgba(0, 147, 67, 0.8);
$pigment-green-alpha-10: rgba(0, 147, 67, 0.1);
$salem: #139343;
$progress-green: #40904c;
$danube: #6a8db6;
$gainsboro: #dddddd;
$gainsboro2: #e3e3e3;
$gainsboro3: #dfdfdf;
$dim-gray: #666666;
$dim-gray2: #707070;
$dim-gray3: #919a9f;
$dim-gray4: #e9e9e9;
$dim-gray5: #636363;
$nobel: #999999;
$ruby: #c90d5c;
$red: #f00023;
$red-alpha-06: rgba(240, 0, 35, 0.6);
$silver: #c6c6c6;
$light-grey: #d7d7d7;
$white-smoke: #f7f7f7;
$background-gray: #f7f8f9;
$dark-gray: #aaaaaa;
$light-black: #333333;
$orange: #fe4110;
$light-grey2: #f7f8f9;
$light-grey3: #fbfbfb;
$light-grey4: #d7d7d71a;
$light-pink: #c90d5c08;
$light-pink2: #c90d5c0d;
$cadetblue: #6a8db6;
$cadetblue-light: #6a8db60d;
$light-dark: #303030;
$light-green: #f8fcfa;
$error-red: #bb2b5c;
$castrol-red: #e9001b;
