@import "src/styles/mixins";
@import "src/styles/props";

.container {
  width: 100%;
  margin-bottom: 30px;
  @include flex(center, center, row);
  background-color: $background-gray;
  @include padding-top(23px);
  @include padding-bottom(23px);
  @include for-phone-only {
    display: none;
  }
}

.step {
  @include flex(center, center, row);
}

.numberCircle {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  background-color: $white;
  margin-right: 13px;
  @include flex(center, center, row);
}

.activeStepCircle {
  color: $white;
  background-color: $ruby;
}

.completedStepCircle {
  margin-right: 13px;
}

.label {
  font-size: 14px;
  line-height: 17px;
  @include CastrolSansConNormal;
  text-transform: uppercase;
  color: $black;
}

.completedLabel {
  color: $pigment-green;
}

.currentLabel {
  color: $ruby;
}

.seperationBar {
  margin-right: 10px;
  margin-left: 10px;
  height: 2px;
  background-color: $gainsboro;
  border-radius: 4px;
  width: 74px;
}

.seperationBarCompleted {
  background-color: $progress-green;
}
