@import "src/styles/mixins";
@import "src/styles/props";

.container {
  width: 100%;
  @include flex(center, space-between, row);
  margin-bottom: 10px;

  .fieldName {
    color: $pigment-green;
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    &:after {
      content: " *";
      color: $ruby;
    }
  }

  .questionMark {
    cursor: pointer;
  }
}
