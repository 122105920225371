@import "src/styles/mixins";
@import "src/styles/props";

// action  menu container
.container {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 14px;

  .settingIcon {
    cursor: pointer;
  }
}

// click detector
.clickDetector {
  @include fullscreen;
  position: fixed;
  z-index: 100;
}

// option container
.optionContainer {
  position: absolute;
  width: 250px;
  top: 0px;
  left: calc(-260px + 50%);
  background-color: $white;
  max-height: 200px;
  box-shadow: 0 10px 20px rgba($black, 0.2);
  z-index: 200;
}
