@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.dividerLine {
  margin-top: 20px;
  @include flex(center);
}

.line {
  @include border-bottom(1px solid $gainsboro);
  flex: 1;
}

.text {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
  line-height: 22px;
  color: $dim-gray;
}
