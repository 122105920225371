@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include item-flex(1, 1, 0);
  @include padding(60px 160px 80px 180px);
  min-width: 500px;
  max-width: 840px;


  @include for-phone-only {
    @include padding(32px 20px 120px 20px);
    min-width: unset;
    max-width: unset;
  }
}

.title {
  @include CastrolSansConRegular;
  font-size: 32px;
  line-height: 43px;
  text-transform: uppercase;
  margin-bottom: 29px;
  position: relative;

  @include for-phone-only {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .keyIcon,
  .checkCircleIcon {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    position: relative;
    top: 7px;

    @include for-phone-only {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
  }

}

.description {
  @include Arial;
  color: $dim-gray;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  @include for-phone-only {
    margin-bottom: 24px;
  }
}

.passwordRule {
  @include Arial;
  color: $dim-gray;
  font-size: 16px;
  line-height: 24px;
}

.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 20px 0 0;

  &.marginBoth {
    margin: 20px 0;
  }
}

.passwordInput {
  span {
    @include CastrolSansConRegular;
    font-size: 22px;
    line-height: 30px;

    @include for-phone-only {
      font-size: 16px;
      line-height: 21px;
    }
  }

}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;

  .button {
    &.changePwdBtn {
      width: 170px;
    }

    &.cancelButton {
      width: 129px;
      margin-left: 20px;
    }

    &.loginBtn {
      width: 94px;
    }
  }

  @include for-phone-only {
    width: 100%;
  }
}

.error {
  @include Arial;
  font-size: 16px;
  line-height: 22px;
}

.inputOK {
  border-color: $pigment-green;
}

.inputError{
  border-color: $ruby;
}