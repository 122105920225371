@import "src/styles/mixins";
@import "src/styles/props";

.deactivateEomModalContainer {
  max-width: 1100px;

  .container {
    @include padding(48px);

    @include for-phone-only {
      padding: 0;
    }

    h1 {
      @include CastrolSansConNormal;
      font-size: 36px;
      line-height: 48px;
      font-weight: 400;
      text-transform: uppercase;

      @include for-phone-only {
        font-size: 26px;
        line-height: 35px;
        padding: 27px 20px 0 20px;
      }
    }

    .modalContent {
      @include flex(null, null, column);
      margin-top: 40px;

      @include for-phone-only {
        margin-top: 32px;
      }

      .description {
        @include Arial;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $dim-gray;

        @include for-phone-only {
          padding: 0 20px;
        }
      }

      .infoLabel {
        @include CastrolSansConNormal;
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
        margin-top: 40px;
        margin-bottom: 21px;
        text-transform: uppercase;

        @include for-phone-only {
          display: none;
        }
      }

      .fieldsContainer {
        @include flex(flex-start, flex-start, row, wrap);
        background-color: rgba($light-grey, 0.1);

        @include for-phone-only {
          @include flex(flex-start, flex-start, column);
          margin-top: 12px;
          min-height: 450px;
        }

        .itemField {
          @include flex(center, flex-start, row, nowrap);
          padding: 30px 20px;
          width: calc(25% - 40px);

          @include for-phone-only {
            padding: 12px 20px;
            width: auto;
          }

          .fieldContent {
            @include flex(flex-start, flex-start, column);
            @include item-flex(1, 1, auto);
            margin-left: 16px;

            .fieldLabel {
              @include CastrolSansConNormal;
              font-size: 14px;
              line-height: 19px;
              font-weight: 400;
              text-transform: uppercase;
              color: $dim-gray;

              &.nameLabelClass {
                text-transform: unset;
              }
            }

            .fieldValue {
              @include CastrolSansConNormal;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;

              &.brandClass {
                @include CastrolSansConNormal;
                font-size: 12px;
                line-height: 16px;
                color: $white;
                background-color: $cadetblue;
                text-transform: uppercase;
                padding: 1px 8px;
              }

              &.documentClass {
                a {
                  color: $pigment-green;
                }
              }
            }
          }

          .castrolConfidentialIcon {
            position: relative;

            .lockIconContainer {
              @include flex(center, center);
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: $white;
              border-radius: 12px;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }

    .actionBtnContainer {
      @include flex(null, flex-start);
      margin-top: 32px;

      @include for-phone-only {
        @include flex(null, space-between);
        @include border-top(1px solid $gainsboro);
        margin-top: 0;
        padding: 20px 20px 40px 20px;
      }

      .confirmButton {
        width: 116px;
      }

      .cancelButton {
        width: 116px;
        margin-left: 24px;
      }

    }
  }
}