@import "src/styles/mixins";
@import "src/styles/props";

.container {
  min-height: calc(100vh - 64px - 60px - 122px - 100px);
  @include flex(null, center);
  padding-top: 100px;
  .top {
    @include flex(stretch, space-between);
    .content {
      @include flex(flex-start, flex-start, column);
      margin-left: 50px;
      & h2{
        @include CastrolSansConNormal;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 44px;
      }
      .header1{
        color: $red;
      }
      .message1{
        margin-top: 40px;
        @include Arial;
        font-size: 16px;
        line-height: 22px;
      }
      .message2{
        margin-top: 5px;
        @include Arial;
        font-size: 14px;
        line-height: 20px;
        & a {
          color: $pigment-green;
        }
      }

      @include for-tabs-only{
        margin-left: 10px;
        flex-grow: 1;
        margin-top: 20px;
      }
    }
    .icon {
      margin-top: 10px;
      height: 60px;
      width: 60px;
      @include for-tabs-only{
        margin-left: 10px;
      }
    }
    @include for-tabs-only{
      width: 100%;
      padding: 5px;
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
}
