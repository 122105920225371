@import "src/styles/mixins";
@import "src/styles/props";

.header {
  border: 1px solid $gainsboro;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  &.collapseHead {
    border: none;
    border-bottom: 1px solid $gainsboro;
    background-color: $white;
  }
}

.title {
  flex: 1;
  font-size: 16px;
}

.clickable {
  cursor: pointer;
}

.upArrow {
  transform: rotate(180deg);
}

.body {
  padding: 26px 20px;
  border: 1px solid $gainsboro;

  &.collapseBody {
    display: none;
  }
}
