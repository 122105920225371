@import "src/styles/mixins";
@import "src/styles/props";

.contentContainer {
  @include padding(32px 15% 76px 15%);
  background-color: $background-gray;

  @include for-phone-only {
    padding: 0;
    background-color: unset;
  }
}

.parentWidth {
  max-width: 100%;
}

.formWrapper {
  @include flex(null, null, column);
  @include padding(30px 20px);
  background-color: $white;
  max-width: 100%;
  overflow: hidden;

  @include for-phone-only {
    @include padding(20px 20px);
  }

  .formHeader {
    .message {
      font-size: 14px;
      color: $dim-gray;
      @include Arial;
      @include padding-bottom(8px);
    }
  }
}

.sectionHeader {
  font-size: 16px;
  text-transform: uppercase;
  @include border-top(1px solid $gainsboro);
  @include padding-top(20px);
  margin-top: 20px;

  &.topSectionHeader {
    margin-top: 0px;

    @include for-phone-only {
      margin-bottom: 16px;
    }
  }

  &.sectionBtn {
    display: flex;
    justify-content: space-between;
  }
}

.actionRow {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include for-phone-only {
    width: auto;
    margin: 20px;
  }
}

.cancelButton {
  width: 129px;

  @include for-phone-only {
    width: 135px;
  }
}

.saveButton {
  width: 95px;

  @include for-phone-only {
    width: 135px;
  }
}

.link {
  @include CastrolSansConRegular;
  display: inline-block;
  color: $pigment-green;
  cursor: pointer;
  svg {
    height: 14px;
    width: 14px;
    margin-right: 10px;
  }
}

.desktopOnly {
  display: initial;
  @include for-phone-only {
    display: none;
  }
}

.phoneOnly {
  display: none;
  @include for-phone-only {
    display: initial;
  }
}

.deleteBtn {
  @include CastrolSansConRegular;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: inline-block;
  margin-top: 60px;
  cursor: pointer;
  color: $pigment-green;
  text-transform: uppercase;

  @include for-phone-only {
    margin-top: 6px;
    align-self: flex-end;
    margin-right: 20px;
  }

  .deleteIconBtn {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 4px;

    path {
      fill: $pigment-green;
    }
  }
}
