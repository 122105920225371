@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, space-between);
  margin-top: 24px;

  button {
    @include padding(15px 28px);
  }

  .saveBtnWrapper {
    @include flex();

    .backBtn {
      @include border(1px solid $pigment-green);
      background-color: $white;
      margin-right: 24px;
    }
  }
}