@import "src/styles/mixins";
@import "src/styles/props";

.baseContainer {
  width: 100%;

  .oemDetailPageContainer {
    @include item-flex(1, 1, 0);
    @include padding(27px 20px 56px 20px);
    margin: 0 auto;
    // max-width: 1300px;

    @include for-phone-only{
      @include padding(20px 15px 20px 15px);
      width: 100%;
    }
  }

  .announcements {
    width: 100%;

    @include for-phone-only{
      margin-top: 20px;
    }
  }
}


.titleRow {
  @include flex(center, space-between, row, nowrap);
  @include CastrolSansConNormal;
  padding-right: 14px;

  .title {
    @include item-flex(1, 1, auto);
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 24px;

    @include for-phone-only{
      margin-bottom: 16px;
    }
  }

  .shareEmail,
  .exportToExcel {
    @include flex(center);
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;
    align-self: flex-end;

    @include for-phone-only{
      align-self: flex-start;
    }

    svg {
      margin-right: 16px
    }
  }

  .divider {
    @include rect(100%, 1px);
    background-color: $gainsboro;
    margin: 20px 0;
  }

  .mobile {
    display: none;
  }

  @include for-phone-only{
    margin-bottom: 0;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .mobile {
      display: block;
    }
  }
}

.actionRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;

  .backButton {
    width: 110px;

    @include for-phone-only{
      display: none;
    }
  }
}


// loading
.loading {
  height: 100px;
}
