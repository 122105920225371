@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: "CastrolSansConBold";
  src: local("CastrolSansCon W1G"),
    url("./fonts/CastrolSansCon-Medium-W1G-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CastrolSansConNormal";
  src: local("CastrolSansCon-Regular-W1G"),
    url("./fonts/CastrolSansCon-Regular-W1G-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  font-family: "CastrolSansConNormal";
}

button,
input,
textarea {
  appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h3,
h4,
h5,
h6 {
  font-family: "CastrolSansConNormal";
  font-weight: 500;
}

h1,
h2 {
  font-family: "CastrolSansConBold";
  font-weight: 500;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 22px;
  line-height: 32px;
}

@media (max-width: 599px) {
  body{
    min-width: auto;
  }
}
