@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include padding(42px 120px 40px);
  overflow: auto;
  max-height: calc(100vh - 64px - 68px);
  @include for-phone-only{
    max-height: 100vh;
    padding: 20px !important;
  }
}

// form field gap
.formField + .formField {
  margin-top: 22px;
}

// form controls
.formControl {
  font-size: 14px;
  height: 40px;
}

// textarea
.textarea {
  height: 120px;
  resize: none;
}

// button container
.buttonContainer {
  @include flex();
  margin-top: 20px;
}

// button
.button {
  height: 50px;
}

// submit button
.submitButton {
  width: 121px;
}

// cancel button
.cancelButton {
  margin-left: 20px;
  text-transform: none;
  font-weight: 400;
}

// loading
.loading {
  width: auto;
  min-height: 0;
  height: auto;
}
