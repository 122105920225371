@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include CastrolSansConNormal;
  font-size: 14px;
  color: $ruby;
  margin-top: 10px;
}
