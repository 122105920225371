@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  position: relative;
  height: 45px;
}

.innerContainer {
  @include flex(center, stretch);
  @include padding-left(16px);
  @include padding-right(16px);
  cursor: pointer;
  height: 100%;
  @include for-phone-only {
    width: 100%;
    @include flex(center, space-between, row);
  }
}

.optionActive {
  background-color: $pigment-green;
}

// label
.label {
  @include flex(center, flex-start);
  @include CastrolSansConNormal;
  font-size: 16px;
  // line-height: 22px;
  margin-left: 8px;
  text-transform: uppercase;
}

.labelActive {
  color: $white;
}

