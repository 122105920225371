@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.tagLine {
  margin-top: -16px;
  margin-bottom: 23px;
  font-size: 16px;
  line-height: 22px;
  color: $ruby;
}

.panelsContainer {
  @include flex(null, flex-start, column, nowrap);
}

.panel {
  background-color: $white;
  padding: 20px;
  &:not(:first-child) {
    margin-top: 24px;
  }
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: $black;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.content {
  border-top: solid 1px $gainsboro;
}

.section {
  width: 100%;
  padding-top: 14px;
  &:not(:first-child) {
    border-top: solid 1px $gainsboro;
  }
  &:not(:last-child) {
    padding-bottom: 10px;
  }
}

.row {
  @include flex(center, stretch, null, nowrap);
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  overflow: hidden;
}

.rowLabel {
  color: $dim-gray;
}

.rowValue {
  flex: 1;
  margin-left: 120px;
  text-align: right;
  color: $black;
  @include ellipsis();
  &.linkValue {
    color: $pigment-green;
  }
}

@include for-phone-only {
  .tagLine {
    display: none;
  }

  .row {
    @include flex(flex-start, flex-start, column, nowrap);
  }

  .rowValue {
    width: 100%;
    text-align: left;
    margin-left: 0;
    margin-top: 9px;
  }
}
