@import "src/styles/mixins";
@import "src/styles/props";

// main container
.container {
  @include padding(64px 40px);
  @include for-phone-only {
    @include padding(20px);
  }
  h2 {
    @include CastrolSansConNormal;
    font-size: 36px;
    line-height: 44px;
    @include for-phone-only {
      font-size: 26px;
      line-height: 31px;
    }
  }
}

// loading container
.loadingContainer {
  @include rect(100%, 300px);
}

// table container
.tableContainer {
  margin-top: 21.5px;
}
