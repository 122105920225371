@import "src/styles/mixins";
@import "src/styles/props";

// filter group gap
.filterGroup + .filterGroup {
  margin-top: 22px;
}

.filterOpen {
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: block;
    }
  }
  & > div:last-child {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }

}

.filterClose {
  & > div:last-child {
    display: block;
  }
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

.noResult {
  @include Arial;
  margin-top: 40px;
}

.selectControl {
  height: 40px;

  svg {
    right: 10px;
  }
}

.selectInput {
  @include Arial;
  font-size: 14px;
  padding: 0 32px 0 10px;
}

.selectOption {
  @include Arial;
  font-size: 14px;
}
