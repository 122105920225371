@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include flex(null, null , column);
  @include border(1px solid $gainsboro);
  max-width: 1280px;
  background-color: $background-gray;
  margin-top: 30px;
  margin-bottom: 30px;

  .header {
    @include padding(20px);
    @include border-bottom(1px solid $gainsboro);

    h3 {
      font-size: 20px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .content {
    @include flex(null, null, column);
    .width10 {
      max-width: 10%;
      flex: 1;
    }

    .width20 {
      max-width: 20%;
      flex: 1;
    }

    .width40 {
      max-width: 40%;
      flex: 1;
    }

    .attributesContainer {
      @include flex(center, null, column);

      .attributes {
        @include flex(null, null, null, nowrap);
        color: $dim-gray;
        margin-top: 15px;
        width: 100%;

        div {
          max-width: 160px;
          flex: 1;
        }
      }
    }

    .contentHeader {
      @include flex(null, space-between, null, wrap, null);
      @include padding(20px 40px);
      @include border-bottom(1px solid $gainsboro);
      text-transform: uppercase;

      .attributes {
        font-size: 14px;
      }

      @include for-phone-only {
        display: none;
      }
    }

    .caseStudiesContainer {
      @include flex(null, null, column);
      @include padding(20px);

      .desktopContainer {
        @include for-phone-only {
          display: none;
        }
      }

      .mobileContainer {
        display: none;

        @include for-phone-only {
          @include flex(null, null, column);
        }

        .caseStudy {
          @include flex(flex-start, null, column);

          .row {
            @include flex(null, null, column);
            width: 100%;

            .label {
              margin-bottom: 11px;
              color: $black;
              text-transform: uppercase;
            }

            .value {
              margin-bottom: 23px;
            }
          }

          .flexSpaceBetween{
            @include flex(stretch, space-between, row);
          }

          .attributes {
            width: 100%;

            > div {
              @include flex();
              padding-left: 15px;

              .label {
                flex: 1;
                color: $dim-gray;
                text-transform: none;
              }
            }
          }
        }
      }

      .caseStudy {
        @include flex(center, space-between, null, wrap, null);
        @include padding(20px);
        background-color: $white;
        color: $dim-gray;
        overflow-wrap: break-word;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        .attributes {
          margin-top: 0px;
        }

        .documentDownloadLink {
          color: $pigment-green;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}