@import 'src/styles/props.scss';
@import 'src/styles/mixins.scss';

.container {
  @include flex();
  > *:not(:first-child) {
    margin-left: 36px;
  }
  > * {
    flex: 1;
  }

  @include for-phone-only {
    @include flex(stretch, flex-start, column, nowrap);
    > *:not(:first-child) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
