@import 'src/styles/mixins';
@import 'src/styles/props';

.container {
  .header {
    height: 94px;
    @include flex(null, center, column);
    @include padding(0px 24px);

    h3 {
      font-size: 24px;
      text-transform: uppercase;
      margin-top: 8px;
    }
  }
}
