// set flex container
@mixin flex(
  $align: null,
  $justify: null,
  $direction: null,
  $wrap: wrap,
  $childFlex: true
) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;

  @if ($childFlex) {
    > * {
      flex: 0 0 auto;
    }
  }
}

// set flex item properties
// for some case IE doesn't allow shorthand flex properties like `flex: 1 1 0`
// so this mixin can resolve cross browser issue
@mixin item-flex($grow: null, $shrink: null, $basis: null) {
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-basis: $basis;
}

// set overflow ellipsis
@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// set multiline ellipsis
@mixin multiline-ellipsis($lines) {
  @include ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

// set top/left/bottom/right positions
// top left
@mixin top-left {
  top: 0;
  left: 0;
}

// top right
@mixin top-right {
  top: 0;
  right: 0;
}

// bottom left
@mixin bottom-left {
  bottom: 0;
  left: 0;
}

// bottom right
@mixin bottom-right {
  bottom: 0;
  right: 0;
}

// set padding
// set all padding
@mixin padding($padding) {
  box-sizing: border-box;
  padding: $padding;
}

// set padding left
@mixin padding-left($padding) {
  box-sizing: border-box;
  padding-left: $padding;
}

// set padding right
@mixin padding-right($padding) {
  box-sizing: border-box;
  padding-right: $padding;
}

// set padding top
@mixin padding-top($padding) {
  box-sizing: border-box;
  padding-top: $padding;
}

// set padding bottom
@mixin padding-bottom($padding) {
  box-sizing: border-box;
  padding-bottom: $padding;
}

// set border
// set all border
@mixin border($border) {
  box-sizing: border-box;
  border: $border;
}

// set border left
@mixin border-left($border) {
  box-sizing: border-box;
  border-left: $border;
}

// set border right
@mixin border-right($border) {
  box-sizing: border-box;
  border-right: $border;
}

// set border top
@mixin border-top($border) {
  box-sizing: border-box;
  border-top: $border;
}

// set border bottom
@mixin border-bottom($border) {
  box-sizing: border-box;
  border-bottom: $border;
}

// create box
@mixin box($size) {
  width: $size;
  height: $size;
}

// create ball
@mixin ball($size) {
  @include box($size);
  border-radius: 50%;
}

// create rect
@mixin rect($width, $height) {
  width: $width;
  height: $height;
}

// set full screen
@mixin fullscreen {
  @include box(100%);
  @include top-left;
}

// custom attribute mixin
@mixin attribute-styles($attr, $value) {
  &[#{$attr}='#{$value}'] {
    @content;
  }
}

// set width between gaps
@mixin flex-grid($gap, $count) {
  width: calc((100% - (#{$gap} * #{$count - 1})) / #{$count});
  margin-right: $gap;

  &:nth-child(#{$count}n) {
    margin-right: 0;
  }
}

// $widths: it should be set of percent and sum of $widths should be 100
// $min-width:
//   this is min-width of total items
//   so if it is set, min-width of each item will be calculated
//   ex)
//     @include setMultipleWidths((40, 60), 600px);
//     -> item-1 { width: 40%, min-width: 240px } item-2 { width: 60%, min-width: 360px }
@mixin width-by-ratio($widths, $min-width: null) {
  $i: 1;

  @each $width in $widths {
    &:nth-child(#{$i}) {
      width: #{$width + '%'};

      @if ($min-width) {
        min-width: calc(#{$min-width / 100} * #{$width});
      }
    }

    $i: $i + 1;
  }
}

// arrows
// default
@mixin arrow-default {
  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    @content;
  }
}

// top
@mixin top-arrow($size, $color) {
  @include arrow-default {
    bottom: 100%;
    left: 50%;
    border: solid $size transparent;
    border-bottom-color: $color;
    margin-left: -$size;
  }
}

// left
@mixin left-arrow($size, $color) {
  @include arrow-default {
    right: 100%;
    top: 50%;
    border: solid $size transparent;
    border-right-color: $color;
    margin-top: -$size;
  }
}

// bottom
@mixin bottom-arrow($size, $color) {
  @include arrow-default {
    top: 100%;
    left: 50%;
    border: solid $size transparent;
    border-top-color: $color;
    margin-left: -$size;
  }
}

// right
@mixin right-arrow($size, $color) {
  @include arrow-default {
    left: 100%;
    top: 50%;
    border: solid $size transparent;
    border-left-color: $color;
    margin-top: -$size;
  }
}

// set element clickable
@mixin clickable($effect: false, $selectable: none) {
  cursor: pointer;
  user-select: $selectable;

  @if ($effect) {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
}

// position element to vertically center
@mixin vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

// position element to horizontally center
@mixin horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

// position element to center
@mixin center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// set style under the screen width
@mixin screen-under($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

@mixin CastrolSansConBold {
  font-family: 'CastrolSansConBold';
}

@mixin CastrolSansConMedium {
  font-family: 'CastrolSansConBold';
  font-weight: 500;
}

@mixin CastrolSansConRegular {
  font-family: 'CastrolSansConNormal';
}

@mixin CastrolSansConNormal {
  font-family: 'CastrolSansConNormal';
  font-weight: 500;
}

@mixin Arial {
  font-family: 'Arial';
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tabs-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-tabs-l-only {
  @media (max-width: 1024px) {
    @content;
  }
}
