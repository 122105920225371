@import "src/styles/mixins";
@import "src/styles/props";

// loading
.loading {
  height: 100px;
}

// filter group
.filterGroup + .filterGroup {
  margin-top: 22px;
}

// children container
.childrenContainer {
  margin-top: 32px;
  @include for-phone-only {
    margin-top: 20px;
  }
}

.filterOpen {
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: block;
    }
  }
  & > div:last-child {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
  
}

.filterClose {
  & > div:last-child {
    display: block;
  }
  & > div:first-of-type {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}