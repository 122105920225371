@import 'src/styles/mixins';
@import 'src/styles/props';

.columnRow {
  @include flex();

  @include for-phone-only {
    @include flex(null, null, column);
  }

  .formRow {
    flex: 1 !important;

    @include for-phone-only {
      margin-right: 0px !important;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.needMarginRight {
      @include for-phone-only {
        margin-right: 20px !important;
      }
    }
  }

  .spanHalfOnDesktop {
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: calc((100% - 20px) / 2) !important;

    @include for-phone-only {
      flex: 1 !important;
    }
  }
}

.selectedItemHeader {
  @include CastrolSansConRegular;
  font-size: 14px;
  line-height: 17px;
  color: $ruby;
}

.sectionHeader {
  margin-top: 24px;
  font-size: 16px;
  line-height: 22px;
  color: $black;
}

.rowButton {
  padding-left: 28px;
  padding-right: 28px;
  @include for-phone-only {
    width: 100%;
  }
}
