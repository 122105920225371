@import 'src/styles/mixins.scss';
@import 'src/styles/props.scss';

.container {
  flex: 1;
  max-width: 100%;
  @include flex(stretch, flex-start, column, nowrap);
  @include Arial;
  overflow: hidden;
}

.textarea {
  @include border(1px solid $gainsboro);
  @include padding(13px 20px);
  @include CastrolSansConNormal;
  width: 100%;
  resize: vertical;
  background-color: $white;
}

.moreLessLink {
  @include Arial;
  color: $pigment-green;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
