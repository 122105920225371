@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include item-flex(1, 1, 0);
  @include padding(60px 60px 80px 60px);
  min-width: 800px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;


  @include for-phone-only {
    @include padding(32px 16px 80px 16px);
    min-width: unset;
    max-width: unset;
    flex-direction: column;
  }
}

.title {
  @include CastrolSansConRegular;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
}

.country {
  @include CastrolSansConRegular;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: $dim-gray;
}

.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 20px 0;
}

.portServed {

  .infoRow {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 60px;

    @include for-phone-only {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .infoLabel {
      width: 150px;
      min-width: 150px;
      text-transform: uppercase;
    }

    .infoValue {
      @include CastrolSansConRegular;
      color: $dim-gray;
      font-size: 16px;
      line-height: 20px;
      padding-top: 5px;
    }
  }
}


.contactContainer {
  display: flex;
  flex-direction: row;

  @include for-phone-only {
    width: 100%;
    flex-direction: column;
  }

  .left {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: row;

    @include for-phone-only {
      width: 100%;
    }

    .label {
      width: 150px;
      min-width: 150px;
      text-transform: uppercase;
    }

    .address {
      @include Arial;
      font-size: 16px;
      line-height: 22px;
      color: $dim-gray;
      padding-top: 5px;
    }
  }

  .right {
    width: calc(50% - 20px);
    max-width: 380px;
    margin-left: 40px;
    padding: 24px 30px;
    background-color: rgba($gainsboro3, 0.2);

    @include for-phone-only {
      width: calc(100% - 40px);
      height: unset;
      margin-left: unset;
      padding: 20px;
      margin-top: 30px;
    }

    .item {
      width: 400px;
      @include for-phone-only {
        width: 100%;
      }
    }

    .item + .item {
      margin-left: 10px;
      @include for-phone-only {
        margin-left: 0px;
        margin-top: 20px;
      }
    }

    .description {
      @include Arial;
      font-size: 16px;
      line-height: 22px;
      margin-top: 15px;

      // description
      &.description2 {
        color: $dim-gray;
      }

      // description
      &.description3 {
        color: $dim-gray;
        .link {
          @include Arial;
          margin-left: 2px;
          color: $pigment-green;
          font-weight: 400;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    // icon gap
    .icon {
      margin-top: 15px;
      &.green {
        color: $pigment-green;
      }
    }

    .divider {
      @include rect(100%, 1px);
      background-color: $gainsboro;
      margin: 30px 0;
    }
  }


}

