@import "src/styles/mixins";
@import "src/styles/props";

.container {
  // position: relative;
  overflow: visible;
  // max-height: 500px;
}

// table row
.tableRow {
  @include flex();
  @include for-phone-only {
    flex-direction: column;
    padding: 0 15px 0 15px;
    // @include padding(0, 15px, 10px, 15px);
  }
  // position: relative;
  &.expanded {
    @include for-phone-only {
      background-color: #f7f7f7;
    }
    .otherColumns {
      @include for-phone-only {
        width: 100% !important;
        display: flex;
        padding: 5px 0;
        flex-wrap: nowrap;
      }
    }

    .dropdownArrow {
      transform: rotate(180deg);
    }
  }
  &.collapsed {
    @include for-phone-only {
      @include padding(10px0);
    }
    .otherColumns {
      @include for-phone-only {
        display: none;
      }
    }
    .firstColumn {
      @include for-phone-only {
        border: none;
      }
    }
  }
  &.inActive {
    background: $light-pink2;

    .firstColumn {
      @include padding(6px !important);

      @include for-phone-only {
        @include padding(0px !important);
      }
    }

    .inActiveLabelWrapper {
      font-size: 12px;
      background-color: $ruby;
      color: $white;
      @include padding(2px);
      text-transform: uppercase;
      margin-top: 2px;
    }
  }
}

// column
.tableColumn {
  @include CastrolSansConNormal;
  @include flex(center);
  @include for-phone-only {
    border-bottom: 1px solid $gainsboro;
    width: calc(100%) !important;

    &:last-child {
      border-bottom: none;
    }
  }
  &.showCheckbox {
    @include for-phone-only {
      border-bottom: 1px solid $gainsboro;
      width: calc(100% - 30px) !important;
      padding-left: 30px;
    }
  }
  .actionsExpandContainer {
    display: none;

    @include for-phone-only {
      display: flex;
      align-items: center;

      .dropdownArrow {
        margin-left: 16px;
      }
    }
  }
}

// checkbox column
.tableCheckboxColumn {
  width: 7.47185261003071% !important;
  @include for-phone-only {
    border: none;
    position: absolute;
  }
}

// header
.tableHeader {
  @include padding-bottom(10px);
  @include border-bottom(1px solid $silver);
  position: sticky;
  background-color: $white;
  top: 0;
  left: 0;
  z-index: 2;
  @include for-phone-only {
    display: none;
  }

  .tableColumn {
    @include CastrolSansConNormal;
    color: $dim-gray;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    pointer-events: visible;
  }
}

// for body
.tableBody {
  // body row
  .tableRow {
    @include border-bottom(1px solid $gainsboro);
  }

  // body column
  .tableColumn {
    font-size: 16px;
    min-height: 50px;
    @include for-phone-only {
      min-height: 35px;
    }
    color: $dim-gray;
    &.showCheckbox {
      .label {
        @include for-phone-only {
          width: 30%;
          padding-left: 30px;
        }
      }
    }
    .label {
      display: none;
      @include for-phone-only {
        display: block;
        width: 40%;
        margin-right: 10px;
        text-transform: capitalize;
      }
    }
    .content {
      width: 90%;
      word-wrap: break-word;

      @include for-phone-only {
        display: block;
        width: 50%;
      }

      &.desktop {
        display: block;
        @include for-phone-only {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @include for-phone-only {
          display: block;
          width: 100%;
        }
      }

    }
    .dropdownArrow {
      display: none;
      @include for-phone-only {
        display: block;
      }
    }
  }

  // checkbox column
  .tableRow .tableColumn:first-child {
    @include padding-left(17px);
    @include for-phone-only {
      @include padding-left(0);
    }
  }

  // column after checkbox column
  .firstColumn {
    @include for-phone-only {
      position: relative;
      justify-content: space-between;
    }
  }
}

// table container
.container {
  // for dark background
  // default is light background
  &.dark {
    .tableHeader {
      @include border-bottom(0);
      background-color: $white-smoke;
    }

    .tableBody {
      .tableRow {
        @include border-bottom(0);
        background-color: $white;
      }

      .tableRow + .tableRow {
        margin-top: 5px;
      }
    }
  }

  // for light background
  &.light {
    .tableHeader {
      background-color: $white;
    }
  }
}

// link
.link {
  color: $pigment-green;
  width: 100%;
  z-index: 1;
  @include for-phone-only {
    width: 80%;
    // padding-top: 10px;
    // padding-bottom: 0px;
    padding: 10px 0;
  }
}

// sortable column
.sortableColumn {
  @include flex(center);
  cursor: pointer;
}

// sort icon
.sortIcon {
  margin-left: 4px;

  &.asc {
    transform: rotate(180deg);
  }
}

// column label
.columnLabel {
  display: inline-block;
  max-width: calc(100% - 24px);
  font-size: 14;
  line-height: 18px;
}
