@import "src/styles/mixins";
@import "src/styles/props";

.dateField {
  padding-top: 20px;

  @include for-phone-only {
    width: 100%;
  }

  @include for-tabs-l-only {
    width: 100%;
  }
}

.datePicker {
  border: 1px solid $gainsboro !important;
  width: 270px;
  height: 15px;

  @include for-phone-only {
    width: 95% !important;
  }

  @include for-tabs-l-only {
    width: 95% !important;
  }
}
