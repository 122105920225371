@import "src/styles/mixins";
@import "src/styles/props";

// header for container
.container {
  h3, h4 {
    text-transform: uppercase;
  }
  h3{
    font-size: 22px;
    line-height: 27px;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }
  h4{
    font-size: 16px;
    line-height: 19px;
  }
  .button {
    margin-left: auto;
    height: 38px;
    width: 108px;
    @include for-phone-only {
        margin-top: 10px;
        margin-right: auto;}
  }
  
}

// input container
.inputContainer {
  @include flex(center, flex-start, row);

  @include for-phone-only {
    flex-direction: column;

    h4 {
      width: 100%;
    }
  }

  margin-top: 19px;
  .inputWrapper{
    margin-left: 20px;

    @include for-phone-only {
      margin-top: 10px;
      margin-left: 0;
    }
    input{
      width: 320px;
      padding-left: 10px;

      @include for-phone-only {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

// list container
.listContainer {
  margin-top: 20px;
}

.empty{
  padding: 20px;
  border: 1px solid $cadetblue;
  background-color: $cadetblue-light;
  color: $cadetblue;
  @include Arial;
  font-size: 16;
  line-height: 22px;
}
