@import "src/styles/mixins";
@import "src/styles/props";

// radio
.radio {
  @include box(16px);
  @include border(1px solid $dark-gray);
  border-radius: 100%;
  cursor: pointer;

  &.large {
    @include box(24px);
    @include border(2px solid $dark-gray);
  }

  &.checked {
    border-color: $pigment-green;
  }

  &.disabled {
    background: #F7F8F9;
    opacity: 0.5;
    cursor: unset;
  }

  .innerCircle {
    @include box(10px);
    border-radius: 100%;
    background-color: $pigment-green;
    position: relative;
    top: 2px;
    left: 2px;

    &.large {
      @include box(12px);
      top: 3.5px;
      left: 3.5px;
    }
  }
}
