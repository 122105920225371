@import "src/styles/mixins";
@import "src/styles/props";

// container
.container {
  @include flex(center, center);
  width: 100%;
  min-height: 100px;
}

$loader-colors: #0a40db;
$loader-dash: 63;
$loader-duration: length($loader-colors) * 1s;
$loader-duration-alt: $loader-duration / length($loader-colors);
$loader-keyframe: 1 / (length($loader-colors) * 2) * 100;

// loader
.loader {
  @include box(24px);
  display: block;
  animation: loader-turn $loader-duration-alt linear infinite;

  @keyframes loader-turn {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
}

// loader stroke
.loaderStroke {
  animation: loader-stroke $loader-duration linear infinite;
  fill: none;
  stroke-dasharray: $loader-dash;
  stroke-dashoffset: $loader-dash;
  stroke-linecap: round;
  stroke-width: 4;

  @for $i from 1 through length($loader-colors) {
    &:nth-child(#{$i}) {
      stroke: nth($loader-colors, $i);

      @if $i > 1 {
        animation-delay: ($i - 1) * $loader-duration-alt;
      }
    }
  }

  @keyframes loader-stroke {
    #{$loader-keyframe * 1%} {
      stroke-dashoffset: 0;
    }

    #{$loader-keyframe * 2%}, 100% {
      stroke-dashoffset: $loader-dash;
    }
  }
}
