@import "src/styles/mixins";
@import "src/styles/props";

.container {
  @include border(1px solid $gainsboro);
  width: 100%;
  @include padding(20px);

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    @include CastrolSansConNormal;
    text-transform: uppercase;
  }
}

.divider {
  @include border-bottom(1px solid $gainsboro);
  width: 100%;
  margin-bottom: 20px;
}

.resultContainer {
  height: 190px;
  @include flex(center, center, column);
}

.successText {
  font-size: 12px;
  line-height: 15px;
  @include CastrolSansConNormal;
  margin-top: 24px;
  text-transform: uppercase;
}
