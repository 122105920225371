@import 'src/styles/props.scss';
@import 'src/styles/mixins.scss';

.container {
  overflow: hidden;
  @include flex(stretch, flex-start, column, nowrap);
  > *:not(:first-child) {
    margin-top: 20px;
  }
}
