@import "src/styles/mixins";
@import "src/styles/props";

// drawer container
.container {
  @include border-right(1px solid $gainsboro);
  @include padding(0 20px 40px 33px);
  width: 260px;
  @include for-phone-only{
    width: 100vw;
    max-width: 400px;
    border: none;
    @include padding(20px 16px);
  }
}
