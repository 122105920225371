@import "src/styles/mixins";
@import "src/styles/props";

// main container
.sliderContainer {
  height:50px;
  overflow: hidden;
  @include flex(center, null, column);
}

// slider

.sliderWrapper {
  width: 100%;
  position: relative;
}

.slider {
  position: absolute;
  height: 8px;
  width: 100%;
  background-color: #009343;
  border-radius: 5px;
  z-index: -2;
  top: 5px;
}
.remainingSlider {
    position: absolute;
    height: 8px;
    width: 100%;
    background-color: #dddddd;
    border-radius: 5px;
    z-index: -1;
    top: 5px;
  }

.sliderInput {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  padding: 0 !important;
  &::-webkit-slider-thumb {
    background-color: #009343;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.barContainer{
    width: 100%;
    position: relative;
    .bar{
        position: absolute;
        font-size: 8px;
        font-weight: 900;
        text-align: center;
        transform: translateX(-50%)
    }
    .label{
        position: absolute;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        top: 10px;
        transform: translateX(-50%)
    }
    &.labelContainer{
      margin-top: 5px;
    }
}



