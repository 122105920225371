@import "src/styles/mixins";
@import "src/styles/props";

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @include for-phone-only {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.toggleExpand{
    cursor: pointer;
}

.option {
  display: grid;
  grid-template-columns: 16px 16px 1fr;
  grid-gap: 12px;
  margin-bottom: 12px;

  .checkboxLabel {
    font-size: 14px;
    line-height: 16px;
    @include Arial;
  }
}
