@import "src/styles/props";

.container {
  .progressIndicatorWrapper {
    height: 6px;
    border-radius: 3px;
    background-color: $gainsboro;
  
    .progressIndicator {
      height: 100%;
      background-color: $pigment-green;
    }
  }
  .progressText {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    color: $dim-gray;
  }
}