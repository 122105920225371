@import "src/styles/mixins";
@import "src/styles/props";

// button
.button {
  @include CastrolSansConNormal;
  @include flex(center);
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: $pigment-green;
}

// arrow icon
.icon {
  margin-left: 10px;

  path {
    stroke: $silver;
  }
}

button{
  &:hover{
    text-decoration: underline;
  }
}
