@import "src/styles/mixins";
@import "src/styles/props";

// header
.tableHeader {
  @include border-bottom(1px solid $gainsboro);

  .tableRow {
    @include flex(stretch, null, null, nowrap);

    .tableColumn {
      @include padding(12px);
      @include padding-bottom(20px);
      min-height: 40px;
    }
  }

  @include for-phone-only {
    display: none;
  }
}

// label column
.tableLabelColumn {
  @include CastrolSansConRegular;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
  color: $dim-gray;
}

// column
.tableColumn {
  @include CastrolSansConRegular;
  @include flex(center);
  align-items: flex-start;
  position: relative;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: $dim-gray;
  &.columnWithIcon {
    svg {
      margin-left: 8px;
      vertical-align: text-bottom;
    }
  }

  &.sortableColumn {
    cursor: pointer;
    align-items: center;

    .sortIcon {
      margin-left: 4px;
      height: 12px;
      width: 12px;

      &.asc {
        transform: rotate(180deg);
      }
    }
  }
}

// body
.tableBody {
  .tableRow {
    @include flex(stretch, null, null, nowrap);
    @include border-bottom(1px solid $gainsboro);

    .tableColumn {
      @include CastrolSansConRegular;
      @include padding(16px 12px);
      @include padding-bottom(40px);
      color: $light-dark;
    }

    .greenText {
      color: $pigment-green;
    }

    .titleColumn {
      font-size: 16px;
    }

    .actionColumn {
      svg {
        margin-left: 10px;
      }
    }

    .dateColumn {
      color: $dim-gray;
    }

    .descColumn {
      @include Arial;
      > * {
        @include Arial;
      }
    }
  }

  &.mobile {
    display: none;
    @include for-phone-only {
      display: block;
    }
  }

  &.desktop {
    display: block;
    @include for-phone-only {
      display: none;
    }
  }
}

.expanderHeader {
  div {
    display: inline-block;
  }
  .expanderTitle {
    color: $pigment-green;
    width: 60%;
  }
  .secondaryLabel {
    color: $dim-gray;
  }
}

// link text
.link {
  @include CastrolSansConRegular;
  font-size: 14px;
  line-height: 18px;
  color: $pigment-green;
  @include for-phone-only {
    width: 80% !important;
  }
  cursor: pointer;
}

.rightBorder {
  border-right: 1px solid $dim-gray;
}

.statusIcon {
  margin-right: 10px;
}

.tableMobileView {
  background-color: $white-smoke;
}

.mobileActionBtn {
  @include CastrolSansConRegular;
  width: 100%;
  margin-top: 10px;
  height: 40px;
  justify-content: start;

  &:first-child {
    margin-top: 0px;
  }

  .actionIcon {
    height: 18px;
    width: 18px;
    margin: 0px 21px;
  }
}

.descriptionHtml {
  width: 100%;
}
